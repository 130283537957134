import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import green from '@material-ui/core/colors/green'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import upperFirst from 'lodash/upperFirst'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import backgroundImage from 'public/img/background.jpg'
import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'
import ErrorSnackbar from 'src/components/Snackbar'

interface ILoginDialogProps extends WithStyles {
  errorMessage?: string
  hideBack?: boolean
  onErrorMessageClose?: () => void
  onValidate: (username: string, password: string) => void
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: 300,
      'max-width': '100%',
    },
    input: { minHeight: 40 },
    wrapper: {
      width: '100%',
      position: 'relative',
    },
    button: {
      width: '100%',
      height: 35,
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    dialogTitle: {},
    dialogContainer: {},
    dialogContainerHidden: {
      background: `url(${backgroundImage}) no-repeat center center fixed`,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    forgotPasswordLink: {
      marginTop: 12,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      height: 400,
      width: 470,
      'max-width': '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    formMargin: {
      marginBottom: 20,
    },
    formRoot: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      width: '100%',
      borderRadius: 4,
    },
    formLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      top: -8,
    },
    formInput: {
      position: 'relative',
      fontSize: 16,
      padding: '10px 12px',
      minHeight: 25,
    },
    link: {
      'text-decoration': 'underline',
      color: 'revert',
      float: 'right',
    },
  })

const LoginDialog = (props: ILoginDialogProps) => {
  const { classes, onValidate, hideBack = false, errorMessage = '', onErrorMessageClose } = props
  const { t } = useTranslation()
  const [showPassword, setPasswordDisplay] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(true)
  const [isErrorMessageOpen, setErroMesgOpen] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    if (errorMessage) {
      setErroMesgOpen(true)
    }
  }, [errorMessage])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickShowPassword = () => {
    setPasswordDisplay((passwordDisplayed) => !passwordDisplayed)
  }

  const handleUsernameChange = (evt: any) => {
    setUsername(evt.target.value)
  }

  const handlePasswordChange = (evt: any) => {
    setPassword(evt.target.value)
  }

  const handleButtonClick = () => {
    setLoading(true)
    if (onValidate) {
      onValidate(username, password)
    }
    setLoading(false)
  }

  const handleErrorMessageClose = () => {
    setErroMesgOpen(false)
    if (onErrorMessageClose) {
      onErrorMessageClose()
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick={true}
        classes={{
          container: hideBack ? classes.dialogContainerHidden : classes.dialogContainer,
        }}
        aria-labelledby="form-dialog-title"
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            handleButtonClick()
          }
        }}
      >
        <DialogContent className={classes.content}>
          <img src={blueSystemsLogo} className={classes.logo} />
          <div>
            <FormControl fullWidth={true} className={classes.formMargin}>
              <InputLabel shrink={true} htmlFor="adornment-username" className={classes.formLabel}>
                {upperFirst(t('username'))}
              </InputLabel>
              <InputBase
                id="adornment-username"
                className={classes.input}
                onChange={handleUsernameChange}
                autoFocus={true}
                type={'text'}
                fullWidth={true}
                classes={{
                  root: classes.formRoot,
                  input: classes.formInput,
                }}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="adornment-password" className={classes.formLabel} shrink={true}>
                {upperFirst(t('password'))}
              </InputLabel>
              <InputBase
                id="adornment-password"
                type={showPassword ? 'text' : 'password'}
                onChange={handlePasswordChange}
                className={classes.input}
                fullWidth={true}
                classes={{
                  root: classes.formRoot,
                  input: classes.formInput,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={upperFirst(t('toggle password visibility'))}
                      onClick={handleClickShowPassword}
                    >
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isLoading}
              onClick={handleButtonClick}
            >
              {t('button.login')}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            <div className={classes.forgotPasswordLink}>
              <a href="/password/reset" className={classes.link}>
                {t('button.forgot_password')}
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ErrorSnackbar open={isErrorMessageOpen} onClose={handleErrorMessageClose} message={errorMessage} />
    </div>
  )
}

export default withStyles(styles)(LoginDialog)
