import url from 'url'

import portableFetch from 'portable-fetch'

import { FetchAPI, ProvidersPermitsApi, ProvidersPermitsApiFetchParamCreator } from './gen/api'

interface IOptions {
  date_end: string
  date_start: string
  providers?: string
}

const ProvidersPermitsAPIFp = (thisPath: string) => {
  return {
    capsPerProvider(fetchOptions?: any) {
      return (fetch: FetchAPI = portableFetch, basePath: string = thisPath) =>
        fetch(basePath, fetchOptions).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
    },
  }
}

export class PermitsCapsAPI extends ProvidersPermitsApi {
  public capsPerProvider(options?: IOptions) {
    const fetchArgs = ProvidersPermitsApiFetchParamCreator(this.configuration).providersPermitsList({ options })
    const path = `${this.basePath}${fetchArgs.url}`
    const urlObj = url.parse(path, true)
    urlObj.query = Object.assign({}, urlObj.query, options)

    return ProvidersPermitsAPIFp(this.basePath).capsPerProvider(fetchArgs.options)(this.fetch, url.format(urlObj))
  }
}
