import { upperFirst } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'

import api from 'src/api'
import {
  AgencySettings,
  AgencySettings_VehicleCategoriesEnum,
  AgencySettings_VehiclePropulsionsEnum,
  AgencySettings_EnabledExtensionsEnum,
  AgencySettings_EnabledFeaturesEnum,
  AgencySettings_LocationTypesEnum,
  PolicyRule_RuleUnitsEnum,
} from 'src/api/gen/api'
import { CurrencyKind, DistanceUnit, DistanceUnitSymbol, SpeedUnit, SpeedUnitSymbol } from 'src/api/types/units'
import { getTranslation } from 'src/commons/i18n'
import { currencySymbols, enumsTranslationKeys, ICurrencySymbols, PREFIXED_CURRENCIES } from 'src/commons/i18nEnums'
import { getRange } from 'src/commons/moment'
import { GranularityEnum } from 'src/commons/types'

export interface IProps {
  children?: React.ReactNode
}

export interface IAgencySettingsContext extends AgencySettings {
  isLoaded: boolean
  kpi_filter_range: () => () => {
    from: string
    to: string
  }
  // logo: string
  // map_config: {
  //   center: {
  //     latitude: number
  //     longitude: number
  //   }
  //   zoom: number
  // }
  // name: string
  location_types: AgencySettings_LocationTypesEnum[]
  penalty_symbol: string
  permit_currency_symbol: string
  vehicle_categories: AgencySettings_VehicleCategoriesEnum[]
  vehicle_propulsions: AgencySettings_VehiclePropulsionsEnum[]
}

const initialAgencySettings: IAgencySettingsContext = {
  isLoaded: false,
  // name: '',
  // logo: '',
  language_code: 'en-us',
  penalty_currency_code: 'USD',
  penalty_symbol: '$',
  speed_unit: 'mph',
  speed_unit_display: 'mph',
  distance_unit: 'miles',
  permit_currency_code: 'USD',
  permit_currency_symbol: '$',
  // map_config: {
  //   center: { latitude: 0, longitude: 0 },
  //   zoom: 11,
  // },
  maximum_password_age: 60 * 60 * 24 * 42,
  use_telemetry_data: false,
  trip_threshold: 0,
  enabled_extensions: [] as AgencySettings_EnabledExtensionsEnum[],
  enabled_features: [] as AgencySettings_EnabledFeaturesEnum[],
  routes: undefined,
  vehicle_categories: [],
  vehicle_propulsions: [],
  location_types: [],
  kpi_filter_number_of_units: 7,
  kpi_filter_unit: 'day',
  kpi_filter_granularity: 'day',
  kpi_filter_range: () => () => getRange(7, 'day', 'day'),
}

export const AgencySettingsContext = React.createContext<IAgencySettingsContext>(initialAgencySettings)

const AgencySettingsWrapper = (props: IProps) => {
  const { children } = props
  const [agencySettings, setAgencySettings] = useState<IAgencySettingsContext>(initialAgencySettings)

  const getSettings = async () => {
    try {
      const response = await api.agencySettings.agencySettingsList()

      const penalty_currency_code = response.settings.penalty_currency_code
      const penaltySymbol =
        penalty_currency_code in currencySymbols
          ? currencySymbols[penalty_currency_code as keyof ICurrencySymbols]
          : penalty_currency_code

      const permit_currency_code = response.settings.permit_currency_code
      const permitCurrencySymbol =
        permit_currency_code in currencySymbols
          ? currencySymbols[permit_currency_code as keyof ICurrencySymbols]
          : permit_currency_code

      const kpiFilterRange = () => () =>
        getRange(
          response.settings.kpi_filter_number_of_units,
          response.settings.kpi_filter_unit as GranularityEnum,
          response.settings.kpi_filter_granularity as GranularityEnum
        )

      setAgencySettings({
        isLoaded: true,
        penalty_symbol: penaltySymbol,
        permit_currency_symbol: permitCurrencySymbol,
        kpi_filter_range: kpiFilterRange,
        ...response.settings,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  return <AgencySettingsContext.Provider value={agencySettings}>{children}</AgencySettingsContext.Provider>
}

export const useAgencySettings = (): IAgencySettingsContext => useContext(AgencySettingsContext)

export const useIsAgencySettingsLoaded = (): boolean => useContext(AgencySettingsContext).isLoaded

export const useAgencySettingsSpeedUnit = (): {
  symbol: SpeedUnitSymbol
  value: SpeedUnit
} => {
  const speedUnit = useContext(AgencySettingsContext).speed_unit
  if (speedUnit === PolicyRule_RuleUnitsEnum.kmh) {
    return {
      value: SpeedUnit.KilometersPerHour,
      symbol: SpeedUnitSymbol.KilometersPerHour,
    }
  } else {
    return {
      value: SpeedUnit.MilesPerHour,
      symbol: SpeedUnitSymbol.MilesPerHour,
    }
  }
}

export const useAgencySettingsDistanceUnit = (): {
  symbol: DistanceUnitSymbol
  value: DistanceUnit
} => {
  const speedUnit = useContext(AgencySettingsContext).speed_unit
  if (speedUnit === PolicyRule_RuleUnitsEnum.kmh) {
    return { value: DistanceUnit.Kilometer, symbol: DistanceUnitSymbol.Kilometer }
  } else {
    return { value: DistanceUnit.Mile, symbol: DistanceUnitSymbol.Mile }
  }
}

export function formatCurrency(amount: string | number, currencyCode: string, currencySymbol?: string): string {
  // This function will have to be changed later on to have a more robust one.
  if (PREFIXED_CURRENCIES.includes(currencyCode)) {
    return `${currencySymbol ? currencySymbol : currencyCode} ${amount}`
  }
  return `${amount} ${currencySymbol ? currencySymbol : currencyCode}`
}

export function _buildCurrencyFormatter(kind: CurrencyKind, agencySettings: IAgencySettingsContext) {
  let code: string
  let symbol: string

  if (kind === CurrencyKind.Permit) {
    ;({ permit_currency_code: code, permit_currency_symbol: symbol } = agencySettings)
  } else {
    ;({ penalty_currency_code: code, penalty_symbol: symbol } = agencySettings)
  }

  return (x: string | number) => formatCurrency(x, code, symbol)
}

export function useCurrencyFormatter(kind: CurrencyKind) {
  const agencySettings = useAgencySettings()
  return _buildCurrencyFormatter(kind, agencySettings)
}

export const POLICY_KIND_TO_FEATURE: any = {
  absolute_fleet_size: AgencySettings_EnabledFeaturesEnum['mds_policy.absolute_fleet_size'],
  permit: AgencySettings_EnabledFeaturesEnum['mds_policy.permit'],
  time: AgencySettings_EnabledFeaturesEnum['mds_policy.time'],
  speed: AgencySettings_EnabledFeaturesEnum['mds_policy.speed'],
  user: AgencySettings_EnabledFeaturesEnum['mds_policy.user'],
  geofence: AgencySettings_EnabledFeaturesEnum['mds_policy.geofence'],
  parking: AgencySettings_EnabledFeaturesEnum['mds_policy.parking'],
  abandoned: AgencySettings_EnabledFeaturesEnum['mds_policy.abandoned'],
  trip_taxation: AgencySettings_EnabledFeaturesEnum['mds_policy.trip_taxation'],
  sidewalk: AgencySettings_EnabledFeaturesEnum['mds_policy.sidewalk'],
  balancing: AgencySettings_EnabledFeaturesEnum['mds_policy.balancing'],
}

export function useCheckEnabledFeature() {
  const { enabled_features = [] } = useAgencySettings()
  return (feature: AgencySettings_EnabledFeaturesEnum): boolean => enabled_features.includes(feature)
}

interface IEnabledFeature {
  children?: React.ReactNode
  feature: AgencySettings_EnabledFeaturesEnum
}

export function EnabledFeature({ children, feature }: IEnabledFeature) {
  const isFeatureEnabled = useCheckEnabledFeature()(feature)
  return isFeatureEnabled ? <>{children}</> : null
}

export function useEnabledPolicyKinds(): Array<{ id: string; label: string }> {
  const { t } = getTranslation()
  const isFeatureEnabled = useCheckEnabledFeature()
  return Object.entries(enumsTranslationKeys.PolicyKind)
    .filter(([id]) => Object.keys(POLICY_KIND_TO_FEATURE).includes(id) && isFeatureEnabled(POLICY_KIND_TO_FEATURE[id]))
    .map(([id, label]) => ({ id, label: upperFirst(t(label)) }))
}

export default AgencySettingsWrapper
