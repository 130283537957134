import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import '@fontsource/roboto'

import { VehicleStateEnum } from 'src/api/provider-agency'

// See https://material-ui.com/guides/typescript/ for more explanations
declare module '@material-ui/core/styles/createMuiTheme' {
  interface ICustomPalette {
    disabled: string
    outlinedErrorHover: string
    success: { dark: string; light: string; main: string }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Theme {
    customPalette: ICustomPalette
    mdsPalette: {
      background: {
        filterMenu: React.CSSProperties['color']
        mainMenu: React.CSSProperties['color']
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface ThemeOptions {
    customPalette: ICustomPalette
    mdsPalette: {
      background: {
        filterMenu: React.CSSProperties['color']
        mainMenu: React.CSSProperties['color']
      }
    }
  }
}

export const colors = {
  black: '#192233',
  blackTransparent: 'rgba(0, 0, 0, 0.38)',
  blue: '#0171e4',
  blueLight: '#188aff',
  blueDark: '#0058b3',
  green: '#5CB85C',
  greenLight: '#7cc67c',
  greenDark: '#408040',
  greenCaribbean: '#00cfac',
  grey: '#9E9E9E',
  greyLight: '#ccc',
  greyDark: '#616161',
  greyTransparent: 'rgba(222, 226, 235, 0.6)',
  orange: '#FFA03C',
  purple: '#6c2bd4',
  pink: '#FF50F9',
  pinkDark: '#F42FB8',
  red: '#DC3030',
  redLight: '#ffd7db',
  redDark: '#9a2121',
  yellow: '#FFFC3C',
  white: '#FFFFFF',
  goldenYellow: '#FFCB37',
}

export const deviceColors = {
  bicycle: colors.pinkDark,
  car: colors.blue,
  deliveryBot: colors.grey,
  moped: colors.purple,
  other: colors.goldenYellow,
  scooter: colors.greenCaribbean,
}

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10,
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
  },
  spacing: 5,
  palette: {
    primary: {
      light: colors.blueLight,
      main: colors.blue,
      dark: colors.blueDark,
    },
    secondary: {
      light: colors.white,
      main: colors.grey,
      dark: colors.greyDark,
    },
    error: {
      light: colors.redLight,
      main: colors.red,
      dark: colors.redDark,
    },
    background: {
      default: colors.greyTransparent,
      paper: colors.white,
    },
  },
  customPalette: {
    disabled: colors.blackTransparent,
    success: {
      light: colors.greenLight,
      main: colors.green,
      dark: colors.greenDark,
    },
    outlinedErrorHover: colors.redLight,
  },
  mdsPalette: {
    background: {
      filterMenu: colors.white,
      mainMenu: colors.black,
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '24px',
      },
    },
    MuiTooltip: {
      popper: { fontSize: '1rem' },
    },
  },
}

const theme = createMuiTheme(themeOptions)
theme.shadows[1] = ['5px 5px 10px 0px rgba(0, 0, 0, 0.05)'].join(',')

export const DEVICE_STATUS_COLOR = {
  [VehicleStateEnum.available]: colors.green,
  [VehicleStateEnum.non_operational]: colors.orange,
  [VehicleStateEnum.on_trip]: colors.blue,
  [VehicleStateEnum.reserved]: colors.blue,
  [VehicleStateEnum.removed]: colors.red,
  [VehicleStateEnum.unknown]: colors.purple,
  [VehicleStateEnum.elsewhere]: colors.pink,
}

export default theme
