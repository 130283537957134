import defaultsDeep from 'lodash/defaultsDeep'

import packageJson from 'package.json'

declare var __SETTINGS__: any // eslint-disable-line no-var
declare var __webpack_public_path__: string // eslint-disable-line no-var

// All those settings MUST be defined for the app to "work",
// since we did not design a "reduced functionality" mode.
// In development mode thought the default values will be set to dummy strings.
interface IAppSettings {
  brand_id: string
  client_id: string
  client_secret: string
  debounce_delay_on_filter_change: number
  display_bestmile_section?: boolean
  display_delivery_bot_section?: boolean
  display_hopon_section?: boolean
  display_mobile_footer: boolean
  display_upstream_section?: boolean
  enable_mobile_version: boolean
  enable_timeslots: boolean
  google_analytics_cookie_flags?: string
  google_analytics_id?: string
  language: string
  mapbox_token: string
  mobile_display_devices_zoom: number
  mobile_select_device_zoom: number
  otp_enabled: boolean
  password_changed_redirect_timeout: number
  sentry_dsn?: string
  timezone: string
}

interface ISettings {
  app: IAppSettings
  basePath: string
  debug: boolean
}

const defaultSettings: ISettings = {
  basePath: __webpack_public_path__,
  debug: process.env.NODE_ENV !== 'production',
  app: {
    brand_id: '__DEFAULT__',
    client_id: '__DEFAULT__',
    client_secret: '__DEFAULT__',
    debounce_delay_on_filter_change: 500, // in ms
    display_bestmile_section: false,
    display_delivery_bot_section: false,
    display_hopon_section: false,
    display_mobile_footer: false,
    display_upstream_section: false,
    enable_mobile_version: false,
    enable_timeslots: false,
    mapbox_token: '__DEFAULT__',
    // Start displaying devices at the "suburb" level where 1px = 40m
    mobile_display_devices_zoom: 12,
    // use a close enough zoom level: the user is supposed to see the vehicles around him on the map.
    // 15 is around 5m per pixel, it's used to follow small roads usually.
    mobile_select_device_zoom: 15,
    otp_enabled: false,
    language: 'en',
    password_changed_redirect_timeout: 3000,
    timezone: 'Europe/Paris',
  },
}

/* the only purpose of this being an exported function is to be able to test the
 * settings override */
export function _getSettings(): ISettings {
  return defaultsDeep(
    {}, // object that gets recursively filled
    (window as any).__SETTINGS__, // settings set by app.settings.js
    (global as any).__SETTINGS__, // eventually overriden by settings from webpack & env
    __SETTINGS__, // eventually overriden by settings from .settings.yaml
    defaultSettings // and let the default hardcoded settings fill the gaps
  )
}

const SETTINGS = _getSettings()

// Make settings and build informations accessible for debug
;(global as any).PLATO_SETTINGS = SETTINGS
;(global as any).PLATO_BUILD = {
  tag: process.env.REACT_APP_BUILD_TAG,
  sha: process.env.REACT_APP_BUILD_SHA,
  version: packageJson.version,
}

export default SETTINGS
