import green from '@material-ui/core/colors/green'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.main,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    success: {
      backgroundColor: green[600],
    },
  })

interface ISnackbar {
  className?: string
  message: string
  onClose: () => void
  open: boolean
}
const ErrorSnackbar = (props: ISnackbar & WithStyles) => {
  const { open, onClose, message, classes } = props
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes.error}
        aria-describedby="error-snackbar"
        message={
          <span id="error-snackbar" className={classes.message}>
            <WarningIcon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export const SuccessSnackbar = withStyles(styles)((props: ISnackbar & WithStyles) => {
  const { open, onClose, message, classes, className } = props
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes.success, className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
})

export default withStyles(styles)(ErrorSnackbar)
