import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import upperFirst from 'lodash/upperFirst'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'
import { resetPassword } from 'src/api/auth-api'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    height: 35,
  },
  formMargin: {
    marginBottom: 20,
  },
  formRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    width: '100%',
    borderRadius: 4,
  },
  formLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    top: -8,
  },
  formInput: {
    position: 'relative',
    fontSize: 16,
    padding: '10px 12px',
    minHeight: 25,
  },
  input: { minHeight: 40 },
  logo: {
    width: 300,
    'max-width': '100%',
  },
  content: {
    height: 500,
    width: 470,
    'max-width': '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  wrapper: {
    width: '100%',
    margin: theme.spacing(),
    position: 'relative',
  },
  info: {
    'font-size': '1rem',
  },
}))

interface IResetPasswordProps {
  setErrorMessage(message: string): void
  setSuccessMessage(message: string): void
}

export default function ResetPasswordForm(props: IResetPasswordProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [email, setEmail] = useState('')

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  async function handleButtonClick() {
    try {
      await resetPassword(email)
      props.setSuccessMessage(t('reset.mail_sent_success_redirect'))
    } catch (error) {
      console.error(error.json)
      props.setErrorMessage(t('reset.invalid_email'))
    }
  }

  return (
    <DialogContent className={classes.content}>
      <img src={blueSystemsLogo} className={classes.logo} />
      <Typography component="p" className={classes.info}>
        {t('password.forgotten.info')}
      </Typography>
      <FormControl fullWidth={true} className={classes.formMargin}>
        <InputLabel htmlFor="email-input" className={classes.formLabel} shrink={true}>
          {upperFirst(t('email'))}
        </InputLabel>
        <InputBase
          id="email-input"
          type="email"
          onChange={handleEmailChange}
          className={classes.input}
          fullWidth={true}
          autoFocus={true}
          classes={{
            root: classes.formRoot,
            input: classes.formInput,
          }}
          required={true}
        />
      </FormControl>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleButtonClick}
          disabled={email.length === 0}
        >
          {t('button.validate')}
        </Button>
      </div>
    </DialogContent>
  )
}
