import { CircularProgress } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import upperFirst from 'lodash/upperFirst'
import React, { useEffect, useState, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'

import api, { credentials, refreshTokenAndSave } from 'src/api'
import { shouldRememberDevice } from 'src/api/2fa-storage'
import { defaultCredentials, oAuthLogin } from 'src/api/auth-api'
import { TwoFactorValidation, UserInfo } from 'src/api/gen'
import { readCredentials, saveCredentials } from 'src/api/storage'
import useAsyncState from 'src/commons/hooks/useAsyncState'
import { saveTranslation } from 'src/commons/i18n'
import { withFormattedCount } from 'src/commons/i18nEnums'
import { updateUserPermissions, Permission } from 'src/commons/permissions'
import AgencySettings from 'src/layout/AgencySettings'
import MapLayerWrapper from 'src/layout/MapLayerWrapper'
import ProviderWrapper from 'src/layout/ProviderWrapper'
import SETTINGS from 'src/settings'

import ConfirmResetPasswordDialog from './ConfirmResetPassword'
import { CredentialsContext, UserContext } from './hooks'
import Loader from './Loader'
import LoginDialog from './LoginDialog'
import RegisterDialog from './RegisterDialog'
import ResetPasswordDialog from './ResetPassword'
import TwoFactorLogin from './TwoFactorLogin'

export function isMobileDevice() {
  if (!SETTINGS.app.enable_mobile_version) {
    console.debug('mobile layout disabled.')
    return false
  }
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Tablet_or_Desktop
  return navigator.userAgent.indexOf('Mobi') !== -1
}

function LoadingComponent() {
  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={36} />
    </div>
  )
}

export const _testStateUpdateFunctions: {
  setUserProfile?: React.Dispatch<React.SetStateAction<UserInfo | null>>
} = {}

export default function App() {
  const { t } = useTranslation()
  // Save the reference to the translation function so that it can be used anywhere else,
  // not only in a component. This may not be reactive to new dynamically added translations
  // though, nor be safe for a hot language switch. But eh, pretty sure the app isn't ready anyway.
  saveTranslation(t)

  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [twoFactorValidated, setTwoFactorValidated] = useState<boolean>(false)
  const [userProfile, setUserProfile] = useAsyncState<UserInfo | null>(null)

  _testStateUpdateFunctions.setUserProfile = setUserProfile

  // kickoff loading the components right away, before any wrapper kicks in
  const componentPromise = isMobileDevice() ? import('./mobile') : import('./Pages')

  async function loginUser(loginMethod: () => Promise<void>) {
    setIsBusy(true)
    try {
      await loginMethod()
      // List, but it will return only one element...
      const userinfo = await api.userinfo.userinfoList()
      if (userinfo.must_update_password) {
        setErrorMessage(upperFirst(t('login.expired_password')))
      } else {
        await saveUserInfo(userinfo)
      }
    } catch (err) {
      console.log(err)
      if (err.json && 'state' in err.json) {
        switch (err.json['error']) {
          case 'invalid_grant':
            setErrorMessage(upperFirst(t('login.invalid', withFormattedCount(err.json['state'] - 1))))
            break
          case 'account_locked':
            setErrorMessage(upperFirst(t('login.locked', withFormattedCount(err.json['state']))))
            break
        }
      } else {
        setErrorMessage(upperFirst(t('login.failed')))
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function saveUserInfo(userinfo: UserInfo): Promise<void> {
    const permissions: Permission[] = userinfo.domain_permissions.map((permission) => {
      return [permission.verb, permission.domain]
    })
    updateUserPermissions(permissions)

    Sentry.configureScope((scope) =>
      scope.setUser({
        id: `${userinfo.id}`,
        username: userinfo.username,
      })
    )

    await setUserProfile(userinfo)
  }

  function handleLogin(username: string, password: string) {
    loginUser(async () => {
      const userCredentials = await oAuthLogin({ username, password })
      credentials.setAccessToken(userCredentials.access_token)
      credentials.setRefreshToken(userCredentials.refresh_token)
      saveCredentials({ refreshToken: userCredentials.refresh_token })
    })
  }

  async function validateToken(digits: string) {
    const resp: TwoFactorValidation = await api.validate2fa.userValidate2faCreate({ data: { token: digits } })
    if (resp.is_valid) {
      setTwoFactorValidated(true)
    } else {
      setErrorMessage(upperFirst(t('login.2fa.validationError')))
    }
  }

  function handleTwoFactorLogin(digits: string) {
    validateToken(digits)
  }

  // DEV purpose, replace credentials by env variables and autologin
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && defaultCredentials) {
      if (defaultCredentials.username && defaultCredentials.password) {
        console.info(`[DEV] autologin to ${defaultCredentials.username}`)
        handleLogin(defaultCredentials.username, defaultCredentials.password)
      }
    }
  }, [])

  useEffect(() => {
    const storedCredentials = readCredentials()
    if (storedCredentials) {
      credentials.setRefreshToken(storedCredentials.refreshToken)
      loginUser(refreshTokenAndSave)
    }
  }, [])

  if (isBusy) {
    return <Loader />
  }

  if (!userProfile) {
    return (
      <Switch>
        <Route path="/invite/:key">
          <RegisterDialog />
        </Route>
        <Route path="/password/reset">
          <ResetPasswordDialog />
        </Route>
        <Route path="/password/confirm/:token/:email">
          <ConfirmResetPasswordDialog />
        </Route>
        <LoginDialog
          onValidate={handleLogin}
          hideBack={true}
          errorMessage={errorMessage}
          onErrorMessageClose={() => setErrorMessage('')}
        />
      </Switch>
    )
  }

  if (
    SETTINGS.app.otp_enabled &&
    !shouldRememberDevice() &&
    !twoFactorValidated &&
    userProfile.two_factor_enabled === true
  ) {
    return (
      <TwoFactorLogin
        onValidate={handleTwoFactorLogin}
        errorMessage={errorMessage}
        onErrorMessageClose={() => setErrorMessage('')}
      />
    )
  }

  const MainComponent = React.lazy(() => componentPromise)

  // This way of getting the credentials is historic and probably more complex than needed.
  // It's an import that somehow gets reinitialized, but not a state so it won't
  // trigger a re-render when set; it's not updated when we use refresh, etc.
  //
  // Also, this whole provider tree is confusing; we could probably refactor this as
  // well someday with a simple global state variable somewhere.

  return (
    <CredentialsContext.Provider value={credentials}>
      <UserContext.Provider value={userProfile}>
        <AgencySettings>
          <MapLayerWrapper>
            <ProviderWrapper>
              <Suspense fallback={<LoadingComponent />}>
                <MainComponent />
              </Suspense>
            </ProviderWrapper>
          </MapLayerWrapper>
        </AgencySettings>
      </UserContext.Provider>
    </CredentialsContext.Provider>
  )
}
