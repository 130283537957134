export enum CurrencyKind {
  Global = 'global',
  Penalty = 'penalty',
  Permit = 'permit',
}

export enum SpeedUnit {
  KilometersPerHour = 'kilometers per hour',
  MilesPerHour = 'miles per hour',
}

export enum SpeedUnitSymbol {
  KilometersPerHour = 'km/h',
  MilesPerHour = 'mph',
}

export enum DistanceUnit {
  Kilometer = 'kilometer',
  Meter = 'meter',
  Mile = 'mile',
}

export enum DistanceUnitSymbol {
  Kilometer = 'km',
  Meter = 'm',
  Mile = 'mi',
}
