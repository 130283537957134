import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

import backgroundImage from 'public/img/background.jpg'
import ResetPasswordForm from 'src/components/Forms/ResetPasswordForm'
import ErrorSnackbar, { SuccessSnackbar } from 'src/components/Snackbar'

const useStyles = makeStyles(() => ({
  dialogContainer: {
    background: `url(${backgroundImage}) no-repeat center center fixed`,
  },
}))

export default function ResetPasswordDialog() {
  const classes = useStyles()
  const history = useHistory()
  const [isOpen, setOpen] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleErrorMessageClose = () => {
    setErrorMessage('')
  }

  const handleSuccessMessageClose = () => {
    setSuccessMessage('')
    history.push('/login')
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick={true}
        classes={{ container: classes.dialogContainer }}
        aria-labelledby="form-dialog-title"
      >
        <ResetPasswordForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} />
      </Dialog>
      <ErrorSnackbar open={!!errorMessage} onClose={handleErrorMessageClose} message={errorMessage} />
      <SuccessSnackbar open={!!successMessage} onClose={handleSuccessMessageClose} message={successMessage} />
    </div>
  )
}
