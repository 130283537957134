import kebabCase from 'lodash/kebabCase'
import portableFetch from 'portable-fetch'

import SETTINGS from '../settings'

import { BaseAPI, FetchAPI } from './gen/api'
import { apiPost } from './helpers'

const OAUTH_TOKEN_PATH = '/authent/token/'
const INVITATION_PATH = '/invitation/validate_token/'
const REGISTRATION_PATH = '/invitation/confirm_registration/'
const RESET_PASSWORD_PATH = '/password/reset'
const CONFIRM_RESET_PASSWORD_PATH = '/password/reset/confirm'

interface IAuthenticationPayload {
  password: string
  username: string
}

interface IOAuthCredentials {
  clientId: string
  clientSecret: string
}

interface IOAuthCredentialsResponse {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

export interface IValidateTokenResponse {
  message?: string
  status: string
  username?: string
}

export interface IConfirmRegistrationResponse {
  validation_errors?: string[]
}

const oautCredentials: IOAuthCredentials = {
  clientId: SETTINGS.app.client_id,
  clientSecret: SETTINGS.app.client_secret,
}

/**
 * For DEV purpose only, retrieve username and password from env to autologin
 */
export const defaultCredentials: IAuthenticationPayload = {
  username: process.env.REACT_APP_USERNAME || '',
  password: process.env.REACT_APP_PASSWORD || '',
}

function getBasicAuthHeader() {
  const { clientId, clientSecret } = oautCredentials

  if (!clientId || !clientSecret) {
    throw new Error('Missing OAuth2 credentials')
  }

  return 'Basic ' + new Buffer(clientId + ':' + clientSecret).toString('base64')
}

function generateOauthBody(content: { [s: string]: string }) {
  return Object.entries(content).reduce(
    (last, [key, value]) =>
      // when making a request with content-type = application/x-www-form-urlencoded
      // Non-alphanumerical characters are percent-encoded. We need to encode
      // the credentials to have the same format
      last ? `${key}=${encodeURIComponent(value)}&${last}` : `${key}=${encodeURIComponent(value)}`,
    ''
  )
}

function generateOauthHeader(headers = {}) {
  return {
    authorization: getBasicAuthHeader(),
    'Content-type': 'application/x-www-form-urlencoded',
    ...headers,
  }
}

export async function oAuthLogin(authBody: IAuthenticationPayload): Promise<IOAuthCredentialsResponse> {
  const { clientId, clientSecret } = oautCredentials

  if (!clientId || !clientSecret) {
    throw new Error('Missing OAuth2 credentials')
  }

  return await apiPost(
    OAUTH_TOKEN_PATH,
    generateOauthBody({ grant_type: 'password', ...authBody }),
    generateOauthHeader(),
    false
  )
}

export async function oAuthRefresh(refreshToken: string): Promise<IOAuthCredentialsResponse> {
  return await apiPost(
    OAUTH_TOKEN_PATH,
    generateOauthBody({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
    generateOauthHeader(),
    false
  )
}

export async function validateInvitation(token: string): Promise<IValidateTokenResponse> {
  return await apiPost(
    INVITATION_PATH,
    { key: token },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    true
  )
}

export async function confirmRegistration(username: string, password: string): Promise<IConfirmRegistrationResponse> {
  return await apiPost(
    REGISTRATION_PATH,
    { username: username, password: password },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    true
  )
}

export async function resetPassword(email: string): Promise<any> {
  return await apiPost(
    RESET_PASSWORD_PATH,
    { email },
    {
      'Content-Type': 'application/json',
    },
    true
  )
}

export async function confirmResetPassword(email: string, password: string, token: string): Promise<any> {
  return await apiPost(
    CONFIRM_RESET_PASSWORD_PATH,
    { email, password },
    {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    true
  )
}

export class LongLiveTokenAPI extends BaseAPI {
  public longLiveTokenRetrieve(providerId: string, tokenDuration: number): Promise<any> {
    return this.longLiveTokenRetrieveFetch(providerId, tokenDuration)(this.fetch, this.basePath)
  }

  public longLiveTokenRevoke(token: string): Promise<any> {
    return this.longLiveTokenRevokeFetch(token)(this.fetch, this.basePath)
  }

  public oauthAppCreation(providerName: string, providerId: string): Promise<any> {
    return this.oauthAppCreationFetch(providerName, providerId)(this.fetch, this.basePath)
  }

  public oauthAppDeletion(providerId: string): Promise<any> {
    return this.oauthAppDeletionFetch(providerId, false)(this.fetch, this.basePath)
  }

  public oauthAppRevoke(providerId: string): Promise<any> {
    return this.oauthAppDeletionFetch(providerId, true)(this.fetch, this.basePath)
  }

  private longLiveTokenRetrieveFetch(
    providerId: string,
    tokenDuration: number
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      return fetch(basePath + '/long_lived_token/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          provider_id: providerId,
          token_duration: tokenDuration,
        }),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }

  private longLiveTokenRevokeFetch(token: string): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      return fetch(basePath + '/long_lived_token/', {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_token: token,
        }),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }

  private oauthAppCreationFetch(
    providerName: string,
    providerId: string
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      return fetch(basePath + '/create_application/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          app_name: kebabCase(providerName),
          provider_id: providerId,
        }),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }

  private oauthAppDeletionFetch(
    providerId: string,
    revokeOnly = true
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      return fetch(basePath + '/delete_application/', {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          provider_id: providerId,
          delete: !revokeOnly,
        }),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }
}
