import contentDisposition from 'content-disposition'
import streamSaver from 'streamsaver'
import { WritableStream } from 'web-streams-polyfill'

import SETTINGS from 'src/settings'

streamSaver.WritableStream = WritableStream
// in production, the backend has the same address as the front because it serves it directly
streamSaver.mitm = `${SETTINGS.debug ? process.env.REACT_APP_SERVER_HOSTNAME : ''}/export-proxy/`

export async function downloadExport(response: Response) {
  if (!response.body) {
    return
  }

  // Get filename in response headers
  const content = response.headers.get('Content-Disposition')
  const { parameters } = contentDisposition.parse(content || '')
  const { filename = 'exports.xlsx' } = parameters

  // Stream response data
  const fileStream = streamSaver.createWriteStream(filename)
  const writer: WritableStreamDefaultWriter = fileStream.getWriter()

  // abort download when user closes the window
  function unloadListener() {
    fileStream.abort()
    writer.abort()
  }

  // Write (pipe) manually
  // some day in the future we will just do
  // return response.body.pipeTo(fileStream);
  try {
    window.addEventListener('unload', unloadListener)
    const reader = response.body.getReader()
    await writeStreamToFile(reader, writer)
  } finally {
    window.removeEventListener('unload', unloadListener)
    if (!writer.closed) {
      await writer.close()
    }
  }
}

async function writeStreamToFile(reader: ReadableStreamDefaultReader<Uint8Array>, writer: WritableStreamDefaultWriter) {
  const streamResult = await reader.read()
  if (streamResult.done) {
    await writer.close()
  } else {
    await writer.write(streamResult.value)
    await writeStreamToFile(reader, writer)
  }
}
