import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import momentDurationFormatSetup from 'moment-duration-format'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import urljoin from 'url-join'

import packageJson from 'package.json'

import { loadGoogleAnalytics } from './commons/analytics'
import ScrollToTop from './components/ScrollToTop'
import App from './layout'
import './index.css'
import SETTINGS from './settings'
import theme from './theme'

const VALID_LANGUAGES = ['fr', 'en', 'en-gb']

function getBrowserLanguage() {
  // navigator.userLanguage for IE
  return navigator.languages ? navigator.languages[0] : navigator.language || (navigator as any).userLanguage
}

export function getValidLanguage(language: string, validLanguages: string[], fallback: string) {
  if (validLanguages.some((element) => language.indexOf(element) != -1)) {
    return language
  }
  return fallback
}

export function configure() {
  momentDurationFormatSetup(moment as any)

  const language = getValidLanguage(getBrowserLanguage(), VALID_LANGUAGES, SETTINGS.app.language)

  if (SETTINGS.app.sentry_dsn) {
    Sentry.init({
      dsn: SETTINGS.app.sentry_dsn,
      debug: SETTINGS.debug,
      release: packageJson.version,
      environment: SETTINGS.app.brand_id,
    })
  }

  i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      debug: process.env.NODE_ENV !== 'production',
      fallbackLng: SETTINGS.app.language,
      lng: language,
      load: 'languageOnly',

      backend: {
        // Required because the frontend might not be exposed at the domain root
        loadPath: urljoin(SETTINGS.basePath, '/locales/{{lng}}/{{ns}}.json'),
        // Append the git hash so that the URL is saw as new on each release
        queryStringParams: { v: packageJson.version },
      },

      // allow keys to be phrases having `:`, `.`
      nsSeparator: false,
      keySeparator: false,

      // do not consider null or "" as translations
      returnNull: false,
      returnEmptyString: false,

      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true, // Wait for namespace to be loaded before displaying child component
        // see https://github.com/i18next/react-i18next/issues/796
        // and https://github.com/i18next/react-i18next/issues/1124
        // we don't need the binding as we never change the language dynamically, also
        // it has bugs with React 16.13 when trying to update the state. This both improves
        // performance, and enables us to change the language on-demand without console errors.
        bindI18n: false,
        useSuspense: false,
      },
    })

  moment.locale(language)
  moment.tz.setDefault(SETTINGS.app.timezone)
}

export async function bootstrapApp(rootElementId: string) {
  if (SETTINGS.app.google_analytics_id) {
    await loadGoogleAnalytics(SETTINGS.app.google_analytics_id, SETTINGS.app.google_analytics_cookie_flags)
  }

  ReactDOM.render(
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </MuiThemeProvider>
    </React.Fragment>,
    document.getElementById(rootElementId) as HTMLElement
  )
}
