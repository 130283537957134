import { FormControlLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import useDigitInput, { InputAttributes } from 'react-digit-input'
import { useTranslation } from 'react-i18next'

import backgroundImage from 'public/img/background.jpg'
import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'
import { forgetDevice, registerDevice, shouldRememberDevice } from 'src/api/2fa-storage'
import ErrorSnackbar from 'src/components/Snackbar'

export const REMEMBER_KEY = 'plato.remember2fa'

interface ITwoFactorLoginProps {
  errorMessage: string
  onErrorMessageClose?: () => void
  onValidate: (digits: string) => void
}

export default function TwoFactorLogin(props: ITwoFactorLoginProps) {
  const classes = useStyles()
  const [isOpen, setOpen] = useState<boolean>(true)
  const [isErrorMessageOpen, setErroMesgOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props.errorMessage) {
      setErroMesgOpen(true)
    }
  }, [props.errorMessage])

  const handleClose = () => {
    setOpen(false)
  }

  const handleErrorMessageClose = () => {
    setErroMesgOpen(false)
    if (props.onErrorMessageClose) {
      props.onErrorMessageClose()
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick={true}
        classes={{ container: classes.dialogContainer }}
        aria-labelledby="form-dialog-title"
      >
        <TwoFactorLoginForm onValidate={props.onValidate} />
      </Dialog>
      <ErrorSnackbar open={isErrorMessageOpen} onClose={handleErrorMessageClose} message={props.errorMessage} />
    </div>
  )
}

interface IInputDecimalProps {
  autoFocus: boolean
  index: number
  inputAttributes: InputAttributes[]
  key: number
}

function InputDecimal(props: IInputDecimalProps) {
  const classes = useStyles()

  return (
    <>
      {props.autoFocus ? (
        <input inputMode="decimal" autoFocus {...props.inputAttributes[props.index]} className={classes.inputStyle} />
      ) : (
        <input inputMode="decimal" {...props.inputAttributes[props.index]} className={classes.inputStyle} />
      )}
    </>
  )
}

interface ITwoFactorLoginFormProps {
  onValidate: (digits: string) => void
}

function TwoFactorLoginForm(props: ITwoFactorLoginFormProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [rememberChecked, setRememberChecked] = useState<boolean>(shouldRememberDevice())
  const [value, onChange] = useState(' ')
  // Here we're using react-digit-input package. In case we need to modify its source code, dont hesitate
  // to copy/paste it from https://github.com/ForbesLindesay/react-digit-input and uninstall it.
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange,
  })

  const numbersOfDigits = [0, 1, 2, 3, 4, 5]

  function handleRememberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRememberChecked(event.target.checked)
  }

  function onClick(value: string) {
    rememberChecked ? registerDevice() : forgetDevice()
    props.onValidate(value)
  }

  return (
    <DialogContent className={classes.twoFactorContent}>
      <img src={blueSystemsLogo} className={classes.logo} />
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        spacing={1}
        className={classes.infoContainer}
      >
        <Grid item component={Typography} variant="h6">
          {t('login.2fa.title')}
        </Grid>
        <Grid item component={Typography}>
          {t('login.2fa.infoMessage')}
        </Grid>
        <Grid item component={Typography}>
          {t('login.2fa.infoMessage2')}
        </Grid>
      </Grid>
      <FormControl fullWidth={true}>
        <div className={classes.inputGroup}>
          {numbersOfDigits.map((index) => (
            <InputDecimal key={index} index={index} autoFocus={index === 0 ? true : false} inputAttributes={digits} />
          ))}
        </div>
      </FormControl>
      <FormControlLabel
        className={classes.rememberDevice}
        control={<Checkbox color="primary" checked={rememberChecked} onChange={handleRememberChange} />}
        label={t('login.2fa.rememberCheckbox')}
      />
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!value || /\s+/.test(value) ? true : false}
          onClick={() => onClick(value)}
        >
          {t('button.validate')}
        </Button>
        <Typography className={classes.contactText}>{t('login.2fa.contactMessage')}</Typography>
      </div>
    </DialogContent>
  )
}

const useStyles = makeStyles(() => ({
  contactText: {
    fontSize: '9px',
    color: '#757575',
    marginTop: '12px',
    textAlign: 'center',
  },
  dialogContainer: {
    background: `url(${backgroundImage}) no-repeat center center fixed`,
  },
  button: {
    width: '100%',
    height: 35,
  },
  buttonWrapper: {
    width: '100%',
  },
  inputStyle: {
    width: '45px',
    height: '55px',
    margin: '0 5px',
    textAlign: 'center',
    outline: 'none',
    border: '1px solid #ccc',
    borderRadius: 3,
    '&:focus': { borderColor: '#0171e4' },
  },
  infoContainer: {
    color: '#757575',
    textAlign: 'center',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logo: {
    width: 300,
    'max-width': '100%',
  },
  rememberDevice: {
    marginBottom: '-25px',
    color: '#757575',
    fontSize: '12px',
  },
  twoFactorContent: {
    height: 500,
    width: 470,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}))
