import { JurisdictionApi as BaseJurisdictionApi } from './gen/api'

export class JurisdictionApi extends BaseJurisdictionApi {
  public changeJurisdiction(jurisdiction: string): Promise<any> {
    return this.fetch(this.basePath + '/change_jurisdiction/', {
      method: 'POST',
      body: JSON.stringify({ jurisdiction }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw response
      }
    })
  }
}
