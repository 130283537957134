import i18next from 'i18next'
import deburr from 'lodash/deburr'
import lowerCase from 'lodash/lowerCase'

import { DistanceUnit } from 'src/api/types/units'

const sortLabels = (e1: string, e2: string) => {
  const lhs = deburr(lowerCase(e1))
  const rhs = deburr(lowerCase(e2))
  if (lhs < rhs) {
    return -1
  }
  if (lhs > rhs) {
    return 1
  }
  return 0
}

export const sortObjectsByLabel = (e1: { label: string }, e2: { label: string }) => {
  return sortLabels(e1.label, e2.label)
}

export const formatNumber = (value?: number, locale?: string): string | undefined => {
  if (value === undefined) {
    return undefined
  }

  const localeToUse = locale || i18next.language
  let numericFormatter = new Intl.NumberFormat(localeToUse)

  if (Math.abs(value) >= 10e5) {
    numericFormatter = new Intl.NumberFormat(localeToUse, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore : TypeScript still "thinks" that notation is experimental, but it is not the case anymore :
      // ==> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      notation: 'compact',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore : TypeScript still "thinks" that notation is experimental, but it is not the case anymore :
      // ==> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      compactDisplay: 'short',
    })
  }

  return numericFormatter.format(value)
}

export const insert = (list: any[], element: any, index: number) => list.splice(index, 0, element)

export const convertDistance = (distance: number, inputUnit: DistanceUnit, outputUnit: DistanceUnit): number => {
  let convertedDistance = null
  if (inputUnit === DistanceUnit.Meter && outputUnit === DistanceUnit.Kilometer) {
    convertedDistance = distance / 1000
  }
  if (inputUnit === DistanceUnit.Kilometer && outputUnit === DistanceUnit.Meter) {
    convertedDistance = distance * 1000
  }
  if (inputUnit === DistanceUnit.Meter && outputUnit === DistanceUnit.Mile) {
    convertedDistance = distance / 1609.344
  }
  if (inputUnit === DistanceUnit.Mile && outputUnit === DistanceUnit.Meter) {
    convertedDistance = distance * 1609.344
  }
  if (typeof convertedDistance !== 'number') {
    throw Error('The input and output distance units do not match any known combinaison of distance units')
  }
  return Math.round(convertedDistance * 100) / 100
}

function lexicographicCompare(labelA: string, labelB: string): number {
  const matchA = labelA.match(/\d+/)
  const matchB = labelB.match(/\d+/)
  if (matchA && matchA.index === 0 && matchB && matchB.index === 0) {
    // Both labels starts with digits
    return parseInt(matchA[0], 10) - parseInt(matchB[0], 10)
  }

  if (
    matchA &&
    matchB &&
    matchA.index === matchB.index &&
    labelA.slice(0, matchA.index) === labelB.slice(0, matchB.index)
  ) {
    // Both labels starts with same string and have a number => we can sort using this number
    return parseInt(matchA[0], 10) - parseInt(matchB[0], 10)
  }

  return labelA.toLowerCase() > labelB.toLowerCase() ? 1 : -1
}

export function lexicographicSort(collections: any[], key = 'label') {
  return collections.sort((a, b) => lexicographicCompare(a[key] || '', b[key] || ''))
}
