import merge from 'lodash/merge'

import { LongLiveTokenAPI, oAuthRefresh } from './auth-api'
import Credentials from './Credentials'
import { ElasticApi } from './esclient'
import * as api from './gen/api'
import { fetchOrFail, URLbasename } from './helpers'
import { JurisdictionApi } from './jurisdiction-api'
import { PermitsCapsAPI } from './permits-caps-api'
import { clearCredentials, saveCredentials } from './storage'

const refreshFetch = require('refresh-fetch')

export interface IAPI {
  agencySettings: api.AgencyApi
  areas: api.ServiceAreasApi
  auditLogs: api.AuditLogsApi
  bestmile: api.BestmileApi
  esclient: ElasticApi
  eventRecords: api.EventRecordsApi
  gbfs: api.GbfsDataApi
  graphs: api.GraphsApi
  homepage: api.HomepageApi
  hopon: api.HoponApi
  jurisdiction: JurisdictionApi
  mdsApiCredentials: api.MdsCredentialsApi
  offenses: api.OffensesApi
  password: api.PasswordApi
  permitsCaps: PermitsCapsAPI
  policies: api.PoliciesApi
  polygons: api.PolygonsApi
  providers: api.ProvidersApi
  reports: api.ReportsApi
  stops: api.StopsApi
  toggle2fa: api.UserToggle2faApi
  token: LongLiveTokenAPI
  totp: api.UserTotpApi
  trips: api.TripApi
  userinfo: api.UserinfoApi
  users: any
  validate2fa: api.UserValidate2faApi
  vehicles: api.VehiclesApi
}

/** We need a context to access user information across all app */
interface IAuthContext {
  api: IAPI
  credentials: Credentials
  refreshTokenAndSave: () => Promise<void>
}

const shouldRefreshToken = (response: any) => {
  return response.status === 401
}

/**
 */
function buildAuthApi(): IAuthContext {
  const credentials = new Credentials()

  const logoutUser = () => {
    clearCredentials()
    window.location.href = '/'
  }

  const refreshTokenAndSave = async () => {
    try {
      const resp = await oAuthRefresh(credentials.getRefreshToken())
      credentials.setAccessToken(resp.access_token)
      credentials.setRefreshToken(resp.refresh_token)
      saveCredentials({ refreshToken: resp.refresh_token })
    } catch (error) {
      logoutUser()
      throw error
    }
  }

  const overriddenFetch = refreshFetch.configureRefreshFetch({
    fetch: (url: string, options = {}) => {
      const accessToken = credentials.getAccessToken()

      let optionsWithToken = options
      if (accessToken) {
        optionsWithToken = merge({}, options, {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        })
      }
      return fetchOrFail(url, optionsWithToken)
    },
    refreshToken: refreshTokenAndSave,
    shouldRefreshToken: shouldRefreshToken,
  })

  const basePath = `${URLbasename}/prv`
  const authBasePath = `${basePath}/authent`
  return {
    api: {
      auditLogs: new api.AuditLogsApi({}, basePath, overriddenFetch),
      agencySettings: new api.AgencyApi({}, basePath, overriddenFetch),
      areas: new api.ServiceAreasApi({}, basePath, overriddenFetch),
      bestmile: new api.BestmileApi({}, basePath, overriddenFetch),
      esclient: new ElasticApi({}, basePath, overriddenFetch),
      eventRecords: new api.EventRecordsApi({}, basePath, overriddenFetch),
      gbfs: new api.GbfsDataApi({}, basePath, overriddenFetch),
      graphs: new api.GraphsApi({}, basePath, overriddenFetch),
      homepage: new api.HomepageApi({}, basePath, overriddenFetch),
      hopon: new api.HoponApi({}, basePath, overriddenFetch),
      jurisdiction: new JurisdictionApi({}, basePath, overriddenFetch),
      mdsApiCredentials: new api.MdsCredentialsApi({}, basePath, overriddenFetch),
      offenses: new api.OffensesApi({}, basePath, overriddenFetch),
      password: new api.PasswordApi({}, basePath, overriddenFetch),
      permitsCaps: new PermitsCapsAPI({}, basePath, overriddenFetch),
      policies: new api.PoliciesApi({}, basePath, overriddenFetch),
      polygons: new api.PolygonsApi({}, basePath, overriddenFetch),
      providers: new api.ProvidersApi({}, basePath, overriddenFetch),
      reports: new api.ReportsApi({}, basePath, overriddenFetch),
      stops: new api.StopsApi({}, basePath, overriddenFetch),
      toggle2fa: new api.UserToggle2faApi({}, basePath, overriddenFetch),
      token: new LongLiveTokenAPI({}, authBasePath, overriddenFetch),
      totp: new api.UserTotpApi({}, basePath, overriddenFetch),
      trips: new api.TripApi({}, basePath, overriddenFetch),
      userinfo: new api.UserinfoApi({}, basePath, overriddenFetch),
      users: {
        logout: logoutUser,
      },
      validate2fa: new api.UserValidate2faApi({}, basePath, overriddenFetch),
      vehicles: new api.VehiclesApi({}, basePath, overriddenFetch),
    },
    credentials,
    refreshTokenAndSave,
  }
}

const authApi = buildAuthApi()

export const refreshTokenAndSave = authApi.refreshTokenAndSave
export const credentials = authApi.credentials

export default authApi.api
