const KEY = 'plato.credentials'

interface IStoredCredentials {
  refreshToken: string
}

export const saveCredentials = (storedCredentials: IStoredCredentials) => {
  localStorage.setItem(KEY, JSON.stringify(storedCredentials))
}

export const readCredentials = (): IStoredCredentials | null => {
  const stored = localStorage.getItem(KEY)
  if (!stored) {
    return null
  }

  try {
    const storedCredentials = JSON.parse(stored)
    if (!storedCredentials || !storedCredentials.refreshToken) {
      return null
    }
    return storedCredentials
  } catch (e) {
    return null
  }
}

export const clearCredentials = () => {
  localStorage.removeItem(KEY)
}
