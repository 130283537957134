import { TFunction } from 'i18next'

let tFunction: unknown = null

export function saveTranslation(t: TFunction) {
  tFunction = t
}

export function getTranslation() {
  // Need to get it dynamically or it will get optimized out by TS and
  // appears undefined upon import
  // Note the same signature as useTranslation() just for convenience.
  return { t: tFunction as TFunction }
}
