class Credentials {
  private refreshToken = ''
  private accessToken = ''
  private tokenType = 'bearer'

  public getTokenType = () => this.tokenType
  public setTokenType = (type: string) => (this.tokenType = type)
  public getRefreshToken = () => this.refreshToken
  public getAccessToken = () => this.accessToken
  public setAccessToken = (token: string) => (this.accessToken = token)
  public setRefreshToken = (token: string) => (this.refreshToken = token)

  public clearTokens = () => {
    this.refreshToken = ''
    this.accessToken = ''
  }
}

export default Credentials
