import url from 'url'

import portableFetch from 'portable-fetch'

import { BaseFetchAPI } from './base-api'
import { FetchAPI } from './gen/api'

export class ElasticApi extends BaseFetchAPI {
  /**
   * Retrieve all kpi registered
   */
  public kpiSearch(index: string, body: any): Promise<any> {
    return this.kpiSearchES(index, body)(this.fetch, this.basePath)
  }

  private kpiSearchES(index: string, body: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      const path = `/es/kpi-search`
      const urlObj = url.parse(path, true)
      const queryParameter = { index } as any

      urlObj.query = Object.assign({}, urlObj.query, queryParameter)

      return this.postFetch(url.format(urlObj), body)(fetch, basePath)
    }
  }
}
