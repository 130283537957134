import urljoin from 'url-join'

import { downloadExport } from 'src/api/downloadExport'

export function handleGeneratedApiResponse(response: Response): Promise<any> | Promise<void> | any {
  if (200 <= response.status && response.status < 300) {
    if (response.headers.get('Content-Type') === 'application/json') {
      return response.json()
    }
    if (response.headers.get('Content-Type') === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      downloadExport(response)
    }
    return response
  } else {
    throw new Error(`Responses with a status code like ${response.status} are considered errors.`)
  }
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

// In production, the backend has the same address as the front because it serves it directly
export const URLbasename = urljoin(process.env.REACT_APP_SERVER_HOSTNAME || window.location.origin, '/api')

export async function fetchOrFail(url: string, options: RequestInit): Promise<any> {
  const res = await fetch(url, options)
  if (!res.ok) {
    const error: any = new Error(`${res.status} code when requesting ${res.url}`)
    error.status = res.status
    try {
      error.json = await res.json()
    } catch (err) {
      console.warn('Non-2xx response received without a JSON body')
    }
    throw error
  }

  return res
}

export async function fetchJson(
  path: string,
  method: HttpMethod = 'GET',
  initHeaders: any = {},
  body?: any,
  json = true
): Promise<any> {
  const headers = new Headers(initHeaders)
  if (json) {
    headers.set('Content-Type', 'application/json;charset=UTF-8')
  }

  const options: RequestInit = {
    headers,
    method,
    body,
  }

  if (body) {
    if (body instanceof FormData || !json) {
      options.body = body
    } else {
      options.body = JSON.stringify(body)
    }
  }

  const res = await fetchOrFail(urljoin(URLbasename, path), options)
  const contentType = res.headers.get('Content-Type')
  if (!contentType || contentType.indexOf('application/json') === -1) {
    return null
  }

  return res.json()
}

export async function apiGet(url: string, headers: any = {}): Promise<any> {
  return fetchJson(url, 'GET', headers)
}

export async function apiPost(url: string, body?: any, headers: any = {}, json = true): Promise<any> {
  return fetchJson(url, 'POST', headers, body, json)
}

export async function apiPatch(url: string, body?: any, headers: any = {}): Promise<any> {
  return fetchJson(url, 'PATCH', headers, body)
}

export async function apiPostMultipart(url: string, body?: any, headers: any = {}): Promise<any> {
  return fetchJson(url, 'POST', headers, body, false)
}

export async function apiPutMultipart(url: string, body?: any, headers: any = {}): Promise<any> {
  return fetchJson(url, 'PUT', headers, body, false)
}

export async function apiPut(url: string, body?: any): Promise<any> {
  return fetchJson(url, 'PUT', {}, body)
}

export async function apiDelete(url: string, body?: any): Promise<any> {
  return fetchJson(url, 'DELETE', {}, body)
}
