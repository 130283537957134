import { Ability, AbilityBuilder } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'

import { DomainPermission_DomainEnum, DomainPermission_VerbEnum } from 'src/api/gen'

type AbilityActions = keyof typeof DomainPermission_VerbEnum
type AbilitySubjects = keyof typeof DomainPermission_DomainEnum

type AbilityType = Ability<[AbilityActions, AbilitySubjects]>

export type Permission = [AbilityActions, AbilitySubjects]

const ability = new Ability<[AbilityActions, AbilitySubjects]>()

export const Can = createCanBoundTo(ability)

export function updateUserPermissions(permissions: Permission[]): void {
  const { can: allow, rules } = new AbilityBuilder<AbilityType>()

  for (const permission of permissions) {
    const [action, subject] = permission
    if (!action || !subject) {
      throw new Error(`trying to update permissions with invalid action=${action} topic=${subject}`)
    }
    allow(action, subject)
  }

  ability.update(rules)
}

export function userHasPermissions(permissions: Permission[]): boolean {
  return permissions.every(userHasPermission)
}

export function userHasAtLeastOnePermission(permissions: Permission[]): boolean {
  return permissions.length > 0 ? permissions.some(userHasPermission) : true
}

export function userHasPermission(permission: Permission): boolean {
  const [action, subject] = permission
  return ability.can(action, subject)
}
