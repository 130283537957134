const KEY = 'plato.remember2fa'

export function registerDevice() {
  localStorage.setItem(KEY, ' ')
}

export function forgetDevice() {
  localStorage.removeItem(KEY)
}

export function shouldRememberDevice() {
  return !!localStorage.getItem(KEY)
}
