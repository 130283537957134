import { upperFirst } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import api from 'src/api'
import Credentials from 'src/api/Credentials'
import { Jurisdiction, UserInfo } from 'src/api/gen'
import { lexicographicSort } from 'src/commons/helpers'
import { IViewport } from 'src/commons/map'
import { IProviderContext, ProviderContext } from 'src/layout/ProviderWrapper'

export type userScopes = string[]

export const CredentialsContext = React.createContext<Credentials | undefined>(undefined)
export const UserContext = React.createContext<UserInfo | undefined>(undefined)

export const useProviders = (): IProviderContext => useContext(ProviderContext)

export const useIsProvidersLoaded = (): boolean => useContext(ProviderContext).isLoaded

export function useUserProfile() {
  const context = useContext(UserContext)
  return context
}

export function useProviderUser(): boolean {
  const { is_provider_user } = useUserProfile()!
  return is_provider_user
}

export function useCurrentJurisdiction(): Jurisdiction {
  const userProfile = useUserProfile()
  // We are sure to have a userContext once we login, and we don't use useCurrentJurisdiction before that.
  // Thus we can use `!`
  return userProfile!.jurisdiction
}

export function useCurrentJurisdictionKey() {
  const jurisdiction = useCurrentJurisdiction()
  return jurisdiction.agency_key
}

export function useJurisdictionMapping(): Array<{ id: string; name: string }> {
  const { groups } = useUserProfile()!
  const uniqueKeys = new Set(groups.map((g) => g.jurisdiction_key))

  return lexicographicSort(
    Array.from(uniqueKeys).map((key) => ({
      id: key,
      name: groups.find((g) => g.jurisdiction_key === key)!.jurisdiction,
    })),
    'name'
  )
}

export function useCityBoundaries(): string {
  const jurisdiction = useCurrentJurisdiction()
  return jurisdiction.default_layer
}

export function useViewport(): IViewport {
  const jurisdiction = useCurrentJurisdiction()
  const { center, zoom } = jurisdiction.map_config
  const { latitude: lat, longitude: lng } = center

  return {
    center: { lng, lat },
    zoom,
  }
}

export function useCredentials() {
  const context = useContext(CredentialsContext)
  return context
}

export function usePolicyIds() {
  const [policyIds, setPolicyIds] = useState<string[]>([])
  const fetchPolicies = useCallback(async () => api.policies.policiesListLight(), [api])

  useEffect(() => {
    fetchPolicies().then((response) => setPolicyIds(response.results.map((policy) => policy.id)))
  }, [api])
  return policyIds
}

export function usePasswordValidation(password: string, passwordConfirm: string) {
  const { t } = useTranslation()
  const [hasValidLength, setHasValidLength] = useState<boolean>(false)
  const [hasNumber, setHasNumber] = useState<boolean>(false)
  const [hasUpperCase, setHasUpperCase] = useState<boolean>(false)
  const [hasLowerCase, setHasLowerCase] = useState<boolean>(false)
  const [areMatching, setPasswordsMatch] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

  // labels and state boolean corresponding to each validation
  const checkRules: Array<[string, boolean]> = [
    [upperFirst(t('At least 12 characters')), hasValidLength],
    [upperFirst(t('A number (0-9)')), hasNumber],
    [upperFirst(t('An uppercase letter (A-Z)')), hasUpperCase],
    [upperFirst(t('A lowercase letter (a-z)')), hasLowerCase],
    [upperFirst(t('Passwords match')), areMatching],
  ]

  useEffect(() => {
    setHasValidLength(password.length >= 12 ? true : false)
    setHasUpperCase(password.toLowerCase() !== password)
    setHasLowerCase(password.toUpperCase() !== password)
    setHasNumber(/\d/.test(password))
    setPasswordsMatch(password === passwordConfirm)
  }, [password, passwordConfirm])

  useEffect(() => {
    setDisableSubmit(!(hasValidLength && hasNumber && hasUpperCase && hasLowerCase && areMatching))
  }, [hasValidLength, hasNumber, hasUpperCase, hasLowerCase, areMatching])

  return { checkRules, disableSubmit }
}
