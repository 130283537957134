import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import backgroundImage from 'public/img/background.jpg'
import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'
import { confirmResetPassword } from 'src/api/auth-api'
import ChangePasswordForm from 'src/components/Forms/ChangePasswordForm'
import RulesValidator, { GridDirection } from 'src/components/Forms/RulesValidator'
import ErrorSnackbar, { SuccessSnackbar } from 'src/components/Snackbar'
import { usePasswordValidation } from 'src/layout/hooks'

const useStyles = makeStyles(() => ({
  dialogContainer: {
    background: `url(${backgroundImage}) no-repeat center center fixed`,
  },
  logo: {
    width: 300,
    'max-width': '100%',
  },
  content: {
    height: 500,
    width: 470,
    'max-width': '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}))

interface IConfirmResetPasswordParams {
  email: string
  token: string
}

export default function ConfirmResetPasswordDialog() {
  const { t } = useTranslation()
  const { email, token } = useParams<IConfirmResetPasswordParams>()
  const history = useHistory()
  const classes = useStyles()
  const [isOpen, setOpen] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const { checkRules, disableSubmit } = usePasswordValidation(password, passwordConfirm)

  const handleClose = () => {
    setOpen(false)
  }

  const handleErrorMessageClose = () => {
    setErrorMessage('')
  }

  const handleSuccessMessageClose = () => {
    setSuccessMessage('')
  }

  async function sendConfirmResetPassword(password: string): Promise<boolean> {
    try {
      await confirmResetPassword(email, password, token)
    } catch (error) {
      console.error(error.json)
      setErrorMessage(Object.values<string>(error.json)[0])
      return false
    }
    return true
  }

  async function handleButtonClick(password: string) {
    if (await sendConfirmResetPassword(password)) {
      setSuccessMessage(t('register.password_set_success_redirect'))
      setTimeout(() => {
        history.push('/')
      }, 3000)
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick={true}
        classes={{ container: classes.dialogContainer }}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.content}>
          <img src={blueSystemsLogo} className={classes.logo} />
          <ChangePasswordForm
            handleButtonClick={handleButtonClick}
            checkRules={checkRules}
            disableSubmit={disableSubmit}
            password={password}
            setPassword={setPassword}
            setPasswordConfirm={setPasswordConfirm}
          />
          <RulesValidator checkRules={checkRules} rulesDirection={GridDirection.Row} />
        </DialogContent>
      </Dialog>
      <ErrorSnackbar open={!!errorMessage} onClose={handleErrorMessageClose} message={errorMessage} />
      <SuccessSnackbar open={!!successMessage} onClose={handleSuccessMessageClose} message={successMessage} />
    </div>
  )
}
