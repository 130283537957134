import React, { useCallback, useEffect, useState } from 'react'

import api from 'src/api'
import { Provider } from 'src/api/gen/api'

interface IProps {
  children?: React.ReactNode
}

export interface IProviderContext {
  activeProviders: Provider[]
  activeProvidersIds: string[]
  addProvider?: (provider: Provider) => void
  allProviders: Provider[]
  colors: Record<string, string>
  isLoaded: boolean
  updateProvider?: (provider: Provider) => void
}

const initialProviderContextValue = {
  isLoaded: false,
  allProviders: [],
  activeProviders: [],
  activeProvidersIds: [],
  colors: {},
}

export const ProviderContext = React.createContext<IProviderContext>(initialProviderContextValue)

// Exported in order to expect on the value of the context in our tests
export let _testContextValue: IProviderContext = initialProviderContextValue

const ProviderWrapper = (props: IProps) => {
  const [providerContextValue, setProviderContextValue] = useState<IProviderContext>(initialProviderContextValue)
  const addProvider = useCallback(
    function (provider: Provider) {
      setProviderContextValue((prevState) => ({
        ...prevState,
        ...computeProvidersAttributes(prevState.allProviders.concat([provider])),
      }))
    },
    [setProviderContextValue]
  )
  const updateProvider = useCallback(
    function (providerToUpdate: Provider) {
      setProviderContextValue((prevState) => ({
        ...prevState,
        ...computeProvidersAttributes(
          prevState.allProviders.map((provider) => (provider.id === providerToUpdate.id ? providerToUpdate : provider))
        ),
      }))
    },
    [setProviderContextValue]
  )

  // Only for testing purposes
  _testContextValue = providerContextValue

  useEffect(() => {
    fetchProvidersList().then((fetchedProviders) => {
      setProviderContextValue({
        ...computeProvidersAttributes(fetchedProviders),
        addProvider: addProvider,
        updateProvider: updateProvider,
        isLoaded: true,
      })
    })
  }, [])

  return <ProviderContext.Provider value={providerContextValue}>{props.children}</ProviderContext.Provider>
}

async function fetchProvidersList(): Promise<Provider[]> {
  try {
    return await api.providers.providersList()
  } catch (error) {
    console.error(error)
    return []
  }
}

export function computeProvidersAttributes(updatedProviders: Provider[]) {
  return {
    allProviders: updatedProviders,
    activeProviders: updatedProviders.filter((provider) => provider.is_active),
    activeProvidersIds: updatedProviders.filter((provider) => provider.is_active).map((provider) => provider.id),
    colors: getProvidersColorsMapping(updatedProviders),
  }
}

export function getProvidersColorsMapping(providers: Provider[]): Record<string, string> {
  const mapping: Record<string, string> = {}

  providers.forEach((provider: Provider) => {
    if (provider.colors && provider.colors.primary) {
      mapping[provider.name] = provider.colors.primary
    }
  })

  return mapping
}

export default ProviderWrapper
