/**
 * load gtag script and define data layer
 * to contains all of the information that need to be passed to gtag
 */

interface IWindow extends Window {
  dataLayer: any
}

declare let window: IWindow
let _gAPIKey: string

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function _gtag(..._args: any[]) {
  if (window.dataLayer) {
    // We need to disable this due to the weird nature of Google Analytics behavior
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  } else {
    console.debug('Google Analytics was never loaded !')
  }
}

export function triggerPageview(route: string) {
  if (_gAPIKey) {
    _gtag('config', _gAPIKey, { page_path: route })
  } else {
    console.debug('no Google Analytics API Key, route was:', route)
  }
}

function _addScript(resolve: () => void, reject: () => void, gAPIKey: string) {
  const script = document.createElement('script')
  script.onload = resolve
  script.onerror = reject
  script.src = `//www.googletagmanager.com/gtag/js?id=${gAPIKey}`
  document.getElementsByTagName('head')[0].appendChild(script)
}

export async function loadGoogleAnalytics(
  gAPIKey: string,
  cookieFlags: string | undefined,
  addScript = _addScript
): Promise<void> {
  // this function is designed to be called only once
  _gAPIKey = gAPIKey
  return new Promise((resolve, reject) => {
    addScript(resolve, reject, gAPIKey)
  }).then(
    () => {
      window.dataLayer = window.dataLayer || []
      _gtag('js', new Date())
      if (cookieFlags) {
        _gtag('set', { cookie_flags: cookieFlags })
      }
      _gtag('config', gAPIKey)
    },
    () => {
      console.warn('failed to load gtag')
    }
  )
}
