import moment, { unitOfTime, Moment } from 'moment-timezone'

/* 
Note : moment() has to be called in a function so that
it uses the timezone configured from agency settings,
meaning we cannot define a const in a file namespace.
*/
export function getRange(
  from = 0,
  unit: unitOfTime.Diff = 'day',
  appromixationUnit: unitOfTime.Diff = 'day'
): { from: string; to: string } {
  return {
    from: moment().subtract(from, unit).startOf(appromixationUnit).format(),
    to: moment().format(),
  }
}

export function getMomentRange({
  from = 0,
  unit = 'day',
  appromixationUnit = 'day',
}: {
  appromixationUnit: unitOfTime.Diff
  from: number
  unit: unitOfTime.Diff
}): { from: Moment; to: Moment } {
  return {
    from: moment().subtract(from, unit).startOf(appromixationUnit),
    to: moment(),
  }
}
