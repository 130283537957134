import { Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'

const useStyles = makeStyles(() => ({
  infoPageContent: {
    height: 400,
    width: 470,
    'max-width': '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  logo: {
    width: 300,
    'max-width': '100%',
  },
}))

interface IInfoPageProps {
  message: string
}

export default function InfoPage(props: IInfoPageProps) {
  const classes = useStyles()
  return (
    <DialogContent className={classes.infoPageContent}>
      <img src={blueSystemsLogo} className={classes.logo} />
      <Typography variant="body1">
        <span style={{ fontSize: '1.19em' }}>{props.message}</span>
      </Typography>
    </DialogContent>
  )
}
