import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import backgroundImage from 'public/img/background.jpg'
import {
  validateInvitation,
  confirmRegistration,
  IValidateTokenResponse,
  IConfirmRegistrationResponse,
} from 'src/api/auth-api'
import ErrorSnackbar, { SuccessSnackbar } from 'src/components/Snackbar'

import ChangePasswordForm from './ChangePasswordForm'
import InfoPage from './InfoPage'

const useStyles = makeStyles(() => ({
  dialogContainer: {
    background: `url(${backgroundImage}) no-repeat center center fixed`,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}))

interface IKeyPathType {
  key: string
}

export default function RegisterDialog() {
  const { t } = useTranslation()
  const { key } = useParams<IKeyPathType>()
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [redirectionMessage, setRedirectionMessage] = useState<string>('')

  async function getTokenStatus() {
    setLoading(true)
    const data: IValidateTokenResponse = await validateInvitation(key)
    if (data.status == 'error') {
      if (data.message == 'token_expired') {
        setRedirectionMessage(t('register.token_expired'))
      } else if (data.message == 'already_accepted') {
        setRedirectionMessage(t('register.already_accepted'))
      } else if (data.message == 'key_not_found') {
        history.push('/login')
      }
    } else if (data.status == 'ok' && data.username) {
      setUsername(data.username)
    }
    setLoading(false)
  }

  useEffect(() => {
    getTokenStatus()
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleErrorMessageClose = () => {
    setErrorMessage('')
  }

  const handleSuccessMessageClose = () => {
    setSuccessMessage('')
  }

  async function backendValidationSuccess(password: string): Promise<boolean> {
    if (username) {
      const payload: IConfirmRegistrationResponse = await confirmRegistration(username, password)
      if (payload.validation_errors && payload.validation_errors.length > 0) {
        setErrorMessage(payload.validation_errors[0])
        return false
      }
    }
    return true
  }

  async function handleButtonClick(password: string): Promise<void> {
    if (await backendValidationSuccess(password)) {
      setSuccessMessage(t('register.password_set_success_redirect'))
      setTimeout(() => {
        history.push('/')
      }, 3000)
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick={true}
        classes={{ container: classes.dialogContainer }}
        aria-labelledby="form-dialog-title"
      >
        {isLoading ? (
          <Loader />
        ) : redirectionMessage ? (
          <InfoPage message={redirectionMessage} />
        ) : (
          <ChangePasswordForm handleButtonClick={handleButtonClick} isLoading={isLoading} username={username} />
        )}
      </Dialog>
      <ErrorSnackbar open={!!errorMessage.length} onClose={handleErrorMessageClose} message={errorMessage} />
      <SuccessSnackbar open={!!successMessage.length} onClose={handleSuccessMessageClose} message={successMessage} />
    </div>
  )
}

function Loader() {
  const classes = useStyles()
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress size={72} />
    </div>
  )
}
