import React, { useContext, useEffect } from 'react'

import api from 'src/api'
import { Feature, FeatureCollection, KPIGeometry } from 'src/api/gen/api'
import { lexicographicSort } from 'src/commons/helpers'
import useSafeState from 'src/commons/hooks/useSafeState'
import { useCurrentJurisdictionKey } from 'src/layout/hooks'

interface IProps {
  children?: React.ReactNode
}

export interface IMapLayerContext {
  isLoaded: boolean
  layers: ILayer[]
}
const initialMapLayerContextValue = {
  isLoaded: false,
  layers: [],
}

export interface ILayer {
  geojson?: FeatureCollection
  id: string
  label: string
  permitLayer: boolean
}

export function getCurrentLayer(id: string, mapLayers: any) {
  const layer = mapLayers.find((l: any) => l.id === id)
  return layer ? layer.geojson : undefined
}

export const mapLayerContext = React.createContext<IMapLayerContext>(initialMapLayerContextValue)

function MapLayerWrapper(props: IProps) {
  const { children } = props

  const [mapLayerContextValue, setLayers] = useSafeState<IMapLayerContext>(initialMapLayerContextValue)
  const jurisdictionKey = useCurrentJurisdictionKey()

  useEffect(() => {
    api.jurisdiction
      .jurisdictionKpiGeometriesList({ agency_key: jurisdictionKey })
      .then((geometries: KPIGeometry[]) => {
        const layersFromGeometries = geometries.map((geometry) => ({
          id: geometry.id,
          label: geometry.name,
          permitLayer: geometry.permit_layer,
          geojson: geometry.geom,
        }))

        const allLayers = [...mapLayerContextValue.layers, ...layersFromGeometries]
        setLayers({ isLoaded: true, layers: lexicographicSort(allLayers) })
      })
      .catch((err: string) => {
        console.error('ERROR while fetching map layers', err)
      })
  }, [])

  return <mapLayerContext.Provider value={mapLayerContextValue}>{children}</mapLayerContext.Provider>
}

export function useIsMapLayerLoaded() {
  const context = useContext(mapLayerContext)
  return context.isLoaded
}

export function useMapLayer() {
  const context = useContext(mapLayerContext)
  return context.layers
}

export function usePermitLayerIds(): string[] {
  const mapLayers = useMapLayer()
  return mapLayers.filter((layer) => layer.permitLayer).map((layer) => layer.id)
}

export function getLayerFeatures(layer: ILayer): Feature[] {
  return layer.geojson ? layer.geojson.features : []
}

export default MapLayerWrapper
