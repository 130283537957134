import { Vehicle_LastKnownStatusEnum as VehicleStateEnum } from './gen/api'

export { VehicleStateEnum }

/*
 * These are the deployed statuses (in ElasticSearch)
 * of a device.
 * - available stands for a free device
 * - reserved is for an in trip or reserved device
 * - unavailable is for a device on the street but
 *   unavailable for customer use
 *
 * The trip Enum should not be here
 * bc currently ES is provider-like, however
 * it still stores "trip" statuses,
 * So we filter on trips to get realistic data
 * Besides ES shall become agency-like.
 */
export const DEPLOYED_STATUSES = [
  VehicleStateEnum.available,
  VehicleStateEnum.reserved,
  VehicleStateEnum.on_trip,
  VehicleStateEnum.non_operational,
]

/*
 * These are the active statuses (deployed and available to customers)
 * of a device (in ElasticSearch).
 */
export const ACTIVE_STATUSES = [VehicleStateEnum.available, VehicleStateEnum.reserved, VehicleStateEnum.on_trip]

/*
 * These are the trip statuses of a device (in ElasticSearch)
 * Look at the comment of DEPLOYED_STATUSES to understand
 */
export const TRIP_STATUSES = [VehicleStateEnum.reserved, VehicleStateEnum.on_trip]
