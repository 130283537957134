import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import upperFirst from 'lodash/upperFirst'
import React, { useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    height: 35,
  },
  buttonWrapper: {
    width: '100%',
    paddingTop: '16px',
  },
  formMargin: {
    marginBottom: 20,
  },
  formRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    width: '100%',
    borderRadius: 4,
  },
  formLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    top: -8,
  },
  formInput: {
    position: 'relative',
    fontSize: 16,
    padding: '10px 12px',
    minHeight: 25,
  },
  input: { minHeight: 40 },
}))

interface IChangePasswordFormProps {
  checkRules: [string, boolean][]
  disableSubmit: boolean
  handleButtonClick(password: string): Promise<void>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  setPasswordConfirm: Dispatch<SetStateAction<string>>
}

export default function ChangePasswordForm(props: IChangePasswordFormProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showPassword, setPasswordDisplay] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setPasswordDisplay((passwordDisplayed) => !passwordDisplayed)
  }

  async function handleValidate() {
    if (props.disableSubmit) {
      return
    }
    await props.handleButtonClick(props.password)
  }

  return (
    <div>
      <div>
        <FormControl fullWidth={true} className={classes.formMargin}>
          <InputLabel htmlFor="adornment-password" className={classes.formLabel} shrink={true}>
            {upperFirst(t('password'))}
          </InputLabel>
          <InputBase
            id="adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setPassword(event.target.value)
            }}
            className={classes.input}
            fullWidth={true}
            autoFocus={true}
            classes={{
              root: classes.formRoot,
              input: classes.formInput,
            }}
            required={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={upperFirst(t('toggle password visibility'))} onClick={handleClickShowPassword}>
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="adornment-passwordConfirm" className={classes.formLabel} shrink={true}>
            {upperFirst(t('confirm password'))}
          </InputLabel>
          <InputBase
            id="adornment-passwordConfirm"
            type={showPassword ? 'text' : 'password'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setPasswordConfirm(event.target.value)
            }}
            className={classes.input}
            fullWidth={true}
            classes={{
              root: classes.formRoot,
              input: classes.formInput,
            }}
            required={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={upperFirst(t('toggle password visibility'))} onClick={handleClickShowPassword}>
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={props.disableSubmit}
          onClick={handleValidate}
        >
          {t('button.validate')}
        </Button>
      </div>
    </div>
  )
}
