import {
  HopOnVehicle_CategoryEnum,
  PolicyInput_PenaltyFrequencyEnum,
  HopOnVehicle_StatusEnum,
  Policy_FeeCalculationMethodEnum,
} from 'src/api/gen'

import { formatNumber } from './helpers'

// fake t function for translation collection
export const t = (k: string) => k

interface IEnumsTranslationKeys {
  [index: string]: { [index: string]: string }
}

export const enumsTranslationKeys: IEnumsTranslationKeys = {
  AnomalyType: {
    event_out_of_order: t('kpi.AnomaliesPerType.event_out_of_order'),
    old_event: t('kpi.AnomaliesPerType.old_event'),
    no_event_for_too_long: t('kpi.AnomaliesPerType.no_event_for_too_long'),
    trip_workflow: t('kpi.AnomaliesPerType.trip_workflow'),
    workflow_trip: t('kpi.AnomaliesPerType.workflow_trip'),
    workflow: t('kpi.AnomaliesPerType.workflow'),
    duplicate: t('kpi.AnomaliesPerType.duplicate'),
    wrong_order: t('kpi.AnomaliesPerType.wrong_order'),
    teleportation: t('kpi.AnomaliesPerType.teleportation'),
    outside_city: t('kpi.AnomaliesPerType.outside_city'),
    unknown: t('kpi.AnomaliesPerType.unknown'),
  },
  AuditLogKind: {
    area: t('area'),
    polygon: t('polygon'),
    user: t('user'),
    jurisdiction: t('jurisdiction'),
    policy: t('policy'),
    report: t('report'),
  },
  AuditLogType: {
    created: t('administration.auditLogs.created'),
    changed: t('administration.auditLogs.changed'),
    deleted: t('administration.auditLogs.deleted'),
  },
  DeviceCategory: {
    bicycle: t('device.bicycle'),
    car: t('device.car'),
    delivery_bot: t('device.deliveryBot'),
    moped: t('device.moped'),
    other: t('device.other'),
    scooter: t('device.scooter'),
    pt: t('device.public_transport'),
  },
  DevicePropulsion: {
    combustion: t('devicePropulsion.combustion'),
    electric: t('devicePropulsion.electric'),
    electric_assist: t('devicePropulsion.electricAssist'),
    human: t('devicePropulsion.human'),
  },
  EventType: {
    agency_drop_off: t('event.agency_drop_off'),
    agency_pick_up: t('event.agency_pick_up'),
    battery_charged: t('event.battery_charged'),
    battery_low: t('event.battery_low'),
    comms_lost: t('event.comms_lost'),
    comms_restored: t('event.comms_restored'),
    compliance_pick_up: t('event.compliance_pick_up'),
    decommissioned: t('event.decommissioned'),
    located: t('event.located'),
    maintenance: t('event.maintenance'),
    maintenance_pick_up: t('event.maintenance_pick_up'),
    missing: t('event.missing'),
    off_hours: t('event.off_hours'),
    on_hours: t('event.on_hours'),
    provider_drop_off: t('event.provider_drop_off'),
    rebalance_pick_up: t('event.rebalance_pick_up'),
    reservation_cancel: t('event.reservation_cancel'),
    reservation_start: t('event.reservation_start'),
    system_resume: t('event.system_resume'),
    system_suspend: t('event.system_suspend'),
    trip_cancel: t('event.trip_cancel'),
    trip_end: t('event.trip_end'),
    trip_enter_jurisdiction: t('event.trip_enter_jurisdiction'),
    trip_leave_jurisdiction: t('event.trip_leave_jurisdiction'),
    trip_start: t('event.trip_start'),
    unspecified: t('event.unspecified'),
    // Custom for devices garbage collected
    inactive: t('event.inactive'),
  },
  FeeCalculationMethod: {
    [Policy_FeeCalculationMethodEnum.highest]: t('fee_calculation_method-highest'),
    [Policy_FeeCalculationMethodEnum.lowest]: t('fee_calculation_method-lowest'),
    [Policy_FeeCalculationMethodEnum.sum]: t('fee_calculation_method-sum'),
    [Policy_FeeCalculationMethodEnum.mean]: t('fee_calculation_method-mean'),
    [Policy_FeeCalculationMethodEnum.origin]: t('fee_calculation_method-origin'),
    [Policy_FeeCalculationMethodEnum.destination]: t('fee_calculation_method-destination'),
  },
  HopOnVehicleCategory: {
    [HopOnVehicle_CategoryEnum.BIKE]: t('hopon.bike'),
    [HopOnVehicle_CategoryEnum.EBIKE]: t('hopon.ebike'),
    [HopOnVehicle_CategoryEnum.ECAR]: t('hopon.ecar'),
    [HopOnVehicle_CategoryEnum.EKICKSCOOTER]: t('hopon.ekickscooter'),
    [HopOnVehicle_CategoryEnum.EMOPED]: t('hopon.emoped'),
  },
  HopOnVehicleStatus: {
    [HopOnVehicle_StatusEnum.AVAILABLE]: t('hopon.available'),
    [HopOnVehicle_StatusEnum.DAMAGED]: t('hopon.damaged'),
    [HopOnVehicle_StatusEnum.LOST]: t('hopon.lost'),
    [HopOnVehicle_StatusEnum.LOW_BATTERY]: t('hopon.low_battery'),
    [HopOnVehicle_StatusEnum.MAINTAINING]: t('hopon.maintaining'),
    [HopOnVehicle_StatusEnum.MAINTENANCE_PENDING]: t('hopon.maintenance_pending'),
    [HopOnVehicle_StatusEnum.REPORTED]: t('hopon.reported'),
    [HopOnVehicle_StatusEnum.RESERVED]: t('hopon.reserved'),
    [HopOnVehicle_StatusEnum.RUNNING]: t('hopon.running'),
  },
  LocationType: {
    bike_lane: t('locationType.bike_lane'),
    crosswalk: t('locationType.crosswalk'),
    side: t('locationType.side'),
    street: t('locationType.street'),
    garage: t('locationType.garage'),
    invalid: t('locationType.invalid'),
  },
  ReportType: {
    area_flow: t('report.area_flow'),
    historical_map: t('report.historical_map'),
  },
  PenaltyFrequency: {
    [PolicyInput_PenaltyFrequencyEnum.hourly]: t('policy.hourly_penalty'),
    [PolicyInput_PenaltyFrequencyEnum.daily]: t('policy.daily_penalty'),
  },
  Time: {
    hour: t('hour'),
    day: t('day'),
    week: t('week'),
    month: t('month'),
    quarter: t('quarter'),
  },
  Kpi: {
    peak: t('kpi.peak'),
  },
  PolicyKind: {
    absolute_fleet_size: t('policy.kind.absolute_fleet_size'),
    permit: t('policy.kind.permit'),
    geofence: t('policy.kind.geofence'),
    speed: t('policy.kind.speed'),
    parking: t('policy.kind.parking'),
    abandoned: t('policy.kind.abandoned'),
    trip_taxation: t('policy.kind.trip_taxation'),
    sidewalk: t('policy.kind.sidewalk'),
    balancing: t('policy.kind.balancing'),
  },
  PolicyStatus: {
    draft: t('policy.status.draft'),
    upcoming: t('policy.status.upcoming'),
    ongoing: t('policy.status.ongoing'),
    finished: t('policy.status.finished'),
  },
  VehicleStates: {
    removed: t('vehiclestates.removed'),
    available: t('vehiclestates.available'),
    non_operational: t('vehiclestates.non_operational'),
    reserved: t('vehiclestates.reserved'),
    on_trip: t('vehiclestates.on_trip'),
    elsewhere: t('vehiclestates.elsewhere'),
    unknown: t('vehiclestates.unknown'),
  },
  OffenseReason: {
    under_minimum: t('policy.dashboard.offense.reason.minimum'),
    over_maximum: t('policy.dashboard.offense.reason.maximum'),
  },
  TripPurposeCategory: {
    delivery: t('delivery_bot.trip_purpose.delivery'),
    return: t('delivery_bot.trip_purpose.return'),
    roaming: t('delivery_bot.trip_purpose.roaming'),
    pick_up: t('delivery_bot.trip_purpose.pick_up'),
  },
}
export interface ICurrencySymbols {
  EUR: string
  GBP: string
  SAR: string
  USD: string
}
export const currencySymbols: ICurrencySymbols = {
  EUR: '€',
  GBP: '£',
  SAR: 'ر.س',
  USD: '$',
}
export const PREFIXED_CURRENCIES = ['USD', 'SAR']
export const PREFIXED_CURRENCY_SYMBOLS = ['$', 'ر.س']

export const withFormattedCount = (count: number, locale?: string) => ({
  context: 'formatted',
  count,
  formattedCount: formatNumber(count, locale),
})
