import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

function AppLoader() {
  return (
    <div style={styles}>
      <CircularProgress size={60} />
    </div>
  )
}

const styles: React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
}

export default AppLoader
