import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import green from '@material-ui/core/colors/green'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import upperFirst from 'lodash/upperFirst'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import blueSystemsLogo from 'public/img/Logo_BS_Corporate_Quadri.svg'
import RulesValidator, { GridDirection } from 'src/components/Forms/RulesValidator'
import { usePasswordValidation } from 'src/layout/hooks'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    height: 35,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    width: '100%',
  },
  formMargin: {
    marginBottom: 20,
  },
  formRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    width: '100%',
    borderRadius: 4,
  },
  formLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    top: -8,
  },
  formInput: {
    position: 'relative',
    fontSize: 16,
    padding: '10px 12px',
    minHeight: 25,
  },
  input: { minHeight: 40 },
  logo: {
    width: 300,
    'max-width': '100%',
  },
  passwordValidationContainer: {
    marginTop: 15,
    padding: 0,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  passwordValidationElements: {
    marginLeft: 10,
  },
  registerContent: {
    height: 500,
    width: 470,
    'max-width': '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  rulesValidator: {
    padding: '15px 0',
  },
}))

interface IChangePasswordForm {
  handleButtonClick(password: string): Promise<void>
  isLoading?: boolean
  username?: string
}

export default function ChangePasswordForm(props: IChangePasswordForm) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showPassword, setPasswordDisplay] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const { checkRules, disableSubmit } = usePasswordValidation(password, passwordConfirm)

  const handleClickShowPassword = () => {
    setPasswordDisplay((passwordDisplayed) => !passwordDisplayed)
  }
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }
  const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value)
  }

  async function handleValidate() {
    if (disableSubmit) {
      return
    }
    await props.handleButtonClick(password)
  }

  return (
    <DialogContent className={classes.registerContent}>
      <img src={blueSystemsLogo} className={classes.logo} />
      <div>
        <FormControl fullWidth={true} className={classes.formMargin}>
          <InputLabel shrink={true} htmlFor="adornment-username" className={classes.formLabel}>
            {upperFirst(t('username'))}
          </InputLabel>
          <InputBase
            value={props.username}
            disabled={true}
            id="adornment-username"
            className={classes.input}
            autoFocus={true}
            type={'text'}
            fullWidth={true}
            classes={{
              root: classes.formRoot,
              input: classes.formInput,
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} className={classes.formMargin}>
          <InputLabel htmlFor="adornment-password" className={classes.formLabel} shrink={true}>
            {upperFirst(t('password'))}
          </InputLabel>
          <InputBase
            id="adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange}
            className={classes.input}
            fullWidth={true}
            classes={{
              root: classes.formRoot,
              input: classes.formInput,
            }}
            required={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={upperFirst(t('toggle password visibility'))} onClick={handleClickShowPassword}>
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="adornment-passwordConfirm" className={classes.formLabel} shrink={true}>
            {upperFirst(t('confirm password'))}
          </InputLabel>
          <InputBase
            id="adornment-passwordConfirm"
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordConfirmChange}
            className={classes.input}
            fullWidth={true}
            classes={{
              root: classes.formRoot,
              input: classes.formInput,
            }}
            required={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={upperFirst(t('toggle password visibility'))} onClick={handleClickShowPassword}>
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div className={classes.rulesValidator}>
            <RulesValidator checkRules={checkRules} rulesDirection={GridDirection.Row} />
          </div>
        </FormControl>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={disableSubmit}
          onClick={handleValidate}
        >
          {t('button.validate')}
        </Button>
        {props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </DialogContent>
  )
}
