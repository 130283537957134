/// <reference path="./custom.d.ts" />
/* eslint-disable */
/**
 * Plato frontend API
 * Used by plato front
 *
 * OpenAPI spec version: v0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import url from "url";
import portableFetch from "portable-fetch";

import { handleGeneratedApiResponse } from "src/api/helpers";

import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost/prv".replace(/\/+$/, "");

/**
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AgencyApiAuthentication
 */
export interface AgencyApiAuthentication {
    /**
     * 
     * @type {string}
     * @memberof AgencyApiAuthentication
     */
    type: AgencyApiAuthentication_TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgencyApiAuthentication
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyApiAuthentication
     */
    client_secret?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyApiAuthentication
     */
    token?: string;
    /**
     * URL to get the token - Only used by the native poller
     * @type {string}
     * @memberof AgencyApiAuthentication
     */
    token_url?: string;
    /**
     * Expiration delay in seconds
     * @type {number}
     * @memberof AgencyApiAuthentication
     */
    expires_in?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AgencyApiAuthentication_TypeEnum {
    'header' = 'header',
    'none' = 'none',
    'oauth2' = 'oauth2',
    'spin' = 'spin',
    'token' = 'token'
}

/**
 * 
 * @export
 * @interface AgencyApiConfiguration
 */
export interface AgencyApiConfiguration {
    /**
     * 
     * @type {string}
     * @memberof AgencyApiConfiguration
     */
    api_version: AgencyApiConfiguration_ApiVersionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AgencyApiConfiguration_ApiVersionEnum {
    'none' = 'none',
    'v0_3' = 'v0_3',
    'v0_4' = 'v0_4',
    'v1_0' = 'v1_0',
    'v1_1' = 'v1_1'
}

/**
 * 
 * @export
 * @interface AgencySettings
 */
export interface AgencySettings {
    /**
     * 
     * @type {number}
     * @memberof AgencySettings
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AgencySettings
     */
    trip_threshold: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencySettings
     */
    enabled_extensions: Array<AgencySettings_EnabledExtensionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencySettings
     */
    enabled_features: Array<AgencySettings_EnabledFeaturesEnum>;
    /**
     * 
     * @type {StreetSegmentsFile}
     * @memberof AgencySettings
     */
    routes?: StreetSegmentsFile;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencySettings
     */
    vehicle_categories: Array<AgencySettings_VehicleCategoriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencySettings
     */
    vehicle_propulsions: Array<AgencySettings_VehiclePropulsionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencySettings
     */
    location_types: Array<AgencySettings_LocationTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    distance_unit: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    language_code: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    penalty_currency_code: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    permit_currency_code: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    speed_unit: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    speed_unit_display: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgencySettings
     */
    use_telemetry_data: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgencySettings
     */
    maximum_password_age: number;
    /**
     * 
     * @type {number}
     * @memberof AgencySettings
     */
    kpi_filter_number_of_units: number;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    kpi_filter_unit: string;
    /**
     * 
     * @type {string}
     * @memberof AgencySettings
     */
    kpi_filter_granularity: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AgencySettings_EnabledExtensionsEnum {
    'bestmile' = 'bestmile',
    'gbfs' = 'gbfs',
    'hopon' = 'hopon',
    'mds' = 'mds',
    'upstream' = 'upstream'
}
/**
    * @export
    * @enum {string}
    */
export enum AgencySettings_EnabledFeaturesEnum {
    'heatmap_origin_destination' = 'heatmap_origin_destination',
    'heatmap_trips' = 'heatmap_trips',
    'environment_kpi' = 'environment_kpi',
    'user_kpi' = 'user_kpi',
    'sensors_kpi' = 'sensors_kpi',
    'mds_policy.absolute_fleet_size' = 'mds_policy.absolute_fleet_size',
    'mds_policy.geofence' = 'mds_policy.geofence',
    'mds_policy.parking' = 'mds_policy.parking',
    'mds_policy.permit' = 'mds_policy.permit',
    'mds_policy.speed' = 'mds_policy.speed',
    'mds_policy.trip_taxation' = 'mds_policy.trip_taxation',
    'reports.area_flow' = 'reports.area_flow',
    'reports.historical_map' = 'reports.historical_map',
    'mds_policy.abandoned' = 'mds_policy.abandoned',
    'mds_policy.balancing' = 'mds_policy.balancing',
    'mds_policy.sidewalk' = 'mds_policy.sidewalk',
    'mds_policy.time' = 'mds_policy.time',
    'mds_policy.user' = 'mds_policy.user'
}
/**
    * @export
    * @enum {string}
    */
export enum AgencySettings_VehicleCategoriesEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum AgencySettings_VehiclePropulsionsEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}
/**
    * @export
    * @enum {string}
    */
export enum AgencySettings_LocationTypesEnum {
    'bike_lane' = 'bike_lane',
    'crosswalk' = 'crosswalk',
    'side' = 'side',
    'street' = 'street',
    'garage' = 'garage',
    'invalid' = 'invalid'
}

/**
 * 
 * @export
 * @interface AgencySettingsResponse
 */
export interface AgencySettingsResponse {
    /**
     * 
     * @type {AgencySettings}
     * @memberof AgencySettingsResponse
     */
    settings: AgencySettings;
    /**
     * 
     * @type {Date}
     * @memberof AgencySettingsResponse
     */
    last_modified: Date;
}
/**
 * 
 * @export
 * @interface AreaLabelResponse
 */
export interface AreaLabelResponse {
    /**
     * 
     * @type {string}
     * @memberof AreaLabelResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AreaLabelResponse
     */
    label: string;
}
/**
 * 
 * @export
 * @interface AreaRequest
 */
export interface AreaRequest {
    /**
     * Name of the Area
     * @type {string}
     * @memberof AreaRequest
     */
    label: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AreaRequest
     */
    polygons: Array<string>;
    /**
     * Color of the Area
     * @type {string}
     * @memberof AreaRequest
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface AreaResponse
 */
export interface AreaResponse {
    /**
     * 
     * @type {string}
     * @memberof AreaResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AreaResponse
     */
    label: string;
    /**
     * 
     * @type {Array<PolygonResponse>}
     * @memberof AreaResponse
     */
    polygons: Array<PolygonResponse>;
    /**
     * 
     * @type {string}
     * @memberof AreaResponse
     */
    color: string;
}
/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    history_date?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    history_user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    history_type?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    history_kind?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    display_name?: string;
}
/**
 * 
 * @export
 * @interface AuditLogResponse
 */
export interface AuditLogResponse {
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof AuditLogResponse
     */
    results: Array<AuditLog>;
}
/**
 * 
 * @export
 * @interface BestMileStops
 */
export interface BestMileStops {
    /**
     * 
     * @type {string}
     * @memberof BestMileStops
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BestMileStops
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BestMileStops
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileStops
     */
    latitude: number;
}
/**
 * 
 * @export
 * @interface BestMileVehicle
 */
export interface BestMileVehicle {
    /**
     * 
     * @type {string}
     * @memberof BestMileVehicle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BestMileVehicle
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    bearing?: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    occupancy?: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    passengerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BestMileVehicle
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof BestMileVehicle
     */
    vehicleType?: string;
}
/**
 * 
 * @export
 * @interface BestmileResponse
 */
export interface BestmileResponse {
    /**
     * 
     * @type {Array<BestMileVehicle>}
     * @memberof BestmileResponse
     */
    results: Array<BestMileVehicle>;
}
/**
 * 
 * @export
 * @interface BestmileStopsResponse
 */
export interface BestmileStopsResponse {
    /**
     * 
     * @type {Array<BestMileStops>}
     * @memberof BestmileStopsResponse
     */
    results: Array<BestMileStops>;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    current: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    password: string;
}
/**
 * 
 * @export
 * @interface CreateReport
 */
export interface CreateReport {
    /**
     * 
     * @type {string}
     * @memberof CreateReport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReport
     */
    kind: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateReport
     */
    start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateReport
     */
    end_at: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateReport
     */
    area_id: string;
}
/**
 * 
 * @export
 * @interface DeleteListPolygonRequest
 */
export interface DeleteListPolygonRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteListPolygonRequest
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface DomainPermission
 */
export interface DomainPermission {
    /**
     * 
     * @type {string}
     * @memberof DomainPermission
     */
    domain: DomainPermission_DomainEnum;
    /**
     * 
     * @type {string}
     * @memberof DomainPermission
     */
    verb: DomainPermission_VerbEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DomainPermission_DomainEnum {
    'administration' = 'administration',
    'area' = 'area',
    'audit' = 'audit',
    'device' = 'device',
    'gbfs' = 'gbfs',
    'instance' = 'instance',
    'kpi' = 'kpi',
    'map' = 'map',
    'policy' = 'policy',
    'provider' = 'provider',
    'report' = 'report',
    'trip' = 'trip',
    'all' = 'all'
}
/**
    * @export
    * @enum {string}
    */
export enum DomainPermission_VerbEnum {
    'read' = 'read',
    'summarize' = 'summarize',
    'analyze' = 'analyze',
    'write' = 'write',
    'manage' = 'manage'
}

/**
 * 
 * @export
 * @interface EmptyResponse
 */
export interface EmptyResponse {
}
/**
 * 
 * @export
 * @interface EventRecord
 */
export interface EventRecord {
    /**
     * 
     * @type {Date}
     * @memberof EventRecord
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    provider_name: string;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    device_id: string;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    device_vin: string;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    vehicle_state: EventRecord_VehicleStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventRecord
     */
    event_types: Array<EventRecord_EventTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof EventRecord
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EventRecord
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    source: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EventRecord_VehicleStateEnum {
    'available' = 'available',
    'elsewhere' = 'elsewhere',
    'non_operational' = 'non_operational',
    'on_trip' = 'on_trip',
    'removed' = 'removed',
    'reserved' = 'reserved',
    'unknown' = 'unknown'
}
/**
    * @export
    * @enum {string}
    */
export enum EventRecord_EventTypesEnum {
    'agency_drop_off' = 'agency_drop_off',
    'agency_pick_up' = 'agency_pick_up',
    'battery_charged' = 'battery_charged',
    'battery_low' = 'battery_low',
    'comms_lost' = 'comms_lost',
    'comms_restored' = 'comms_restored',
    'compliance_pick_up' = 'compliance_pick_up',
    'decommissioned' = 'decommissioned',
    'located' = 'located',
    'maintenance' = 'maintenance',
    'maintenance_pick_up' = 'maintenance_pick_up',
    'missing' = 'missing',
    'off_hours' = 'off_hours',
    'on_hours' = 'on_hours',
    'provider_drop_off' = 'provider_drop_off',
    'rebalance_pick_up' = 'rebalance_pick_up',
    'reservation_cancel' = 'reservation_cancel',
    'reservation_start' = 'reservation_start',
    'system_resume' = 'system_resume',
    'system_suspend' = 'system_suspend',
    'trip_cancel' = 'trip_cancel',
    'trip_end' = 'trip_end',
    'trip_enter_jurisdiction' = 'trip_enter_jurisdiction',
    'trip_leave_jurisdiction' = 'trip_leave_jurisdiction',
    'trip_start' = 'trip_start',
    'unspecified' = 'unspecified'
}

/**
 * 
 * @export
 * @interface ExistPolicyResponse
 */
export interface ExistPolicyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExistPolicyResponse
     */
    exist: boolean;
}
/**
 * 
 * @export
 * @interface FacetedListOffenseResponse
 */
export interface FacetedListOffenseResponse {
    /**
     * 
     * @type {Array<Offense>}
     * @memberof FacetedListOffenseResponse
     */
    results: Array<Offense>;
    /**
     * 
     * @type {OffenseAggregation}
     * @memberof FacetedListOffenseResponse
     */
    aggregations: OffenseAggregation;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    type: Feature_TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    id?: string;
    /**
     * 
     * @type {any}
     * @memberof Feature
     */
    properties: any;
    /**
     * 
     * @type {Geometry}
     * @memberof Feature
     */
    geometry: Geometry;
}

/**
    * @export
    * @enum {string}
    */
export enum Feature_TypeEnum {
    'Feature' = 'Feature'
}

/**
 * 
 * @export
 * @interface FeatureCollection
 */
export interface FeatureCollection {
    /**
     * 
     * @type {string}
     * @memberof FeatureCollection
     */
    type: FeatureCollection_TypeEnum;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollection
     */
    features: Array<Feature>;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureCollection_TypeEnum {
    'FeatureCollection' = 'FeatureCollection'
}

/**
 * 
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    trip_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    vehicle_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    vehicle_type: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    policy_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    policy_name: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    provider_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    provider_name: string;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof Fee
     */
    trip_start_time: Date;
    /**
     * 
     * @type {Date}
     * @memberof Fee
     */
    trip_end_time: Date;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    trip_duration: number;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    trip_distance: number;
}
/**
 * 
 * @export
 * @interface FeesAggregations
 */
export interface FeesAggregations {
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    amount_min?: number;
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    amount_max?: number;
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    trip_datetime_min?: number;
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    trip_datetime_max?: number;
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    trip_distance_min?: number;
    /**
     * 
     * @type {number}
     * @memberof FeesAggregations
     */
    trip_distance_max?: number;
}
/**
 * 
 * @export
 * @interface FeesResponse
 */
export interface FeesResponse {
    /**
     * 
     * @type {Array<Fee>}
     * @memberof FeesResponse
     */
    fees: Array<Fee>;
    /**
     * 
     * @type {number}
     * @memberof FeesResponse
     */
    fees_count: number;
    /**
     * 
     * @type {FeesAggregations}
     * @memberof FeesResponse
     */
    aggregations?: FeesAggregations;
}
/**
 * 
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * 
     * @type {string}
     * @memberof Geometry
     */
    type: string;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof Geometry
     */
    coordinates: Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @interface GraphBucket
 */
export interface GraphBucket {
    /**
     * 
     * @type {string}
     * @memberof GraphBucket
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof GraphBucket
     */
    doc_count: number;
}
/**
 * 
 * @export
 * @interface GraphBucketList
 */
export interface GraphBucketList {
    /**
     * 
     * @type {Array<GraphBucket>}
     * @memberof GraphBucketList
     */
    buckets: Array<GraphBucket>;
}
/**
 * 
 * @export
 * @interface GraphBucketResponse
 */
export interface GraphBucketResponse {
    /**
     * 
     * @type {number}
     * @memberof GraphBucketResponse
     */
    key: number;
    /**
     * 
     * @type {string}
     * @memberof GraphBucketResponse
     */
    key_as_string: string;
    /**
     * 
     * @type {number}
     * @memberof GraphBucketResponse
     */
    doc_count: number;
    /**
     * 
     * @type {GraphBucketList}
     * @memberof GraphBucketResponse
     */
    result?: GraphBucketList;
}
/**
 * 
 * @export
 * @interface GraphBucketWithTotalResponse
 */
export interface GraphBucketWithTotalResponse {
    /**
     * 
     * @type {number}
     * @memberof GraphBucketWithTotalResponse
     */
    total: number;
    /**
     * 
     * @type {Array<GraphBucketResponse>}
     * @memberof GraphBucketWithTotalResponse
     */
    data: Array<GraphBucketResponse>;
}
/**
 * 
 * @export
 * @interface GraphCompareResponse
 */
export interface GraphCompareResponse {
    /**
     * 
     * @type {number}
     * @memberof GraphCompareResponse
     */
    key: number;
    /**
     * 
     * @type {string}
     * @memberof GraphCompareResponse
     */
    key_as_string: string;
    /**
     * 
     * @type {GraphBucketList}
     * @memberof GraphCompareResponse
     */
    compareBy: GraphBucketList;
    /**
     * 
     * @type {number}
     * @memberof GraphCompareResponse
     */
    average?: number;
    /**
     * 
     * @type {number}
     * @memberof GraphCompareResponse
     */
    doc_count?: number;
}
/**
 * 
 * @export
 * @interface GraphCompareWithTotalResponse
 */
export interface GraphCompareWithTotalResponse {
    /**
     * 
     * @type {number}
     * @memberof GraphCompareWithTotalResponse
     */
    total: number;
    /**
     * 
     * @type {Array<GraphCompareResponse>}
     * @memberof GraphCompareWithTotalResponse
     */
    data: Array<GraphCompareResponse>;
}
/**
 * 
 * @export
 * @interface GraphData
 */
export interface GraphData {
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphData
     */
    labels: Array<string>;
    /**
     * 
     * @type {Array<GraphDataset>}
     * @memberof GraphData
     */
    datasets: Array<GraphDataset>;
}
/**
 * 
 * @export
 * @interface GraphDataset
 */
export interface GraphDataset {
    /**
     * 
     * @type {string}
     * @memberof GraphDataset
     */
    label: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphDataset
     */
    data: Array<number>;
}
/**
 * 
 * @export
 * @interface GraphFilters
 */
export interface GraphFilters {
    /**
     * 
     * @type {Date}
     * @memberof GraphFilters
     */
    time_range_from: Date;
    /**
     * 
     * @type {Date}
     * @memberof GraphFilters
     */
    time_range_to: Date;
    /**
     * 
     * @type {string}
     * @memberof GraphFilters
     */
    granularity: GraphFilters_GranularityEnum;
    /**
     * 
     * @type {string}
     * @memberof GraphFilters
     */
    layer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    device_type?: Array<GraphFilters_DeviceTypeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    propulsion_type?: Array<GraphFilters_PropulsionTypeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    location_type?: Array<GraphFilters_LocationTypeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    vehicle_state?: Array<GraphFilters_VehicleStateEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    provider_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GraphFilters
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof GraphFilters
     */
    zone?: GraphFilters_ZoneEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GraphFilters
     */
    trip_purpose?: Array<GraphFilters_TripPurposeEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_GranularityEnum {
    'quarter' = 'quarter',
    'month' = 'month',
    'week' = 'week',
    'day' = 'day',
    'hour' = 'hour'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_DeviceTypeEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_PropulsionTypeEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_LocationTypeEnum {
    'bike_lane' = 'bike_lane',
    'crosswalk' = 'crosswalk',
    'side' = 'side',
    'street' = 'street',
    'garage' = 'garage',
    'invalid' = 'invalid'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_VehicleStateEnum {
    'available' = 'available',
    'elsewhere' = 'elsewhere',
    'non_operational' = 'non_operational',
    'on_trip' = 'on_trip',
    'removed' = 'removed',
    'reserved' = 'reserved',
    'unknown' = 'unknown'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_ZoneEnum {
    'origin' = 'origin',
    'destination' = 'destination'
}
/**
    * @export
    * @enum {string}
    */
export enum GraphFilters_TripPurposeEnum {
    'delivery' = 'delivery',
    'pick_up' = 'pick_up',
    'return' = 'return',
    'roaming' = 'roaming'
}

/**
 * 
 * @export
 * @interface GraphFlowsResponse
 */
export interface GraphFlowsResponse {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof GraphFlowsResponse
     */
    flows: { [key: string]: { [key: string]: number; }; };
}
/**
 * 
 * @export
 * @interface GraphReport
 */
export interface GraphReport {
    /**
     * 
     * @type {string}
     * @memberof GraphReport
     */
    type: GraphReport_TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GraphReport
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof GraphReport
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GraphReport
     */
    description?: string;
    /**
     * 
     * @type {GraphFilters}
     * @memberof GraphReport
     */
    filters: GraphFilters;
    /**
     * 
     * @type {any}
     * @memberof GraphReport
     */
    data: any;
}

/**
    * @export
    * @enum {string}
    */
export enum GraphReport_TypeEnum {
    'devices_deployed_per_provider' = 'devices_deployed_per_provider',
    'devices_per_status' = 'devices_per_status',
    'fleet_availability' = 'fleet_availability',
    'average_deployment_duration_per_zone' = 'average_deployment_duration_per_zone',
    'energy_consumption_per_provider' = 'energy_consumption_per_provider',
    'gaz_emission_per_provider' = 'gaz_emission_per_provider',
    'offenses_per_provider_per_date' = 'offenses_per_provider_per_date',
    'offense_price_per_provider_per_date' = 'offense_price_per_provider_per_date',
    'offenses_percentage_per_kind' = 'offenses_percentage_per_kind',
    'offenses_per_kind' = 'offenses_per_kind',
    'number_of_trips_per_provider' = 'number_of_trips_per_provider',
    'number_of_trips' = 'number_of_trips',
    'number_of_trips_per_zone' = 'number_of_trips_per_zone',
    'percentage_of_trips_per_provider' = 'percentage_of_trips_per_provider',
    'trips_flows' = 'trips_flows',
    'trips_total_distance' = 'trips_total_distance',
    'trips_total_distance_per_provider' = 'trips_total_distance_per_provider',
    'trips_by_distance_traveled' = 'trips_by_distance_traveled',
    'trips_by_distance_traveled_comparing' = 'trips_by_distance_traveled_comparing',
    'trips_by_duration' = 'trips_by_duration',
    'ratio_of_trip_per_device' = 'ratio_of_trip_per_device',
    'number_of_trip_per_device' = 'number_of_trip_per_device',
    'number_of_trips_per_trip_purpose' = 'number_of_trips_per_trip_purpose',
    'total_distance_per_trip_purpose' = 'total_distance_per_trip_purpose',
    'number_of_unique_users' = 'number_of_unique_users',
    'number_of_unique_users_per_provider' = 'number_of_unique_users_per_provider',
    'number_of_new_users' = 'number_of_new_users',
    'number_of_new_users_per_provider' = 'number_of_new_users_per_provider',
    'number_of_users_per_trip_performed' = 'number_of_users_per_trip_performed'
}

/**
 * 
 * @export
 * @interface GraphStackResponse
 */
export interface GraphStackResponse {
    /**
     * 
     * @type {string}
     * @memberof GraphStackResponse
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof GraphStackResponse
     */
    key_as_string: string;
    /**
     * 
     * @type {GraphBucketList}
     * @memberof GraphStackResponse
     */
    stackedBy: GraphBucketList;
    /**
     * 
     * @type {number}
     * @memberof GraphStackResponse
     */
    doc_count?: number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    jurisdiction: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    jurisdiction_key: string;
}
/**
 * 
 * @export
 * @interface HomepageVehicle
 */
export interface HomepageVehicle {
    /**
     * Vehicle type
     * @type {string}
     * @memberof HomepageVehicle
     */
    category: HomepageVehicle_CategoryEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof HomepageVehicle
     */
    last_known_position?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum HomepageVehicle_CategoryEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}

/**
 * 
 * @export
 * @interface HomepageVehicleAggregation
 */
export interface HomepageVehicleAggregation {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof HomepageVehicleAggregation
     */
    categories: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface HomepageVehicleResponse
 */
export interface HomepageVehicleResponse {
    /**
     * 
     * @type {Array<HomepageVehicle>}
     * @memberof HomepageVehicleResponse
     */
    results: Array<HomepageVehicle>;
    /**
     * 
     * @type {HomepageVehicleAggregation}
     * @memberof HomepageVehicleResponse
     */
    aggregation: HomepageVehicleAggregation;
}
/**
 * 
 * @export
 * @interface HopOnResponse
 */
export interface HopOnResponse {
    /**
     * 
     * @type {Array<HopOnVehicle>}
     * @memberof HopOnResponse
     */
    results: Array<HopOnVehicle>;
}
/**
 * 
 * @export
 * @interface HopOnVehicle
 */
export interface HopOnVehicle {
    /**
     * 
     * @type {string}
     * @memberof HopOnVehicle
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof HopOnVehicle
     */
    category: HopOnVehicle_CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof HopOnVehicle
     */
    status: HopOnVehicle_StatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof HopOnVehicle
     */
    updated_at: Date;
    /**
     * 
     * @type {number}
     * @memberof HopOnVehicle
     */
    battery_level: number;
    /**
     * 
     * @type {number}
     * @memberof HopOnVehicle
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof HopOnVehicle
     */
    lng: number;
}

/**
    * @export
    * @enum {string}
    */
export enum HopOnVehicle_CategoryEnum {
    'BIKE' = 'BIKE',
    'EBIKE' = 'EBIKE',
    'EKICKSCOOTER' = 'EKICKSCOOTER',
    'EMOPED' = 'EMOPED',
    'ECAR' = 'ECAR'
}
/**
    * @export
    * @enum {string}
    */
export enum HopOnVehicle_StatusEnum {
    'AVAILABLE' = 'AVAILABLE',
    'RUNNING' = 'RUNNING',
    'RESERVED' = 'RESERVED',
    'REPORTED' = 'REPORTED',
    'MAINTAINING' = 'MAINTAINING',
    'MAINTENANCE_PENDING' = 'MAINTENANCE_PENDING',
    'DAMAGED' = 'DAMAGED',
    'LOST' = 'LOST',
    'LOW_BATTERY' = 'LOW_BATTERY'
}

/**
 * 
 * @export
 * @interface Jurisdiction
 */
export interface Jurisdiction {
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    agency_key: string;
    /**
     * 
     * @type {MapConfig}
     * @memberof Jurisdiction
     */
    map_config: MapConfig;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    default_layer: string;
}
/**
 * 
 * @export
 * @interface KPIGeometry
 */
export interface KPIGeometry {
    /**
     * Identifier for the layer
     * @type {string}
     * @memberof KPIGeometry
     */
    id: string;
    /**
     * Name of the layer
     * @type {string}
     * @memberof KPIGeometry
     */
    name: string;
    /**
     * Whether the geometry is a permit layer of the jurisdiction
     * @type {boolean}
     * @memberof KPIGeometry
     */
    permit_layer: boolean;
    /**
     * 
     * @type {KPIPolygon}
     * @memberof KPIGeometry
     */
    geom: KPIPolygon;
}
/**
 * FeatureCollection representation of the layer
 * @export
 * @interface KPIPolygon
 */
export interface KPIPolygon {
}
/**
 * 
 * @export
 * @interface LightArea
 */
export interface LightArea {
    /**
     * 
     * @type {string}
     * @memberof LightArea
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LightArea
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof LightArea
     */
    color: string;
    /**
     * 
     * @type {number}
     * @memberof LightArea
     */
    polygons_count: number;
}
/**
 * 
 * @export
 * @interface LightPolicy
 */
export interface LightPolicy {
    /**
     * 
     * @type {string}
     * @memberof LightPolicy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LightPolicy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LightPolicy
     */
    kind: LightPolicy_KindEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof LightPolicy
     */
    providers?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof LightPolicy
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof LightPolicy
     */
    end_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LightPolicy
     */
    published_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof LightPolicy
     */
    total_price?: number;
    /**
     * 
     * @type {string}
     * @memberof LightPolicy
     */
    status: LightPolicy_StatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LightPolicy_KindEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum LightPolicy_StatusEnum {
    'draft' = 'draft',
    'upcoming' = 'upcoming',
    'ongoing' = 'ongoing',
    'finished' = 'finished'
}

/**
 * 
 * @export
 * @interface LightPolicyResponse
 */
export interface LightPolicyResponse {
    /**
     * 
     * @type {Array<LightPolicy>}
     * @memberof LightPolicyResponse
     */
    results: Array<LightPolicy>;
    /**
     * 
     * @type {PolicyAggregation}
     * @memberof LightPolicyResponse
     */
    aggregation: PolicyAggregation;
}
/**
 * 
 * @export
 * @interface LightPolygon
 */
export interface LightPolygon {
    /**
     * 
     * @type {string}
     * @memberof LightPolygon
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LightPolygon
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof LightPolygon
     */
    areas_count: number;
}
/**
 * 
 * @export
 * @interface ListReport
 */
export interface ListReport {
    /**
     * 
     * @type {number}
     * @memberof ListReport
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ListReport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ListReport
     */
    kind: string;
    /**
     * 
     * @type {Date}
     * @memberof ListReport
     */
    start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof ListReport
     */
    end_at: Date;
    /**
     * 
     * @type {string}
     * @memberof ListReport
     */
    area_id: string;
    /**
     * 
     * @type {number}
     * @memberof ListReport
     */
    progress: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListReport
     */
    result_available?: boolean;
    /**
     * 
     * @type {UserName}
     * @memberof ListReport
     */
    created_by: UserName;
}
/**
 * 
 * @export
 * @interface MapConfig
 */
export interface MapConfig {
    /**
     * 
     * @type {number}
     * @memberof MapConfig
     */
    zoom: number;
    /**
     * 
     * @type {MapCoordinate}
     * @memberof MapConfig
     */
    center: MapCoordinate;
}
/**
 * 
 * @export
 * @interface MapCoordinate
 */
export interface MapCoordinate {
    /**
     * 
     * @type {number}
     * @memberof MapCoordinate
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof MapCoordinate
     */
    latitude: number;
}
/**
 * 
 * @export
 * @interface MdsApiCredentials
 */
export interface MdsApiCredentials {
    /**
     * 
     * @type {number}
     * @memberof MdsApiCredentials
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MdsApiCredentials
     */
    base_api_url?: string;
    /**
     * 
     * @type {ProviderApiAuthentication}
     * @memberof MdsApiCredentials
     */
    api_authentication?: ProviderApiAuthentication;
    /**
     * 
     * @type {ProviderApiConfiguration}
     * @memberof MdsApiCredentials
     */
    api_configuration?: ProviderApiConfiguration;
    /**
     * 
     * @type {AgencyApiAuthentication}
     * @memberof MdsApiCredentials
     */
    agency_api_authentication?: AgencyApiAuthentication;
    /**
     * 
     * @type {AgencyApiConfiguration}
     * @memberof MdsApiCredentials
     */
    agency_api_configuration?: AgencyApiConfiguration;
}
/**
 * 
 * @export
 * @interface Offense
 */
export interface Offense {
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    id: string;
    /**
     * The amount of the offense.
     * @type {number}
     * @memberof Offense
     */
    price?: number;
    /**
     * Official start of the Offense according to the MDS specifications. See functional_specs documents for more details.
     * @type {Date}
     * @memberof Offense
     */
    official_start_date?: Date;
    /**
     * Official end of the Offense according to the MDS Specifications.  See functional_specs documents for more details.
     * @type {Date}
     * @memberof Offense
     */
    official_end_date?: Date;
    /**
     * Vehicle ID
     * @type {string}
     * @memberof Offense
     */
    device_id?: string;
    /**
     * Vehicle Identification Number
     * @type {string}
     * @memberof Offense
     */
    vin?: string;
    /**
     * Vehicle type
     * @type {string}
     * @memberof Offense
     */
    device_category?: Offense_DeviceCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    provider_id: string;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    provider_name: string;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    policy_id: string;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    policy_name: string;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    policy_type: Offense_PolicyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    trip_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Offense
     */
    speed?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Offense
     */
    gps_point: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Offense
     */
    reason?: Offense_ReasonEnum;
    /**
     * 
     * @type {number}
     * @memberof Offense
     */
    fleet_proof_id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum Offense_DeviceCategoryEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum Offense_PolicyTypeEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum Offense_ReasonEnum {
    'under_minimum' = 'under_minimum',
    'over_maximum' = 'over_maximum'
}

/**
 * 
 * @export
 * @interface OffenseAggregation
 */
export interface OffenseAggregation {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OffenseAggregation
     */
    policy_ids: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OffenseAggregation
     */
    policy_types: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OffenseAggregation
     */
    providers: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof OffenseAggregation
     */
    total: number;
}
/**
 * Latest GPS position
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    type: Point_TypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Point
     */
    coordinates: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum Point_TypeEnum {
    'Point' = 'Point'
}

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {Array<AreaLabelResponse>}
     * @memberof Policy
     */
    areas: Array<AreaLabelResponse>;
    /**
     * 
     * @type {PolicyConfig}
     * @memberof Policy
     */
    config: PolicyConfig;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    end_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Policy
     */
    prev_policies?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Policy
     */
    providers?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    published_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    kind: Policy_KindEnum;
    /**
     * 
     * @type {Array<PolicyRule>}
     * @memberof Policy
     */
    rules: Array<PolicyRule>;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    start_date: Date;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    status: Policy_StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    fixed_price?: number;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    flat_penalty?: number;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    penalty_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    penalty_frequency?: Policy_PenaltyFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    confirmation_delay?: number;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    penalty_delay?: number;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    fee_calculation_method?: Policy_FeeCalculationMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum Policy_KindEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum Policy_StatusEnum {
    'draft' = 'draft',
    'upcoming' = 'upcoming',
    'ongoing' = 'ongoing',
    'finished' = 'finished'
}
/**
    * @export
    * @enum {string}
    */
export enum Policy_PenaltyFrequencyEnum {
    'hourly' = 'hourly',
    'daily' = 'daily'
}
/**
    * @export
    * @enum {string}
    */
export enum Policy_FeeCalculationMethodEnum {
    'lowest' = 'lowest',
    'highest' = 'highest',
    'sum' = 'sum',
    'mean' = 'mean',
    'origin' = 'origin',
    'destination' = 'destination'
}

/**
 * 
 * @export
 * @interface PolicyAggregation
 */
export interface PolicyAggregation {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PolicyAggregation
     */
    statuses: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PolicyAggregation
     */
    kinds: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PolicyAggregation
     */
    providers: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof PolicyAggregation
     */
    total: number;
}
/**
 * 
 * @export
 * @interface PolicyConfig
 */
export interface PolicyConfig {
    /**
     * 
     * @type {number}
     * @memberof PolicyConfig
     */
    minimum_fleet_percentage?: number;
}
/**
 * 
 * @export
 * @interface PolicyFacetedGraphsResponse
 */
export interface PolicyFacetedGraphsResponse {
    /**
     * 
     * @type {GraphData}
     * @memberof PolicyFacetedGraphsResponse
     */
    penalty_score_per_provider_per_date: GraphData;
    /**
     * 
     * @type {GraphData}
     * @memberof PolicyFacetedGraphsResponse
     */
    offenses_per_provider_per_date: GraphData;
    /**
     * 
     * @type {PolicyOffenseAggregation}
     * @memberof PolicyFacetedGraphsResponse
     */
    filters_aggregations: PolicyOffenseAggregation;
}
/**
 * 
 * @export
 * @interface PolicyFacetedListOffenseResponse
 */
export interface PolicyFacetedListOffenseResponse {
    /**
     * 
     * @type {Array<Offense>}
     * @memberof PolicyFacetedListOffenseResponse
     */
    results: Array<Offense>;
    /**
     * 
     * @type {PolicyOffenseAggregation}
     * @memberof PolicyFacetedListOffenseResponse
     */
    aggregations: PolicyOffenseAggregation;
}
/**
 * 
 * @export
 * @interface PolicyInput
 */
export interface PolicyInput {
    /**
     * 
     * @type {PolicyConfig}
     * @memberof PolicyInput
     */
    config?: PolicyConfig;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof PolicyInput
     */
    end_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyInput
     */
    prev_policies?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyInput
     */
    providers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyInput
     */
    publish?: boolean;
    /**
     * 
     * @type {Array<RuleInput>}
     * @memberof PolicyInput
     */
    rules: Array<RuleInput>;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    kind: PolicyInput_KindEnum;
    /**
     * 
     * @type {Date}
     * @memberof PolicyInput
     */
    start_date: Date;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    fixed_price?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    flat_penalty?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    penalty_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    penalty_frequency?: PolicyInput_PenaltyFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    confirmation_delay?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    penalty_delay?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    penalty_frequency_str?: PolicyInput_PenaltyFrequencyStrEnum;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    confirmation_delay_seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyInput
     */
    penalty_delay_seconds?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyInput
     */
    fee_calculation_method?: PolicyInput_FeeCalculationMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PolicyInput_KindEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyInput_PenaltyFrequencyEnum {
    'hourly' = 'hourly',
    'daily' = 'daily'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyInput_PenaltyFrequencyStrEnum {
    'hourly' = 'hourly',
    'daily' = 'daily'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyInput_FeeCalculationMethodEnum {
    'lowest' = 'lowest',
    'highest' = 'highest',
    'sum' = 'sum',
    'mean' = 'mean',
    'origin' = 'origin',
    'destination' = 'destination'
}

/**
 * 
 * @export
 * @interface PolicyName
 */
export interface PolicyName {
    /**
     * 
     * @type {string}
     * @memberof PolicyName
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyName
     */
    name: string;
}
/**
 * 
 * @export
 * @interface PolicyOffenseAggregation
 */
export interface PolicyOffenseAggregation {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PolicyOffenseAggregation
     */
    device_types: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PolicyOffenseAggregation
     */
    providers: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof PolicyOffenseAggregation
     */
    total: number;
}
/**
 * 
 * @export
 * @interface PolicyResponse
 */
export interface PolicyResponse {
    /**
     * 
     * @type {Array<Policy>}
     * @memberof PolicyResponse
     */
    results: Array<Policy>;
    /**
     * 
     * @type {PolicyAggregation}
     * @memberof PolicyResponse
     */
    aggregation: PolicyAggregation;
}
/**
 * 
 * @export
 * @interface PolicyRule
 */
export interface PolicyRule {
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    rule_id: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    rule_type: PolicyRule_RuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    rule_units?: PolicyRule_RuleUnitsEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyRule
     */
    geographies: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PolicyRule
     */
    statuses: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyRule
     */
    vehicle_types: Array<PolicyRule_VehicleTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    end_time?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyRule
     */
    days?: Array<PolicyRule_DaysEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PolicyRule
     */
    messages?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    value_url?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyRule
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyRule
     */
    maximum?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyRule
     */
    rate_amount?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PolicyRule
     */
    config?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum PolicyRule_RuleTypeEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyRule_RuleUnitsEnum {
    'seconds' = 'seconds',
    'minutes' = 'minutes',
    'hours' = 'hours',
    'kmh' = 'kmh',
    'mph' = 'mph',
    'device' = 'device',
    'amount' = 'amount'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyRule_VehicleTypesEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum PolicyRule_DaysEnum {
    'sun' = 'sun',
    'mon' = 'mon',
    'tue' = 'tue',
    'wed' = 'wed',
    'thu' = 'thu',
    'fri' = 'fri',
    'sat' = 'sat'
}

/**
 * GeoJSON Polygon
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    type: Polygon_TypeEnum;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof Polygon
     */
    coordinates: Array<Array<Array<number>>>;
}

/**
    * @export
    * @enum {string}
    */
export enum Polygon_TypeEnum {
    'Polygon' = 'Polygon',
    'MultiPolygon' = 'MultiPolygon'
}

/**
 * 
 * @export
 * @interface PolygonRequest
 */
export interface PolygonRequest {
    /**
     * 
     * @type {Polygon}
     * @memberof PolygonRequest
     */
    geom: Polygon;
    /**
     * Name of the polygon
     * @type {string}
     * @memberof PolygonRequest
     */
    label: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolygonRequest
     */
    areas: Array<string>;
}
/**
 * 
 * @export
 * @interface PolygonResponse
 */
export interface PolygonResponse {
    /**
     * Unique Polygon identifier (UUID)
     * @type {string}
     * @memberof PolygonResponse
     */
    id: string;
    /**
     * Name of the polygon
     * @type {string}
     * @memberof PolygonResponse
     */
    label: string;
    /**
     * 
     * @type {Polygon}
     * @memberof PolygonResponse
     */
    geom: Polygon;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolygonResponse
     */
    areas?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PolygonResponse
     */
    properties: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PolygonsImportRequest
 */
export interface PolygonsImportRequest {
    /**
     * 
     * @type {Array<PolygonRequest>}
     * @memberof PolygonsImportRequest
     */
    polygons: Array<PolygonRequest>;
}
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * Unique provider identifier (UUID)
     * @type {string}
     * @memberof Provider
     */
    id: string;
    /**
     * Name of the Provider
     * @type {string}
     * @memberof Provider
     */
    name: string;
    /**
     * 
     * @type {ProviderColors}
     * @memberof Provider
     */
    colors?: ProviderColors;
    /**
     * Logo of provider
     * @type {string}
     * @memberof Provider
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    logo_b64?: string;
    /**
     * This provider is a device operator
     * @type {boolean}
     * @memberof Provider
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    provider_kind?: Provider_ProviderKindEnum;
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    mds_credential_id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum Provider_ProviderKindEnum {
    'bestmile' = 'bestmile',
    'gbfs' = 'gbfs',
    'hopon' = 'hopon',
    'mds' = 'mds',
    'upstream' = 'upstream'
}

/**
 * 
 * @export
 * @interface ProviderApiAuthentication
 */
export interface ProviderApiAuthentication {
    /**
     * 
     * @type {string}
     * @memberof ProviderApiAuthentication
     */
    type: ProviderApiAuthentication_TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProviderApiAuthentication
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderApiAuthentication
     */
    client_secret?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderApiAuthentication
     */
    token?: string;
    /**
     * URL to get the token - Only used by the native poller
     * @type {string}
     * @memberof ProviderApiAuthentication
     */
    token_url?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProviderApiAuthentication_TypeEnum {
    'header' = 'header',
    'none' = 'none',
    'oauth2' = 'oauth2',
    'spin' = 'spin',
    'token' = 'token'
}

/**
 * 
 * @export
 * @interface ProviderApiConfiguration
 */
export interface ProviderApiConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ProviderApiConfiguration
     */
    api_version: ProviderApiConfiguration_ApiVersionEnum;
    /**
     * Lag to wait for before polling the latest status changes
     * @type {number}
     * @memberof ProviderApiConfiguration
     */
    provider_polling_lag?: number;
    /**
     * OAuth2 parameters to fetch the token
     * @type {{ [key: string]: string; }}
     * @memberof ProviderApiConfiguration
     */
    token_params?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum ProviderApiConfiguration_ApiVersionEnum {
    'none' = 'none',
    'v0_3' = 'v0_3',
    'v0_4' = 'v0_4',
    'v1_0' = 'v1_0',
    'v1_1' = 'v1_1'
}

/**
 * colors for distinguishing providers on map
 * @export
 * @interface ProviderColors
 */
export interface ProviderColors {
    /**
     * 
     * @type {string}
     * @memberof ProviderColors
     */
    primary?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderColors
     */
    secondary?: string;
}
/**
 * 
 * @export
 * @interface ProviderMetrics
 */
export interface ProviderMetrics {
    /**
     * 
     * @type {string}
     * @memberof ProviderMetrics
     */
    provider_id: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderMetrics
     */
    offense_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderMetrics
     */
    trip_count: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderMetrics
     */
    vehicle_count: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderMetrics
     */
    trip_ratio: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderMetrics
     */
    has_no_recent_data: boolean;
}
/**
 * 
 * @export
 * @interface RetrieveVehicle
 */
export interface RetrieveVehicle {
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof RetrieveVehicle
     */
    areas?: Array<{ [key: string]: string; }>;
    /**
     * Vehicle model
     * @type {string}
     * @memberof RetrieveVehicle
     */
    model?: string;
    /**
     * logo of the service provider of the device
     * @type {string}
     * @memberof RetrieveVehicle
     */
    provider_logo: string;
    /**
     * Unique device identifier (UUID)
     * @type {string}
     * @memberof RetrieveVehicle
     */
    id: string;
    /**
     * ID of the service provider of the device
     * @type {string}
     * @memberof RetrieveVehicle
     */
    provider_id: string;
    /**
     * Name of the service provider of the device
     * @type {string}
     * @memberof RetrieveVehicle
     */
    provider_name: string;
    /**
     * VIN (Vehicle Identification Number)
     * @type {string}
     * @memberof RetrieveVehicle
     */
    identification_number: string;
    /**
     * Device type
     * @type {string}
     * @memberof RetrieveVehicle
     */
    category: RetrieveVehicle_CategoryEnum;
    /**
     * Propulsion type(s)
     * @type {Array<string>}
     * @memberof RetrieveVehicle
     */
    propulsion: Array<RetrieveVehicle_PropulsionEnum>;
    /**
     * Latest status
     * @type {string}
     * @memberof RetrieveVehicle
     */
    last_known_status: RetrieveVehicle_LastKnownStatusEnum;
    /**
     * Vehicle registration date
     * @type {Date}
     * @memberof RetrieveVehicle
     */
    registration_date: Date;
    /**
     * Latest GPS timestamp
     * @type {Date}
     * @memberof RetrieveVehicle
     */
    last_known_timestamp: Date;
    /**
     * Percentage between 0 and 1
     * @type {number}
     * @memberof RetrieveVehicle
     */
    last_known_battery_pct: number;
    /**
     * 
     * @type {Point}
     * @memberof RetrieveVehicle
     */
    position?: Point;
    /**
     * 
     * @type {number}
     * @memberof RetrieveVehicle
     */
    number_of_offenses?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RetrieveVehicle_CategoryEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum RetrieveVehicle_PropulsionEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}
/**
    * @export
    * @enum {string}
    */
export enum RetrieveVehicle_LastKnownStatusEnum {
    'available' = 'available',
    'elsewhere' = 'elsewhere',
    'non_operational' = 'non_operational',
    'on_trip' = 'on_trip',
    'removed' = 'removed',
    'reserved' = 'reserved',
    'unknown' = 'unknown'
}

/**
 * 
 * @export
 * @interface RetrievedReport
 */
export interface RetrievedReport {
    /**
     * 
     * @type {number}
     * @memberof RetrievedReport
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RetrievedReport
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof RetrievedReport
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof RetrievedReport
     */
    created_by?: number;
    /**
     * 
     * @type {Date}
     * @memberof RetrievedReport
     */
    start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof RetrievedReport
     */
    end_at: Date;
    /**
     * 
     * @type {string}
     * @memberof RetrievedReport
     */
    area_id: string;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof RetrievedReport
     */
    area_geometry: FeatureCollection;
    /**
     * 
     * @type {string}
     * @memberof RetrievedReport
     */
    kind: string;
    /**
     * 
     * @type {any}
     * @memberof RetrievedReport
     */
    report?: any;
    /**
     * 
     * @type {any}
     * @memberof RetrievedReport
     */
    results?: any;
    /**
     * 
     * @type {number}
     * @memberof RetrievedReport
     */
    progress: number;
    /**
     * 
     * @type {boolean}
     * @memberof RetrievedReport
     */
    result_available?: boolean;
}
/**
 * 
 * @export
 * @interface ReverseAreaRequest
 */
export interface ReverseAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof ReverseAreaRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReverseAreaRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ReverseAreaRequest
     */
    color: string;
}
/**
 * 
 * @export
 * @interface RuleConfig
 */
export interface RuleConfig {
    /**
     * 
     * @type {number}
     * @memberof RuleConfig
     */
    permit_vehicle_fee?: number;
}
/**
 * 
 * @export
 * @interface RuleInput
 */
export interface RuleInput {
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    rule_id: string;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    rule_type: RuleInput_RuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    rule_units?: RuleInput_RuleUnitsEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleInput
     */
    geographies: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RuleInput
     */
    statuses: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleInput
     */
    vehicle_types: Array<RuleInput_VehicleTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    end_time?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleInput
     */
    days?: Array<RuleInput_DaysEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof RuleInput
     */
    has_timeslot?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RuleInput
     */
    messages?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    value_url?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleInput
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof RuleInput
     */
    maximum?: number;
    /**
     * 
     * @type {RuleConfig}
     * @memberof RuleInput
     */
    config?: RuleConfig;
    /**
     * 
     * @type {number}
     * @memberof RuleInput
     */
    rate_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof RuleInput
     */
    validation_error_message?: RuleInput_ValidationErrorMessageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RuleInput_RuleTypeEnum {
    'speed' = 'speed',
    'geofence' = 'geofence',
    'absolute_fleet_size' = 'absolute_fleet_size',
    'permit' = 'permit',
    'parking' = 'parking',
    'trip_taxation' = 'trip_taxation',
    'abandoned' = 'abandoned',
    'balancing' = 'balancing',
    'sidewalk' = 'sidewalk',
    'time' = 'time',
    'user' = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum RuleInput_RuleUnitsEnum {
    'seconds' = 'seconds',
    'minutes' = 'minutes',
    'hours' = 'hours',
    'kmh' = 'kmh',
    'mph' = 'mph',
    'device' = 'device',
    'amount' = 'amount'
}
/**
    * @export
    * @enum {string}
    */
export enum RuleInput_VehicleTypesEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum RuleInput_DaysEnum {
    'sun' = 'sun',
    'mon' = 'mon',
    'tue' = 'tue',
    'wed' = 'wed',
    'thu' = 'thu',
    'fri' = 'fri',
    'sat' = 'sat'
}
/**
    * @export
    * @enum {string}
    */
export enum RuleInput_ValidationErrorMessageEnum {
    'empty_days' = 'empty_days',
    'empty_rules' = 'empty_rules',
    'intersection' = 'intersection',
    'time_order' = 'time_order'
}

/**
 * 
 * @export
 * @interface Stops
 */
export interface Stops {
    /**
     * 
     * @type {string}
     * @memberof Stops
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Stops
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Stops
     */
    provider_id: string;
    /**
     * 
     * @type {string}
     * @memberof Stops
     */
    provider_name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Stops
     */
    position?: Array<number>;
}
/**
 * 
 * @export
 * @interface StopsResponse
 */
export interface StopsResponse {
    /**
     * 
     * @type {Array<Stops>}
     * @memberof StopsResponse
     */
    results: Array<Stops>;
}
/**
 * 
 * @export
 * @interface StreetSegmentDetail
 */
export interface StreetSegmentDetail {
    /**
     * 
     * @type {string}
     * @memberof StreetSegmentDetail
     */
    name: string;
    /**
     * 
     * @type {Array<TripPoint>}
     * @memberof StreetSegmentDetail
     */
    points: Array<TripPoint>;
}
/**
 * 
 * @export
 * @interface StreetSegmentsFile
 */
export interface StreetSegmentsFile {
    /**
     * 
     * @type {string}
     * @memberof StreetSegmentsFile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetSegmentsFile
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof StreetSegmentsFile
     */
    created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreetSegmentsFile
     */
    updated_at: Date;
}
/**
 * 
 * @export
 * @interface TotpLink
 */
export interface TotpLink {
    /**
     * 
     * @type {string}
     * @memberof TotpLink
     */
    totp: string;
}
/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    vehicle_id: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    device_id: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    end_time: string;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    speed: number;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    provider_id: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    vehicle_type: Trip_VehicleTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    propulsion_types: Array<Trip_PropulsionTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    location_types: Array<Trip_LocationTypesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum Trip_VehicleTypeEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum Trip_PropulsionTypesEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}
/**
    * @export
    * @enum {string}
    */
export enum Trip_LocationTypesEnum {
    'bike_lane' = 'bike_lane',
    'crosswalk' = 'crosswalk',
    'side' = 'side',
    'street' = 'street',
    'garage' = 'garage',
    'invalid' = 'invalid'
}

/**
 * 
 * @export
 * @interface TripCountInStreetSegment
 */
export interface TripCountInStreetSegment {
    /**
     * 
     * @type {string}
     * @memberof TripCountInStreetSegment
     */
    error_message?: string;
    /**
     * 
     * @type {{ [key: string]: StreetSegmentDetail; }}
     * @memberof TripCountInStreetSegment
     */
    street_segments: { [key: string]: StreetSegmentDetail; };
    /**
     * 
     * @type {Array<TripCountInStreetSegmentBucket>}
     * @memberof TripCountInStreetSegment
     */
    documents: Array<TripCountInStreetSegmentBucket>;
    /**
     * 
     * @type {number}
     * @memberof TripCountInStreetSegment
     */
    trips_per_street_min: number;
    /**
     * 
     * @type {number}
     * @memberof TripCountInStreetSegment
     */
    trips_per_street_max: number;
    /**
     * 
     * @type {number}
     * @memberof TripCountInStreetSegment
     */
    next_page?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCountInStreetSegment
     */
    total_page_num: number;
}
/**
 * 
 * @export
 * @interface TripCountInStreetSegmentBucket
 */
export interface TripCountInStreetSegmentBucket {
    /**
     * 
     * @type {string}
     * @memberof TripCountInStreetSegmentBucket
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof TripCountInStreetSegmentBucket
     */
    doc_count: number;
}
/**
 * 
 * @export
 * @interface TripOriginDestination
 */
export interface TripOriginDestination {
    /**
     * 
     * @type {string}
     * @memberof TripOriginDestination
     */
    id: string;
    /**
     * 
     * @type {TripPoint}
     * @memberof TripOriginDestination
     */
    origin: TripPoint;
    /**
     * 
     * @type {TripPoint}
     * @memberof TripOriginDestination
     */
    destination: TripPoint;
    /**
     * 
     * @type {string}
     * @memberof TripOriginDestination
     */
    provider_id: string;
    /**
     * 
     * @type {string}
     * @memberof TripOriginDestination
     */
    vehicle_type: TripOriginDestination_VehicleTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TripOriginDestination
     */
    propulsion_types: Array<TripOriginDestination_PropulsionTypesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum TripOriginDestination_VehicleTypeEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum TripOriginDestination_PropulsionTypesEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}

/**
 * 
 * @export
 * @interface TripPoint
 */
export interface TripPoint {
    /**
     * 
     * @type {number}
     * @memberof TripPoint
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof TripPoint
     */
    lon: number;
}
/**
 * 
 * @export
 * @interface TripsAggregations
 */
export interface TripsAggregations {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TripsAggregations
     */
    provider_id?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TripsAggregations
     */
    vehicle_type?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TripsAggregations
     */
    propulsion_type?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TripsAggregations
     */
    location_type?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof TripsAggregations
     */
    duration_min?: number;
    /**
     * 
     * @type {number}
     * @memberof TripsAggregations
     */
    duration_max?: number;
    /**
     * 
     * @type {number}
     * @memberof TripsAggregations
     */
    distance_min?: number;
    /**
     * 
     * @type {number}
     * @memberof TripsAggregations
     */
    distance_max?: number;
}
/**
 * 
 * @export
 * @interface TripsFacetedListResponse
 */
export interface TripsFacetedListResponse {
    /**
     * 
     * @type {Array<Trip>}
     * @memberof TripsFacetedListResponse
     */
    documents: Array<Trip>;
    /**
     * 
     * @type {TripsAggregations}
     * @memberof TripsFacetedListResponse
     */
    aggregations: TripsAggregations;
    /**
     * 
     * @type {number}
     * @memberof TripsFacetedListResponse
     */
    number_of_matching_documents: number;
}
/**
 * 
 * @export
 * @interface TwoFactorToken
 */
export interface TwoFactorToken {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorToken
     */
    token: string;
}
/**
 * 
 * @export
 * @interface TwoFactorValidation
 */
export interface TwoFactorValidation {
    /**
     * 
     * @type {boolean}
     * @memberof TwoFactorValidation
     */
    is_valid: boolean;
}
/**
 * 
 * @export
 * @interface UpdateReport
 */
export interface UpdateReport {
    /**
     * 
     * @type {string}
     * @memberof UpdateReport
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UpstreamGraphFilters
 */
export interface UpstreamGraphFilters {
    /**
     * 
     * @type {Date}
     * @memberof UpstreamGraphFilters
     */
    time_range_from: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpstreamGraphFilters
     */
    time_range_to: Date;
    /**
     * 
     * @type {string}
     * @memberof UpstreamGraphFilters
     */
    granularity: UpstreamGraphFilters_GranularityEnum;
    /**
     * 
     * @type {string}
     * @memberof UpstreamGraphFilters
     */
    layer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpstreamGraphFilters
     */
    device_type?: Array<UpstreamGraphFilters_DeviceTypeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpstreamGraphFilters
     */
    provider_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpstreamGraphFilters
     */
    limit?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpstreamGraphFilters_GranularityEnum {
    'quarter' = 'quarter',
    'month' = 'month',
    'week' = 'week',
    'day' = 'day',
    'hour' = 'hour'
}
/**
    * @export
    * @enum {string}
    */
export enum UpstreamGraphFilters_DeviceTypeEnum {
    'car' = 'car',
    'pt' = 'pt',
    'scooter' = 'scooter'
}

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    id: number;
    /**
     * 
     * @type {Array<DomainPermission>}
     * @memberof UserInfo
     */
    domain_permissions: Array<DomainPermission>;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UserInfo
     */
    groups: Array<Group>;
    /**
     * 
     * @type {Jurisdiction}
     * @memberof UserInfo
     */
    jurisdiction: Jurisdiction;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    username: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    two_factor_enabled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserInfo
     */
    last_password_update: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    must_update_password: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    is_provider_user: boolean;
}
/**
 * 
 * @export
 * @interface UserName
 */
export interface UserName {
    /**
     * 
     * @type {number}
     * @memberof UserName
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserName
     */
    full_name: string;
}
/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * Unique device identifier (UUID)
     * @type {string}
     * @memberof Vehicle
     */
    id: string;
    /**
     * ID of the service provider of the device
     * @type {string}
     * @memberof Vehicle
     */
    provider_id: string;
    /**
     * Name of the service provider of the device
     * @type {string}
     * @memberof Vehicle
     */
    provider_name: string;
    /**
     * VIN (Vehicle Identification Number)
     * @type {string}
     * @memberof Vehicle
     */
    identification_number: string;
    /**
     * Device type
     * @type {string}
     * @memberof Vehicle
     */
    category: Vehicle_CategoryEnum;
    /**
     * Propulsion type(s)
     * @type {Array<string>}
     * @memberof Vehicle
     */
    propulsion: Array<Vehicle_PropulsionEnum>;
    /**
     * Latest status
     * @type {string}
     * @memberof Vehicle
     */
    last_known_status: Vehicle_LastKnownStatusEnum;
    /**
     * Vehicle registration date
     * @type {Date}
     * @memberof Vehicle
     */
    registration_date: Date;
    /**
     * Latest GPS timestamp
     * @type {Date}
     * @memberof Vehicle
     */
    last_known_timestamp: Date;
    /**
     * Percentage between 0 and 1
     * @type {number}
     * @memberof Vehicle
     */
    last_known_battery_pct: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Vehicle
     */
    last_known_position?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum Vehicle_CategoryEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}
/**
    * @export
    * @enum {string}
    */
export enum Vehicle_PropulsionEnum {
    'human' = 'human',
    'electric_assist' = 'electric_assist',
    'electric' = 'electric',
    'combustion' = 'combustion'
}
/**
    * @export
    * @enum {string}
    */
export enum Vehicle_LastKnownStatusEnum {
    'available' = 'available',
    'elsewhere' = 'elsewhere',
    'non_operational' = 'non_operational',
    'on_trip' = 'on_trip',
    'removed' = 'removed',
    'reserved' = 'reserved',
    'unknown' = 'unknown'
}

/**
 * 
 * @export
 * @interface VehicleAggregation
 */
export interface VehicleAggregation {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleAggregation
     */
    categories: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleAggregation
     */
    statuses: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleAggregation
     */
    propulsions: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleAggregation
     */
    providers: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof VehicleAggregation
     */
    total: number;
}
/**
 * 
 * @export
 * @interface VehicleAutocomplete
 */
export interface VehicleAutocomplete {
    /**
     * 
     * @type {string}
     * @memberof VehicleAutocomplete
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleAutocomplete
     */
    vin: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleAutocomplete
     */
    category: VehicleAutocomplete_CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof VehicleAutocomplete
     */
    provider_name: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleAutocomplete
     */
    state: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VehicleAutocomplete_CategoryEnum {
    'bicycle' = 'bicycle',
    'car' = 'car',
    'delivery_bot' = 'delivery_bot',
    'moped' = 'moped',
    'other' = 'other',
    'scooter' = 'scooter'
}

/**
 * 
 * @export
 * @interface VehicleProperties
 */
export interface VehicleProperties {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof VehicleProperties
     */
    properties_mapping: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface VehicleResponse
 */
export interface VehicleResponse {
    /**
     * 
     * @type {Array<Vehicle>}
     * @memberof VehicleResponse
     */
    results: Array<Vehicle>;
    /**
     * 
     * @type {VehicleAggregation}
     * @memberof VehicleResponse
     */
    aggregation: VehicleAggregation;
}
/**
 * 
 * @export
 * @interface VehicleUids
 */
export interface VehicleUids {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleUids
     */
    uids: Array<string>;
}
/**
 * 
 * @export
 * @interface ZoneName
 */
export interface ZoneName {
    /**
     * 
     * @type {string}
     * @memberof ZoneName
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneName
     */
    slug: string;
}

/**
 * agencySettingsList parameters
 */
export interface IagencySettingsListProps {
    options?: any,
};

/**
 * AgencyApi - fetch parameter creator
 * @export
 */
export const AgencyApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencySettingsList({ options = {} }: IagencySettingsListProps): FetchArgs {
            const localVarPath = `/agency/settings/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgencyApi - functional programming interface
 * @export
 */
export const AgencyApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        agencySettingsListURL({ options }: IagencySettingsListProps): FetchArgs {
            return AgencyApiFetchParamCreator(configuration).agencySettingsList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencySettingsList({ options }: IagencySettingsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<AgencySettingsResponse> {
            const localVarFetchArgs = this.agencySettingsListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * AgencyApi - factory interface
 * @export
 */
export const AgencyApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencySettingsList({ options }: IagencySettingsListProps) {
            return AgencyApiFp(configuration).agencySettingsList({ options })(fetch, basePath);
        },
    };
};

/**
 * AgencyApi - object-oriented interface
 * @export
 * @class AgencyApi
 * @extends {BaseAPI}
 */
export class AgencyApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``agencySettingsList`` for arguments and options.
     *
     */
    public agencySettingsListURL({ options }: IagencySettingsListProps = {}): FetchArgs {
        return AgencyApiFp(this.configuration).agencySettingsListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgencyApi
     */
    public agencySettingsList({ options }: IagencySettingsListProps = {}) {
        return AgencyApiFp(this.configuration).agencySettingsList({ options })(this.fetch, this.basePath);
    }

}

/**
 * auditLogsExport parameters
 */
export interface IauditLogsExportProps {
    history_kind?: Array<string>, // 
    history_type?: Array<string>, // 
    name?: string, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * auditLogsList parameters
 */
export interface IauditLogsListProps {
    history_kind?: Array<string>, // 
    history_type?: Array<string>, // 
    name?: string, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * AuditLogsApi - fetch parameter creator
 * @export
 */
export const AuditLogsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsExport({ history_kind, history_type, name, limit, offset, options = {} }: IauditLogsExportProps): FetchArgs {
            const localVarPath = `/audit_logs/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (history_kind) {
                localVarQueryParameter['history_kind'] = history_kind;
            }

            if (history_type) {
                localVarQueryParameter['history_type'] = history_type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsList({ history_kind, history_type, name, limit, offset, options = {} }: IauditLogsListProps): FetchArgs {
            const localVarPath = `/audit_logs/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (history_kind) {
                localVarQueryParameter['history_kind'] = history_kind;
            }

            if (history_type) {
                localVarQueryParameter['history_type'] = history_type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        auditLogsExportURL({ history_kind, history_type, name, limit, offset, options }: IauditLogsExportProps): FetchArgs {
            return AuditLogsApiFetchParamCreator(configuration).auditLogsExport({ history_kind, history_type, name, limit, offset, options });
        },

        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsExport({ history_kind, history_type, name, limit, offset, options }: IauditLogsExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.auditLogsExportURL({ history_kind, history_type, name, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        auditLogsListURL({ history_kind, history_type, name, limit, offset, options }: IauditLogsListProps): FetchArgs {
            return AuditLogsApiFetchParamCreator(configuration).auditLogsList({ history_kind, history_type, name, limit, offset, options });
        },

        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsList({ history_kind, history_type, name, limit, offset, options }: IauditLogsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<AuditLogResponse> {
            const localVarFetchArgs = this.auditLogsListURL({ history_kind, history_type, name, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsExport({ history_kind, history_type, name, limit, offset, options }: IauditLogsExportProps) {
            return AuditLogsApiFp(configuration).auditLogsExport({ history_kind, history_type, name, limit, offset, options })(fetch, basePath);
        },
        /**
         * 
         * @param {Array<string>} [history_kind] 
         * @param {Array<string>} [history_type] 
         * @param {string} [name] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogsList({ history_kind, history_type, name, limit, offset, options }: IauditLogsListProps) {
            return AuditLogsApiFp(configuration).auditLogsList({ history_kind, history_type, name, limit, offset, options })(fetch, basePath);
        },
    };
};

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``auditLogsExport`` for arguments and options.
     *
     */
    public auditLogsExportURL({ history_kind, history_type, name, limit, offset, options }: IauditLogsExportProps = {}): FetchArgs {
        return AuditLogsApiFp(this.configuration).auditLogsExportURL({ history_kind, history_type, name, limit, offset, options });
    }

    /**
     * 
     * @param {Array<string>} [history_kind] 
     * @param {Array<string>} [history_type] 
     * @param {string} [name] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public auditLogsExport({ history_kind, history_type, name, limit, offset, options }: IauditLogsExportProps = {}) {
        return AuditLogsApiFp(this.configuration).auditLogsExport({ history_kind, history_type, name, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``auditLogsList`` for arguments and options.
     *
     */
    public auditLogsListURL({ history_kind, history_type, name, limit, offset, options }: IauditLogsListProps = {}): FetchArgs {
        return AuditLogsApiFp(this.configuration).auditLogsListURL({ history_kind, history_type, name, limit, offset, options });
    }

    /**
     * 
     * @param {Array<string>} [history_kind] 
     * @param {Array<string>} [history_type] 
     * @param {string} [name] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public auditLogsList({ history_kind, history_type, name, limit, offset, options }: IauditLogsListProps = {}) {
        return AuditLogsApiFp(this.configuration).auditLogsList({ history_kind, history_type, name, limit, offset, options })(this.fetch, this.basePath);
    }

}

/**
 * authentCreateApplicationCreate parameters
 */
export interface IauthentCreateApplicationCreateProps {
    options?: any,
};

/**
 * authentCreateApplicationDelete parameters
 */
export interface IauthentCreateApplicationDeleteProps {
    options?: any,
};

/**
 * authentDeleteApplicationCreate parameters
 */
export interface IauthentDeleteApplicationCreateProps {
    options?: any,
};

/**
 * authentDeleteApplicationDelete parameters
 */
export interface IauthentDeleteApplicationDeleteProps {
    options?: any,
};

/**
 * authentLongLivedTokenCreate parameters
 */
export interface IauthentLongLivedTokenCreateProps {
    options?: any,
};

/**
 * authentLongLivedTokenDelete parameters
 */
export interface IauthentLongLivedTokenDeleteProps {
    options?: any,
};

/**
 * authentRevokeApplicationCreate parameters
 */
export interface IauthentRevokeApplicationCreateProps {
    options?: any,
};

/**
 * authentRevokeApplicationDelete parameters
 */
export interface IauthentRevokeApplicationDeleteProps {
    options?: any,
};

/**
 * AuthentApi - fetch parameter creator
 * @export
 */
export const AuthentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationCreate({ options = {} }: IauthentCreateApplicationCreateProps): FetchArgs {
            const localVarPath = `/authent/create_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationDelete({ options = {} }: IauthentCreateApplicationDeleteProps): FetchArgs {
            const localVarPath = `/authent/create_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationCreate({ options = {} }: IauthentDeleteApplicationCreateProps): FetchArgs {
            const localVarPath = `/authent/delete_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationDelete({ options = {} }: IauthentDeleteApplicationDeleteProps): FetchArgs {
            const localVarPath = `/authent/delete_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenCreate({ options = {} }: IauthentLongLivedTokenCreateProps): FetchArgs {
            const localVarPath = `/authent/long_lived_token/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenDelete({ options = {} }: IauthentLongLivedTokenDeleteProps): FetchArgs {
            const localVarPath = `/authent/long_lived_token/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationCreate({ options = {} }: IauthentRevokeApplicationCreateProps): FetchArgs {
            const localVarPath = `/authent/revoke_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationDelete({ options = {} }: IauthentRevokeApplicationDeleteProps): FetchArgs {
            const localVarPath = `/authent/revoke_application/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthentApi - functional programming interface
 * @export
 */
export const AuthentApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        authentCreateApplicationCreateURL({ options }: IauthentCreateApplicationCreateProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentCreateApplicationCreate({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationCreate({ options }: IauthentCreateApplicationCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentCreateApplicationCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentCreateApplicationDeleteURL({ options }: IauthentCreateApplicationDeleteProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentCreateApplicationDelete({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationDelete({ options }: IauthentCreateApplicationDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentCreateApplicationDeleteURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentDeleteApplicationCreateURL({ options }: IauthentDeleteApplicationCreateProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentDeleteApplicationCreate({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationCreate({ options }: IauthentDeleteApplicationCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentDeleteApplicationCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentDeleteApplicationDeleteURL({ options }: IauthentDeleteApplicationDeleteProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentDeleteApplicationDelete({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationDelete({ options }: IauthentDeleteApplicationDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentDeleteApplicationDeleteURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentLongLivedTokenCreateURL({ options }: IauthentLongLivedTokenCreateProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentLongLivedTokenCreate({ options });
        },

        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenCreate({ options }: IauthentLongLivedTokenCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentLongLivedTokenCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentLongLivedTokenDeleteURL({ options }: IauthentLongLivedTokenDeleteProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentLongLivedTokenDelete({ options });
        },

        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenDelete({ options }: IauthentLongLivedTokenDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentLongLivedTokenDeleteURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentRevokeApplicationCreateURL({ options }: IauthentRevokeApplicationCreateProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentRevokeApplicationCreate({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationCreate({ options }: IauthentRevokeApplicationCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentRevokeApplicationCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        authentRevokeApplicationDeleteURL({ options }: IauthentRevokeApplicationDeleteProps): FetchArgs {
            return AuthentApiFetchParamCreator(configuration).authentRevokeApplicationDelete({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationDelete({ options }: IauthentRevokeApplicationDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.authentRevokeApplicationDeleteURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * AuthentApi - factory interface
 * @export
 */
export const AuthentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationCreate({ options }: IauthentCreateApplicationCreateProps) {
            return AuthentApiFp(configuration).authentCreateApplicationCreate({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentCreateApplicationDelete({ options }: IauthentCreateApplicationDeleteProps) {
            return AuthentApiFp(configuration).authentCreateApplicationDelete({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationCreate({ options }: IauthentDeleteApplicationCreateProps) {
            return AuthentApiFp(configuration).authentDeleteApplicationCreate({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentDeleteApplicationDelete({ options }: IauthentDeleteApplicationDeleteProps) {
            return AuthentApiFp(configuration).authentDeleteApplicationDelete({ options })(fetch, basePath);
        },
        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenCreate({ options }: IauthentLongLivedTokenCreateProps) {
            return AuthentApiFp(configuration).authentLongLivedTokenCreate({ options })(fetch, basePath);
        },
        /**
         * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
         * @summary Implements an endpoint to generate long lived (JWT) tokens.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentLongLivedTokenDelete({ options }: IauthentLongLivedTokenDeleteProps) {
            return AuthentApiFp(configuration).authentLongLivedTokenDelete({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationCreate({ options }: IauthentRevokeApplicationCreateProps) {
            return AuthentApiFp(configuration).authentRevokeApplicationCreate({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authentRevokeApplicationDelete({ options }: IauthentRevokeApplicationDeleteProps) {
            return AuthentApiFp(configuration).authentRevokeApplicationDelete({ options })(fetch, basePath);
        },
    };
};

/**
 * AuthentApi - object-oriented interface
 * @export
 * @class AuthentApi
 * @extends {BaseAPI}
 */
export class AuthentApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``authentCreateApplicationCreate`` for arguments and options.
     *
     */
    public authentCreateApplicationCreateURL({ options }: IauthentCreateApplicationCreateProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentCreateApplicationCreateURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentCreateApplicationCreate({ options }: IauthentCreateApplicationCreateProps = {}) {
        return AuthentApiFp(this.configuration).authentCreateApplicationCreate({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentCreateApplicationDelete`` for arguments and options.
     *
     */
    public authentCreateApplicationDeleteURL({ options }: IauthentCreateApplicationDeleteProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentCreateApplicationDeleteURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentCreateApplicationDelete({ options }: IauthentCreateApplicationDeleteProps = {}) {
        return AuthentApiFp(this.configuration).authentCreateApplicationDelete({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentDeleteApplicationCreate`` for arguments and options.
     *
     */
    public authentDeleteApplicationCreateURL({ options }: IauthentDeleteApplicationCreateProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentDeleteApplicationCreateURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentDeleteApplicationCreate({ options }: IauthentDeleteApplicationCreateProps = {}) {
        return AuthentApiFp(this.configuration).authentDeleteApplicationCreate({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentDeleteApplicationDelete`` for arguments and options.
     *
     */
    public authentDeleteApplicationDeleteURL({ options }: IauthentDeleteApplicationDeleteProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentDeleteApplicationDeleteURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentDeleteApplicationDelete({ options }: IauthentDeleteApplicationDeleteProps = {}) {
        return AuthentApiFp(this.configuration).authentDeleteApplicationDelete({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentLongLivedTokenCreate`` for arguments and options.
     *
     */
    public authentLongLivedTokenCreateURL({ options }: IauthentLongLivedTokenCreateProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentLongLivedTokenCreateURL({ options });
    }

    /**
     * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
     * @summary Implements an endpoint to generate long lived (JWT) tokens.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentLongLivedTokenCreate({ options }: IauthentLongLivedTokenCreateProps = {}) {
        return AuthentApiFp(this.configuration).authentLongLivedTokenCreate({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentLongLivedTokenDelete`` for arguments and options.
     *
     */
    public authentLongLivedTokenDeleteURL({ options }: IauthentLongLivedTokenDeleteProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentLongLivedTokenDeleteURL({ options });
    }

    /**
     * This is an alternate authentication method to Oauth2. An authorized user can generate such tokens and then transmit it to the token owner for future use with our API.
     * @summary Implements an endpoint to generate long lived (JWT) tokens.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentLongLivedTokenDelete({ options }: IauthentLongLivedTokenDeleteProps = {}) {
        return AuthentApiFp(this.configuration).authentLongLivedTokenDelete({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentRevokeApplicationCreate`` for arguments and options.
     *
     */
    public authentRevokeApplicationCreateURL({ options }: IauthentRevokeApplicationCreateProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentRevokeApplicationCreateURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentRevokeApplicationCreate({ options }: IauthentRevokeApplicationCreateProps = {}) {
        return AuthentApiFp(this.configuration).authentRevokeApplicationCreate({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``authentRevokeApplicationDelete`` for arguments and options.
     *
     */
    public authentRevokeApplicationDeleteURL({ options }: IauthentRevokeApplicationDeleteProps = {}): FetchArgs {
        return AuthentApiFp(this.configuration).authentRevokeApplicationDeleteURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthentApi
     */
    public authentRevokeApplicationDelete({ options }: IauthentRevokeApplicationDeleteProps = {}) {
        return AuthentApiFp(this.configuration).authentRevokeApplicationDelete({ options })(this.fetch, this.basePath);
    }

}

/**
 * bestmileStopsExport parameters
 */
export interface IbestmileStopsExportProps {
    options?: any,
};

/**
 * bestmileStopsList parameters
 */
export interface IbestmileStopsListProps {
    options?: any,
};

/**
 * bestmileStopsRead parameters
 */
export interface IbestmileStopsReadProps {
    id: string, // A UUID string identifying this stops.
    options?: any,
};

/**
 * bestmileVehiclesExport parameters
 */
export interface IbestmileVehiclesExportProps {
    capacity_min?: string, // range (lower bound)
    capacity_max?: string, // range (upper bound)
    occupancy_min?: string, // range (lower bound)
    occupancy_max?: string, // range (upper bound)
    options?: any,
};

/**
 * bestmileVehiclesList parameters
 */
export interface IbestmileVehiclesListProps {
    capacity_min?: string, // range (lower bound)
    capacity_max?: string, // range (upper bound)
    occupancy_min?: string, // range (lower bound)
    occupancy_max?: string, // range (upper bound)
    options?: any,
};

/**
 * bestmileVehiclesRead parameters
 */
export interface IbestmileVehiclesReadProps {
    id: string, // A UUID string identifying this vehicle.
    options?: any,
};

/**
 * BestmileApi - fetch parameter creator
 * @export
 */
export const BestmileApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsExport({ options = {} }: IbestmileStopsExportProps): FetchArgs {
            const localVarPath = `/bestmile/stops/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsList({ options = {} }: IbestmileStopsListProps): FetchArgs {
            const localVarPath = `/bestmile/stops/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsRead({ id, options = {} }: IbestmileStopsReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bestmileStopsRead.');
            }
            const localVarPath = `/bestmile/stops/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options = {} }: IbestmileVehiclesExportProps): FetchArgs {
            const localVarPath = `/bestmile/vehicles/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (capacity_min !== undefined) {
                localVarQueryParameter['capacity_min'] = capacity_min;
            }

            if (capacity_max !== undefined) {
                localVarQueryParameter['capacity_max'] = capacity_max;
            }

            if (occupancy_min !== undefined) {
                localVarQueryParameter['occupancy_min'] = occupancy_min;
            }

            if (occupancy_max !== undefined) {
                localVarQueryParameter['occupancy_max'] = occupancy_max;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options = {} }: IbestmileVehiclesListProps): FetchArgs {
            const localVarPath = `/bestmile/vehicles/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (capacity_min !== undefined) {
                localVarQueryParameter['capacity_min'] = capacity_min;
            }

            if (capacity_max !== undefined) {
                localVarQueryParameter['capacity_max'] = capacity_max;
            }

            if (occupancy_min !== undefined) {
                localVarQueryParameter['occupancy_min'] = occupancy_min;
            }

            if (occupancy_max !== undefined) {
                localVarQueryParameter['occupancy_max'] = occupancy_max;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesRead({ id, options = {} }: IbestmileVehiclesReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bestmileVehiclesRead.');
            }
            const localVarPath = `/bestmile/vehicles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BestmileApi - functional programming interface
 * @export
 */
export const BestmileApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        bestmileStopsExportURL({ options }: IbestmileStopsExportProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileStopsExport({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsExport({ options }: IbestmileStopsExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<BestmileStopsResponse> {
            const localVarFetchArgs = this.bestmileStopsExportURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        bestmileStopsListURL({ options }: IbestmileStopsListProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileStopsList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsList({ options }: IbestmileStopsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<BestmileStopsResponse> {
            const localVarFetchArgs = this.bestmileStopsListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        bestmileStopsReadURL({ id, options }: IbestmileStopsReadProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileStopsRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsRead({ id, options }: IbestmileStopsReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.bestmileStopsReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        bestmileVehiclesExportURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesExportProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
        },

        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<BestmileResponse> {
            const localVarFetchArgs = this.bestmileVehiclesExportURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        bestmileVehiclesListURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesListProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
        },

        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<BestmileResponse> {
            const localVarFetchArgs = this.bestmileVehiclesListURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        bestmileVehiclesReadURL({ id, options }: IbestmileVehiclesReadProps): FetchArgs {
            return BestmileApiFetchParamCreator(configuration).bestmileVehiclesRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesRead({ id, options }: IbestmileVehiclesReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.bestmileVehiclesReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * BestmileApi - factory interface
 * @export
 */
export const BestmileApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsExport({ options }: IbestmileStopsExportProps) {
            return BestmileApiFp(configuration).bestmileStopsExport({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsList({ options }: IbestmileStopsListProps) {
            return BestmileApiFp(configuration).bestmileStopsList({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileStopsRead({ id, options }: IbestmileStopsReadProps) {
            return BestmileApiFp(configuration).bestmileStopsRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesExportProps) {
            return BestmileApiFp(configuration).bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [capacity_min] range (lower bound)
         * @param {string} [capacity_max] range (upper bound)
         * @param {string} [occupancy_min] range (lower bound)
         * @param {string} [occupancy_max] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesListProps) {
            return BestmileApiFp(configuration).bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestmileVehiclesRead({ id, options }: IbestmileVehiclesReadProps) {
            return BestmileApiFp(configuration).bestmileVehiclesRead({ id, options })(fetch, basePath);
        },
    };
};

/**
 * BestmileApi - object-oriented interface
 * @export
 * @class BestmileApi
 * @extends {BaseAPI}
 */
export class BestmileApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileStopsExport`` for arguments and options.
     *
     */
    public bestmileStopsExportURL({ options }: IbestmileStopsExportProps = {}): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileStopsExportURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileStopsExport({ options }: IbestmileStopsExportProps = {}) {
        return BestmileApiFp(this.configuration).bestmileStopsExport({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileStopsList`` for arguments and options.
     *
     */
    public bestmileStopsListURL({ options }: IbestmileStopsListProps = {}): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileStopsListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileStopsList({ options }: IbestmileStopsListProps = {}) {
        return BestmileApiFp(this.configuration).bestmileStopsList({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileStopsRead`` for arguments and options.
     *
     */
    public bestmileStopsReadURL({ id, options }: IbestmileStopsReadProps): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileStopsReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileStopsRead({ id, options }: IbestmileStopsReadProps) {
        return BestmileApiFp(this.configuration).bestmileStopsRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileVehiclesExport`` for arguments and options.
     *
     */
    public bestmileVehiclesExportURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesExportProps = {}): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileVehiclesExportURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
    }

    /**
     * 
     * @param {string} [capacity_min] range (lower bound)
     * @param {string} [capacity_max] range (upper bound)
     * @param {string} [occupancy_min] range (lower bound)
     * @param {string} [occupancy_max] range (upper bound)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesExportProps = {}) {
        return BestmileApiFp(this.configuration).bestmileVehiclesExport({ capacity_min, capacity_max, occupancy_min, occupancy_max, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileVehiclesList`` for arguments and options.
     *
     */
    public bestmileVehiclesListURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesListProps = {}): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileVehiclesListURL({ capacity_min, capacity_max, occupancy_min, occupancy_max, options });
    }

    /**
     * 
     * @param {string} [capacity_min] range (lower bound)
     * @param {string} [capacity_max] range (upper bound)
     * @param {string} [occupancy_min] range (lower bound)
     * @param {string} [occupancy_max] range (upper bound)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options }: IbestmileVehiclesListProps = {}) {
        return BestmileApiFp(this.configuration).bestmileVehiclesList({ capacity_min, capacity_max, occupancy_min, occupancy_max, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``bestmileVehiclesRead`` for arguments and options.
     *
     */
    public bestmileVehiclesReadURL({ id, options }: IbestmileVehiclesReadProps): FetchArgs {
        return BestmileApiFp(this.configuration).bestmileVehiclesReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this vehicle.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestmileApi
     */
    public bestmileVehiclesRead({ id, options }: IbestmileVehiclesReadProps) {
        return BestmileApiFp(this.configuration).bestmileVehiclesRead({ id, options })(this.fetch, this.basePath);
    }

}

/**
 * changeJurisdictionCreate parameters
 */
export interface IchangeJurisdictionCreateProps {
    options?: any,
};

/**
 * ChangeJurisdictionApi - fetch parameter creator
 * @export
 */
export const ChangeJurisdictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeJurisdictionCreate({ options = {} }: IchangeJurisdictionCreateProps): FetchArgs {
            const localVarPath = `/change_jurisdiction/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeJurisdictionApi - functional programming interface
 * @export
 */
export const ChangeJurisdictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        changeJurisdictionCreateURL({ options }: IchangeJurisdictionCreateProps): FetchArgs {
            return ChangeJurisdictionApiFetchParamCreator(configuration).changeJurisdictionCreate({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeJurisdictionCreate({ options }: IchangeJurisdictionCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.changeJurisdictionCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * ChangeJurisdictionApi - factory interface
 * @export
 */
export const ChangeJurisdictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeJurisdictionCreate({ options }: IchangeJurisdictionCreateProps) {
            return ChangeJurisdictionApiFp(configuration).changeJurisdictionCreate({ options })(fetch, basePath);
        },
    };
};

/**
 * ChangeJurisdictionApi - object-oriented interface
 * @export
 * @class ChangeJurisdictionApi
 * @extends {BaseAPI}
 */
export class ChangeJurisdictionApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``changeJurisdictionCreate`` for arguments and options.
     *
     */
    public changeJurisdictionCreateURL({ options }: IchangeJurisdictionCreateProps = {}): FetchArgs {
        return ChangeJurisdictionApiFp(this.configuration).changeJurisdictionCreateURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeJurisdictionApi
     */
    public changeJurisdictionCreate({ options }: IchangeJurisdictionCreateProps = {}) {
        return ChangeJurisdictionApiFp(this.configuration).changeJurisdictionCreate({ options })(this.fetch, this.basePath);
    }

}

/**
 * esKpiSearchCreate parameters
 */
export interface IesKpiSearchCreateProps {
    options?: any,
};

/**
 * EsApi - fetch parameter creator
 * @export
 */
export const EsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        esKpiSearchCreate({ options = {} }: IesKpiSearchCreateProps): FetchArgs {
            const localVarPath = `/es/kpi-search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EsApi - functional programming interface
 * @export
 */
export const EsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        esKpiSearchCreateURL({ options }: IesKpiSearchCreateProps): FetchArgs {
            return EsApiFetchParamCreator(configuration).esKpiSearchCreate({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        esKpiSearchCreate({ options }: IesKpiSearchCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.esKpiSearchCreateURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * EsApi - factory interface
 * @export
 */
export const EsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        esKpiSearchCreate({ options }: IesKpiSearchCreateProps) {
            return EsApiFp(configuration).esKpiSearchCreate({ options })(fetch, basePath);
        },
    };
};

/**
 * EsApi - object-oriented interface
 * @export
 * @class EsApi
 * @extends {BaseAPI}
 */
export class EsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``esKpiSearchCreate`` for arguments and options.
     *
     */
    public esKpiSearchCreateURL({ options }: IesKpiSearchCreateProps = {}): FetchArgs {
        return EsApiFp(this.configuration).esKpiSearchCreateURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsApi
     */
    public esKpiSearchCreate({ options }: IesKpiSearchCreateProps = {}) {
        return EsApiFp(this.configuration).esKpiSearchCreate({ options })(this.fetch, this.basePath);
    }

}

/**
 * eventRecordsExport parameters
 */
export interface IeventRecordsExportProps {
    device: string,
    lang?: string,
    limit?: number,
    options?: any,
};

/**
 * eventRecordsList parameters
 */
export interface IeventRecordsListProps {
    device: string,
    lang?: string,
    limit?: number,
    options?: any,
};

/**
 * EventRecordsApi - fetch parameter creator
 * @export
 */
export const EventRecordsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsExport({ device, lang, limit, options = {} }: IeventRecordsExportProps): FetchArgs {
            // verify required parameter 'device' is not null or undefined
            if (device === null || device === undefined) {
                throw new RequiredError('device','Required parameter device was null or undefined when calling eventRecordsExport.');
            }
            const localVarPath = `/event_records/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsList({ device, lang, limit, options = {} }: IeventRecordsListProps): FetchArgs {
            // verify required parameter 'device' is not null or undefined
            if (device === null || device === undefined) {
                throw new RequiredError('device','Required parameter device was null or undefined when calling eventRecordsList.');
            }
            const localVarPath = `/event_records/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventRecordsApi - functional programming interface
 * @export
 */
export const EventRecordsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        eventRecordsExportURL({ device, lang, limit, options }: IeventRecordsExportProps): FetchArgs {
            return EventRecordsApiFetchParamCreator(configuration).eventRecordsExport({ device, lang, limit, options });
        },

        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsExport({ device, lang, limit, options }: IeventRecordsExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventRecord>> {
            const localVarFetchArgs = this.eventRecordsExportURL({ device, lang, limit, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        eventRecordsListURL({ device, lang, limit, options }: IeventRecordsListProps): FetchArgs {
            return EventRecordsApiFetchParamCreator(configuration).eventRecordsList({ device, lang, limit, options });
        },

        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsList({ device, lang, limit, options }: IeventRecordsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventRecord>> {
            const localVarFetchArgs = this.eventRecordsListURL({ device, lang, limit, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * EventRecordsApi - factory interface
 * @export
 */
export const EventRecordsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsExport({ device, lang, limit, options }: IeventRecordsExportProps) {
            return EventRecordsApiFp(configuration).eventRecordsExport({ device, lang, limit, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} device 
         * @param {string} [lang] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventRecordsList({ device, lang, limit, options }: IeventRecordsListProps) {
            return EventRecordsApiFp(configuration).eventRecordsList({ device, lang, limit, options })(fetch, basePath);
        },
    };
};

/**
 * EventRecordsApi - object-oriented interface
 * @export
 * @class EventRecordsApi
 * @extends {BaseAPI}
 */
export class EventRecordsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``eventRecordsExport`` for arguments and options.
     *
     */
    public eventRecordsExportURL({ device, lang, limit, options }: IeventRecordsExportProps): FetchArgs {
        return EventRecordsApiFp(this.configuration).eventRecordsExportURL({ device, lang, limit, options });
    }

    /**
     * 
     * @param {string} device 
     * @param {string} [lang] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventRecordsApi
     */
    public eventRecordsExport({ device, lang, limit, options }: IeventRecordsExportProps) {
        return EventRecordsApiFp(this.configuration).eventRecordsExport({ device, lang, limit, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``eventRecordsList`` for arguments and options.
     *
     */
    public eventRecordsListURL({ device, lang, limit, options }: IeventRecordsListProps): FetchArgs {
        return EventRecordsApiFp(this.configuration).eventRecordsListURL({ device, lang, limit, options });
    }

    /**
     * 
     * @param {string} device 
     * @param {string} [lang] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventRecordsApi
     */
    public eventRecordsList({ device, lang, limit, options }: IeventRecordsListProps) {
        return EventRecordsApiFp(this.configuration).eventRecordsList({ device, lang, limit, options })(this.fetch, this.basePath);
    }

}

/**
 * gbfsDataList parameters
 */
export interface IgbfsDataListProps {
    options?: any,
};

/**
 * GbfsDataApi - fetch parameter creator
 * @export
 */
export const GbfsDataApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gbfsDataList({ options = {} }: IgbfsDataListProps): FetchArgs {
            const localVarPath = `/gbfs_data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GbfsDataApi - functional programming interface
 * @export
 */
export const GbfsDataApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        gbfsDataListURL({ options }: IgbfsDataListProps): FetchArgs {
            return GbfsDataApiFetchParamCreator(configuration).gbfsDataList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gbfsDataList({ options }: IgbfsDataListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.gbfsDataListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * GbfsDataApi - factory interface
 * @export
 */
export const GbfsDataApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gbfsDataList({ options }: IgbfsDataListProps) {
            return GbfsDataApiFp(configuration).gbfsDataList({ options })(fetch, basePath);
        },
    };
};

/**
 * GbfsDataApi - object-oriented interface
 * @export
 * @class GbfsDataApi
 * @extends {BaseAPI}
 */
export class GbfsDataApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``gbfsDataList`` for arguments and options.
     *
     */
    public gbfsDataListURL({ options }: IgbfsDataListProps = {}): FetchArgs {
        return GbfsDataApiFp(this.configuration).gbfsDataListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GbfsDataApi
     */
    public gbfsDataList({ options }: IgbfsDataListProps = {}) {
        return GbfsDataApiFp(this.configuration).gbfsDataList({ options })(this.fetch, this.basePath);
    }

}

/**
 * graphAnomaliesPerProvider parameters
 */
export interface IgraphAnomaliesPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphAnomaliesRatePerProvider parameters
 */
export interface IgraphAnomaliesRatePerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphAverageDeploymentDurationPerZone parameters
 */
export interface IgraphAverageDeploymentDurationPerZoneProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphDevicesDeployedPerProvider parameters
 */
export interface IgraphDevicesDeployedPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphDevicesPerStatus parameters
 */
export interface IgraphDevicesPerStatusProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphEnergyConsumptionPerProvider parameters
 */
export interface IgraphEnergyConsumptionPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphFleetAvailability parameters
 */
export interface IgraphFleetAvailabilityProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphGazEmissionPerProvider parameters
 */
export interface IgraphGazEmissionPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphLatencyHistogram parameters
 */
export interface IgraphLatencyHistogramProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphLatencyPerProvider parameters
 */
export interface IgraphLatencyPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfNewUsers parameters
 */
export interface IgraphNumberOfNewUsersProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfNewUsersPerProvider parameters
 */
export interface IgraphNumberOfNewUsersPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfRecords parameters
 */
export interface IgraphNumberOfRecordsProps {
    data: UpstreamGraphFilters,
    options?: any,
};

/**
 * graphNumberOfTrips parameters
 */
export interface IgraphNumberOfTripsProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsPerProvider parameters
 */
export interface IgraphNumberOfTripsPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsPerTripPurpose parameters
 */
export interface IgraphNumberOfTripsPerTripPurposeProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsPerVehicle parameters
 */
export interface IgraphNumberOfTripsPerVehicleProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsPerZone parameters
 */
export interface IgraphNumberOfTripsPerZoneProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsWithSensors parameters
 */
export interface IgraphNumberOfTripsWithSensorsProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfTripsWithSensorsPercentage parameters
 */
export interface IgraphNumberOfTripsWithSensorsPercentageProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfUniqueUsers parameters
 */
export interface IgraphNumberOfUniqueUsersProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfUniqueUsersPerProvider parameters
 */
export interface IgraphNumberOfUniqueUsersPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphNumberOfUsersPerTripPerformed parameters
 */
export interface IgraphNumberOfUsersPerTripPerformedProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphOffensePricePerProviderPerDate parameters
 */
export interface IgraphOffensePricePerProviderPerDateProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphOffensesPerKind parameters
 */
export interface IgraphOffensesPerKindProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphOffensesPerProviderPerDate parameters
 */
export interface IgraphOffensesPerProviderPerDateProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphPercentageOfOffensesPerKind parameters
 */
export interface IgraphPercentageOfOffensesPerKindProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphPercentageOfTripsPerProvider parameters
 */
export interface IgraphPercentageOfTripsPerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphRatioOfTripsPerVehiclePerProvider parameters
 */
export interface IgraphRatioOfTripsPerVehiclePerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTotalDistance parameters
 */
export interface IgraphTotalDistanceProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTotalDistancePerProvider parameters
 */
export interface IgraphTotalDistancePerProviderProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTotalDistancePerTripPurpose parameters
 */
export interface IgraphTotalDistancePerTripPurposeProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTripFlows parameters
 */
export interface IgraphTripFlowsProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTripsDistribution parameters
 */
export interface IgraphTripsDistributionProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphTripsDuration parameters
 */
export interface IgraphTripsDurationProps {
    data: GraphFilters,
    options?: any,
};

/**
 * graphUpstreamRecordsFlows parameters
 */
export interface IgraphUpstreamRecordsFlowsProps {
    data: UpstreamGraphFilters,
    options?: any,
};

/**
 * reportGraphList parameters
 */
export interface IreportGraphListProps {
    options?: any,
};

/**
 * GraphsApi - fetch parameter creator
 * @export
 */
export const GraphsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesPerProvider({ data, options = {} }: IgraphAnomaliesPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphAnomaliesPerProvider.');
            }
            const localVarPath = `/graphs/anomalies-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesRatePerProvider({ data, options = {} }: IgraphAnomaliesRatePerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphAnomaliesRatePerProvider.');
            }
            const localVarPath = `/graphs/anomalies-rate-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAverageDeploymentDurationPerZone({ data, options = {} }: IgraphAverageDeploymentDurationPerZoneProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphAverageDeploymentDurationPerZone.');
            }
            const localVarPath = `/graphs/device-duration-per-zone/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesDeployedPerProvider({ data, options = {} }: IgraphDevicesDeployedPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphDevicesDeployedPerProvider.');
            }
            const localVarPath = `/graphs/devices-deployed-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesPerStatus({ data, options = {} }: IgraphDevicesPerStatusProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphDevicesPerStatus.');
            }
            const localVarPath = `/graphs/devices-per-status/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphEnergyConsumptionPerProvider({ data, options = {} }: IgraphEnergyConsumptionPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphEnergyConsumptionPerProvider.');
            }
            const localVarPath = `/graphs/energy-consumption-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphFleetAvailability({ data, options = {} }: IgraphFleetAvailabilityProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphFleetAvailability.');
            }
            const localVarPath = `/graphs/fleet-availability/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphGazEmissionPerProvider({ data, options = {} }: IgraphGazEmissionPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphGazEmissionPerProvider.');
            }
            const localVarPath = `/graphs/gaz-emission-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyHistogram({ data, options = {} }: IgraphLatencyHistogramProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphLatencyHistogram.');
            }
            const localVarPath = `/graphs/latency-histogram/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyPerProvider({ data, options = {} }: IgraphLatencyPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphLatencyPerProvider.');
            }
            const localVarPath = `/graphs/latency-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsers({ data, options = {} }: IgraphNumberOfNewUsersProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfNewUsers.');
            }
            const localVarPath = `/graphs/number-of-new-users/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsersPerProvider({ data, options = {} }: IgraphNumberOfNewUsersPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfNewUsersPerProvider.');
            }
            const localVarPath = `/graphs/number-of-new-users-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfRecords({ data, options = {} }: IgraphNumberOfRecordsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfRecords.');
            }
            const localVarPath = `/graphs/number-of-upstream-records/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpstreamGraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTrips({ data, options = {} }: IgraphNumberOfTripsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTrips.');
            }
            const localVarPath = `/graphs/number-of-trips/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerProvider({ data, options = {} }: IgraphNumberOfTripsPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsPerProvider.');
            }
            const localVarPath = `/graphs/number-of-trips-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerTripPurpose({ data, options = {} }: IgraphNumberOfTripsPerTripPurposeProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsPerTripPurpose.');
            }
            const localVarPath = `/graphs/number-of-trips-per-trip-purpose/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerVehicle({ data, options = {} }: IgraphNumberOfTripsPerVehicleProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsPerVehicle.');
            }
            const localVarPath = `/graphs/numer-of-trips-per-vehicle/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerZone({ data, options = {} }: IgraphNumberOfTripsPerZoneProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsPerZone.');
            }
            const localVarPath = `/graphs/number-of-trips-per-zone/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensors({ data, options = {} }: IgraphNumberOfTripsWithSensorsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsWithSensors.');
            }
            const localVarPath = `/graphs/number-of-trips-with-sensors/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensorsPercentage({ data, options = {} }: IgraphNumberOfTripsWithSensorsPercentageProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfTripsWithSensorsPercentage.');
            }
            const localVarPath = `/graphs/number-of-trips-with-sensors-percentage/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsers({ data, options = {} }: IgraphNumberOfUniqueUsersProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfUniqueUsers.');
            }
            const localVarPath = `/graphs/number-of-unique-users/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsersPerProvider({ data, options = {} }: IgraphNumberOfUniqueUsersPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfUniqueUsersPerProvider.');
            }
            const localVarPath = `/graphs/number-of-unique-users-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUsersPerTripPerformed({ data, options = {} }: IgraphNumberOfUsersPerTripPerformedProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphNumberOfUsersPerTripPerformed.');
            }
            const localVarPath = `/graphs/number-of-users-per-trip-performed/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensePricePerProviderPerDate({ data, options = {} }: IgraphOffensePricePerProviderPerDateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphOffensePricePerProviderPerDate.');
            }
            const localVarPath = `/graphs/offense-price-per-provider-per-date/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerKind({ data, options = {} }: IgraphOffensesPerKindProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphOffensesPerKind.');
            }
            const localVarPath = `/graphs/offenses-per-kind/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerProviderPerDate({ data, options = {} }: IgraphOffensesPerProviderPerDateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphOffensesPerProviderPerDate.');
            }
            const localVarPath = `/graphs/offenses-per-provider-per-date/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfOffensesPerKind({ data, options = {} }: IgraphPercentageOfOffensesPerKindProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphPercentageOfOffensesPerKind.');
            }
            const localVarPath = `/graphs/percentage-of-offenses-per-kind/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfTripsPerProvider({ data, options = {} }: IgraphPercentageOfTripsPerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphPercentageOfTripsPerProvider.');
            }
            const localVarPath = `/graphs/percentage-of-trips-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphRatioOfTripsPerVehiclePerProvider({ data, options = {} }: IgraphRatioOfTripsPerVehiclePerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphRatioOfTripsPerVehiclePerProvider.');
            }
            const localVarPath = `/graphs/ratio-of-trips-per-vehicle-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistance({ data, options = {} }: IgraphTotalDistanceProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTotalDistance.');
            }
            const localVarPath = `/graphs/total-distance/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerProvider({ data, options = {} }: IgraphTotalDistancePerProviderProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTotalDistancePerProvider.');
            }
            const localVarPath = `/graphs/total-distance-per-provider/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerTripPurpose({ data, options = {} }: IgraphTotalDistancePerTripPurposeProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTotalDistancePerTripPurpose.');
            }
            const localVarPath = `/graphs/total-distance-per-trip-purpose/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripFlows({ data, options = {} }: IgraphTripFlowsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTripFlows.');
            }
            const localVarPath = `/graphs/trips-flows/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDistribution({ data, options = {} }: IgraphTripsDistributionProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTripsDistribution.');
            }
            const localVarPath = `/graphs/trips-distribution/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDuration({ data, options = {} }: IgraphTripsDurationProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphTripsDuration.');
            }
            const localVarPath = `/graphs/trips-duration/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphUpstreamRecordsFlows({ data, options = {} }: IgraphUpstreamRecordsFlowsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling graphUpstreamRecordsFlows.');
            }
            const localVarPath = `/graphs/upstream-flows/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpstreamGraphFilters" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGraphList({ options = {} }: IreportGraphListProps): FetchArgs {
            const localVarPath = `/graphs/report-graph-list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GraphsApi - functional programming interface
 * @export
 */
export const GraphsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        graphAnomaliesPerProviderURL({ data, options }: IgraphAnomaliesPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphAnomaliesPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesPerProvider({ data, options }: IgraphAnomaliesPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphAnomaliesPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphAnomaliesRatePerProviderURL({ data, options }: IgraphAnomaliesRatePerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphAnomaliesRatePerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesRatePerProvider({ data, options }: IgraphAnomaliesRatePerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphAnomaliesRatePerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphAverageDeploymentDurationPerZoneURL({ data, options }: IgraphAverageDeploymentDurationPerZoneProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphAverageDeploymentDurationPerZone({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAverageDeploymentDurationPerZone({ data, options }: IgraphAverageDeploymentDurationPerZoneProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphData> {
            const localVarFetchArgs = this.graphAverageDeploymentDurationPerZoneURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphDevicesDeployedPerProviderURL({ data, options }: IgraphDevicesDeployedPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphDevicesDeployedPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesDeployedPerProvider({ data, options }: IgraphDevicesDeployedPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphDevicesDeployedPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphDevicesPerStatusURL({ data, options }: IgraphDevicesPerStatusProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphDevicesPerStatus({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesPerStatus({ data, options }: IgraphDevicesPerStatusProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphDevicesPerStatusURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphEnergyConsumptionPerProviderURL({ data, options }: IgraphEnergyConsumptionPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphEnergyConsumptionPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphEnergyConsumptionPerProvider({ data, options }: IgraphEnergyConsumptionPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphEnergyConsumptionPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphFleetAvailabilityURL({ data, options }: IgraphFleetAvailabilityProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphFleetAvailability({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphFleetAvailability({ data, options }: IgraphFleetAvailabilityProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphBucketResponse>> {
            const localVarFetchArgs = this.graphFleetAvailabilityURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphGazEmissionPerProviderURL({ data, options }: IgraphGazEmissionPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphGazEmissionPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphGazEmissionPerProvider({ data, options }: IgraphGazEmissionPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphCompareWithTotalResponse> {
            const localVarFetchArgs = this.graphGazEmissionPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphLatencyHistogramURL({ data, options }: IgraphLatencyHistogramProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphLatencyHistogram({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyHistogram({ data, options }: IgraphLatencyHistogramProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphBucket>> {
            const localVarFetchArgs = this.graphLatencyHistogramURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphLatencyPerProviderURL({ data, options }: IgraphLatencyPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphLatencyPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyPerProvider({ data, options }: IgraphLatencyPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphLatencyPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfNewUsersURL({ data, options }: IgraphNumberOfNewUsersProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfNewUsers({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsers({ data, options }: IgraphNumberOfNewUsersProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphBucketWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfNewUsersURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfNewUsersPerProviderURL({ data, options }: IgraphNumberOfNewUsersPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfNewUsersPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsersPerProvider({ data, options }: IgraphNumberOfNewUsersPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphCompareWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfNewUsersPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfRecordsURL({ data, options }: IgraphNumberOfRecordsProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfRecords({ data, options });
        },

        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfRecords({ data, options }: IgraphNumberOfRecordsProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphBucketWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfRecordsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsURL({ data, options }: IgraphNumberOfTripsProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTrips({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTrips({ data, options }: IgraphNumberOfTripsProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphBucketWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfTripsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsPerProviderURL({ data, options }: IgraphNumberOfTripsPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerProvider({ data, options }: IgraphNumberOfTripsPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphCompareWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfTripsPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsPerTripPurposeURL({ data, options }: IgraphNumberOfTripsPerTripPurposeProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsPerTripPurpose({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerTripPurpose({ data, options }: IgraphNumberOfTripsPerTripPurposeProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphNumberOfTripsPerTripPurposeURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsPerVehicleURL({ data, options }: IgraphNumberOfTripsPerVehicleProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsPerVehicle({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerVehicle({ data, options }: IgraphNumberOfTripsPerVehicleProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphNumberOfTripsPerVehicleURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsPerZoneURL({ data, options }: IgraphNumberOfTripsPerZoneProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsPerZone({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerZone({ data, options }: IgraphNumberOfTripsPerZoneProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphData> {
            const localVarFetchArgs = this.graphNumberOfTripsPerZoneURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsWithSensorsURL({ data, options }: IgraphNumberOfTripsWithSensorsProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsWithSensors({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensors({ data, options }: IgraphNumberOfTripsWithSensorsProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphNumberOfTripsWithSensorsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfTripsWithSensorsPercentageURL({ data, options }: IgraphNumberOfTripsWithSensorsPercentageProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfTripsWithSensorsPercentage({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensorsPercentage({ data, options }: IgraphNumberOfTripsWithSensorsPercentageProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphNumberOfTripsWithSensorsPercentageURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfUniqueUsersURL({ data, options }: IgraphNumberOfUniqueUsersProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfUniqueUsers({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsers({ data, options }: IgraphNumberOfUniqueUsersProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphBucketWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfUniqueUsersURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfUniqueUsersPerProviderURL({ data, options }: IgraphNumberOfUniqueUsersPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfUniqueUsersPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsersPerProvider({ data, options }: IgraphNumberOfUniqueUsersPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphCompareWithTotalResponse> {
            const localVarFetchArgs = this.graphNumberOfUniqueUsersPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphNumberOfUsersPerTripPerformedURL({ data, options }: IgraphNumberOfUsersPerTripPerformedProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphNumberOfUsersPerTripPerformed({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUsersPerTripPerformed({ data, options }: IgraphNumberOfUsersPerTripPerformedProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphNumberOfUsersPerTripPerformedURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphOffensePricePerProviderPerDateURL({ data, options }: IgraphOffensePricePerProviderPerDateProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphOffensePricePerProviderPerDate({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensePricePerProviderPerDate({ data, options }: IgraphOffensePricePerProviderPerDateProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphData> {
            const localVarFetchArgs = this.graphOffensePricePerProviderPerDateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphOffensesPerKindURL({ data, options }: IgraphOffensesPerKindProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphOffensesPerKind({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerKind({ data, options }: IgraphOffensesPerKindProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphOffensesPerKindURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphOffensesPerProviderPerDateURL({ data, options }: IgraphOffensesPerProviderPerDateProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphOffensesPerProviderPerDate({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerProviderPerDate({ data, options }: IgraphOffensesPerProviderPerDateProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphData> {
            const localVarFetchArgs = this.graphOffensesPerProviderPerDateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphPercentageOfOffensesPerKindURL({ data, options }: IgraphPercentageOfOffensesPerKindProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphPercentageOfOffensesPerKind({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfOffensesPerKind({ data, options }: IgraphPercentageOfOffensesPerKindProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphPercentageOfOffensesPerKindURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphPercentageOfTripsPerProviderURL({ data, options }: IgraphPercentageOfTripsPerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphPercentageOfTripsPerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfTripsPerProvider({ data, options }: IgraphPercentageOfTripsPerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphPercentageOfTripsPerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphRatioOfTripsPerVehiclePerProviderURL({ data, options }: IgraphRatioOfTripsPerVehiclePerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphRatioOfTripsPerVehiclePerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphRatioOfTripsPerVehiclePerProvider({ data, options }: IgraphRatioOfTripsPerVehiclePerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphCompareResponse>> {
            const localVarFetchArgs = this.graphRatioOfTripsPerVehiclePerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTotalDistanceURL({ data, options }: IgraphTotalDistanceProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTotalDistance({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistance({ data, options }: IgraphTotalDistanceProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphBucketWithTotalResponse> {
            const localVarFetchArgs = this.graphTotalDistanceURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTotalDistancePerProviderURL({ data, options }: IgraphTotalDistancePerProviderProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTotalDistancePerProvider({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerProvider({ data, options }: IgraphTotalDistancePerProviderProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphCompareWithTotalResponse> {
            const localVarFetchArgs = this.graphTotalDistancePerProviderURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTotalDistancePerTripPurposeURL({ data, options }: IgraphTotalDistancePerTripPurposeProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTotalDistancePerTripPurpose({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerTripPurpose({ data, options }: IgraphTotalDistancePerTripPurposeProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphTotalDistancePerTripPurposeURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTripFlowsURL({ data, options }: IgraphTripFlowsProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTripFlows({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripFlows({ data, options }: IgraphTripFlowsProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphFlowsResponse> {
            const localVarFetchArgs = this.graphTripFlowsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTripsDistributionURL({ data, options }: IgraphTripsDistributionProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTripsDistribution({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDistribution({ data, options }: IgraphTripsDistributionProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphTripsDistributionURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphTripsDurationURL({ data, options }: IgraphTripsDurationProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphTripsDuration({ data, options });
        },

        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDuration({ data, options }: IgraphTripsDurationProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphStackResponse>> {
            const localVarFetchArgs = this.graphTripsDurationURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        graphUpstreamRecordsFlowsURL({ data, options }: IgraphUpstreamRecordsFlowsProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).graphUpstreamRecordsFlows({ data, options });
        },

        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphUpstreamRecordsFlows({ data, options }: IgraphUpstreamRecordsFlowsProps): (fetch?: FetchAPI, basePath?: string) => Promise<GraphFlowsResponse> {
            const localVarFetchArgs = this.graphUpstreamRecordsFlowsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportGraphListURL({ options }: IreportGraphListProps): FetchArgs {
            return GraphsApiFetchParamCreator(configuration).reportGraphList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGraphList({ options }: IreportGraphListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GraphReport>> {
            const localVarFetchArgs = this.reportGraphListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * GraphsApi - factory interface
 * @export
 */
export const GraphsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesPerProvider({ data, options }: IgraphAnomaliesPerProviderProps) {
            return GraphsApiFp(configuration).graphAnomaliesPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAnomaliesRatePerProvider({ data, options }: IgraphAnomaliesRatePerProviderProps) {
            return GraphsApiFp(configuration).graphAnomaliesRatePerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAverageDeploymentDurationPerZone({ data, options }: IgraphAverageDeploymentDurationPerZoneProps) {
            return GraphsApiFp(configuration).graphAverageDeploymentDurationPerZone({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesDeployedPerProvider({ data, options }: IgraphDevicesDeployedPerProviderProps) {
            return GraphsApiFp(configuration).graphDevicesDeployedPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDevicesPerStatus({ data, options }: IgraphDevicesPerStatusProps) {
            return GraphsApiFp(configuration).graphDevicesPerStatus({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphEnergyConsumptionPerProvider({ data, options }: IgraphEnergyConsumptionPerProviderProps) {
            return GraphsApiFp(configuration).graphEnergyConsumptionPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphFleetAvailability({ data, options }: IgraphFleetAvailabilityProps) {
            return GraphsApiFp(configuration).graphFleetAvailability({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphGazEmissionPerProvider({ data, options }: IgraphGazEmissionPerProviderProps) {
            return GraphsApiFp(configuration).graphGazEmissionPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyHistogram({ data, options }: IgraphLatencyHistogramProps) {
            return GraphsApiFp(configuration).graphLatencyHistogram({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphLatencyPerProvider({ data, options }: IgraphLatencyPerProviderProps) {
            return GraphsApiFp(configuration).graphLatencyPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsers({ data, options }: IgraphNumberOfNewUsersProps) {
            return GraphsApiFp(configuration).graphNumberOfNewUsers({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfNewUsersPerProvider({ data, options }: IgraphNumberOfNewUsersPerProviderProps) {
            return GraphsApiFp(configuration).graphNumberOfNewUsersPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfRecords({ data, options }: IgraphNumberOfRecordsProps) {
            return GraphsApiFp(configuration).graphNumberOfRecords({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTrips({ data, options }: IgraphNumberOfTripsProps) {
            return GraphsApiFp(configuration).graphNumberOfTrips({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerProvider({ data, options }: IgraphNumberOfTripsPerProviderProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerTripPurpose({ data, options }: IgraphNumberOfTripsPerTripPurposeProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsPerTripPurpose({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerVehicle({ data, options }: IgraphNumberOfTripsPerVehicleProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsPerVehicle({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsPerZone({ data, options }: IgraphNumberOfTripsPerZoneProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsPerZone({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensors({ data, options }: IgraphNumberOfTripsWithSensorsProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsWithSensors({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfTripsWithSensorsPercentage({ data, options }: IgraphNumberOfTripsWithSensorsPercentageProps) {
            return GraphsApiFp(configuration).graphNumberOfTripsWithSensorsPercentage({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsers({ data, options }: IgraphNumberOfUniqueUsersProps) {
            return GraphsApiFp(configuration).graphNumberOfUniqueUsers({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUniqueUsersPerProvider({ data, options }: IgraphNumberOfUniqueUsersPerProviderProps) {
            return GraphsApiFp(configuration).graphNumberOfUniqueUsersPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphNumberOfUsersPerTripPerformed({ data, options }: IgraphNumberOfUsersPerTripPerformedProps) {
            return GraphsApiFp(configuration).graphNumberOfUsersPerTripPerformed({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensePricePerProviderPerDate({ data, options }: IgraphOffensePricePerProviderPerDateProps) {
            return GraphsApiFp(configuration).graphOffensePricePerProviderPerDate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerKind({ data, options }: IgraphOffensesPerKindProps) {
            return GraphsApiFp(configuration).graphOffensesPerKind({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphOffensesPerProviderPerDate({ data, options }: IgraphOffensesPerProviderPerDateProps) {
            return GraphsApiFp(configuration).graphOffensesPerProviderPerDate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfOffensesPerKind({ data, options }: IgraphPercentageOfOffensesPerKindProps) {
            return GraphsApiFp(configuration).graphPercentageOfOffensesPerKind({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphPercentageOfTripsPerProvider({ data, options }: IgraphPercentageOfTripsPerProviderProps) {
            return GraphsApiFp(configuration).graphPercentageOfTripsPerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphRatioOfTripsPerVehiclePerProvider({ data, options }: IgraphRatioOfTripsPerVehiclePerProviderProps) {
            return GraphsApiFp(configuration).graphRatioOfTripsPerVehiclePerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistance({ data, options }: IgraphTotalDistanceProps) {
            return GraphsApiFp(configuration).graphTotalDistance({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerProvider({ data, options }: IgraphTotalDistancePerProviderProps) {
            return GraphsApiFp(configuration).graphTotalDistancePerProvider({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTotalDistancePerTripPurpose({ data, options }: IgraphTotalDistancePerTripPurposeProps) {
            return GraphsApiFp(configuration).graphTotalDistancePerTripPurpose({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripFlows({ data, options }: IgraphTripFlowsProps) {
            return GraphsApiFp(configuration).graphTripFlows({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDistribution({ data, options }: IgraphTripsDistributionProps) {
            return GraphsApiFp(configuration).graphTripsDistribution({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {GraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTripsDuration({ data, options }: IgraphTripsDurationProps) {
            return GraphsApiFp(configuration).graphTripsDuration({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {UpstreamGraphFilters} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphUpstreamRecordsFlows({ data, options }: IgraphUpstreamRecordsFlowsProps) {
            return GraphsApiFp(configuration).graphUpstreamRecordsFlows({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGraphList({ options }: IreportGraphListProps) {
            return GraphsApiFp(configuration).reportGraphList({ options })(fetch, basePath);
        },
    };
};

/**
 * GraphsApi - object-oriented interface
 * @export
 * @class GraphsApi
 * @extends {BaseAPI}
 */
export class GraphsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``graphAnomaliesPerProvider`` for arguments and options.
     *
     */
    public graphAnomaliesPerProviderURL({ data, options }: IgraphAnomaliesPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphAnomaliesPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphAnomaliesPerProvider({ data, options }: IgraphAnomaliesPerProviderProps) {
        return GraphsApiFp(this.configuration).graphAnomaliesPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphAnomaliesRatePerProvider`` for arguments and options.
     *
     */
    public graphAnomaliesRatePerProviderURL({ data, options }: IgraphAnomaliesRatePerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphAnomaliesRatePerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphAnomaliesRatePerProvider({ data, options }: IgraphAnomaliesRatePerProviderProps) {
        return GraphsApiFp(this.configuration).graphAnomaliesRatePerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphAverageDeploymentDurationPerZone`` for arguments and options.
     *
     */
    public graphAverageDeploymentDurationPerZoneURL({ data, options }: IgraphAverageDeploymentDurationPerZoneProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphAverageDeploymentDurationPerZoneURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphAverageDeploymentDurationPerZone({ data, options }: IgraphAverageDeploymentDurationPerZoneProps) {
        return GraphsApiFp(this.configuration).graphAverageDeploymentDurationPerZone({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphDevicesDeployedPerProvider`` for arguments and options.
     *
     */
    public graphDevicesDeployedPerProviderURL({ data, options }: IgraphDevicesDeployedPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphDevicesDeployedPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphDevicesDeployedPerProvider({ data, options }: IgraphDevicesDeployedPerProviderProps) {
        return GraphsApiFp(this.configuration).graphDevicesDeployedPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphDevicesPerStatus`` for arguments and options.
     *
     */
    public graphDevicesPerStatusURL({ data, options }: IgraphDevicesPerStatusProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphDevicesPerStatusURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphDevicesPerStatus({ data, options }: IgraphDevicesPerStatusProps) {
        return GraphsApiFp(this.configuration).graphDevicesPerStatus({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphEnergyConsumptionPerProvider`` for arguments and options.
     *
     */
    public graphEnergyConsumptionPerProviderURL({ data, options }: IgraphEnergyConsumptionPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphEnergyConsumptionPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphEnergyConsumptionPerProvider({ data, options }: IgraphEnergyConsumptionPerProviderProps) {
        return GraphsApiFp(this.configuration).graphEnergyConsumptionPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphFleetAvailability`` for arguments and options.
     *
     */
    public graphFleetAvailabilityURL({ data, options }: IgraphFleetAvailabilityProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphFleetAvailabilityURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphFleetAvailability({ data, options }: IgraphFleetAvailabilityProps) {
        return GraphsApiFp(this.configuration).graphFleetAvailability({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphGazEmissionPerProvider`` for arguments and options.
     *
     */
    public graphGazEmissionPerProviderURL({ data, options }: IgraphGazEmissionPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphGazEmissionPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphGazEmissionPerProvider({ data, options }: IgraphGazEmissionPerProviderProps) {
        return GraphsApiFp(this.configuration).graphGazEmissionPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphLatencyHistogram`` for arguments and options.
     *
     */
    public graphLatencyHistogramURL({ data, options }: IgraphLatencyHistogramProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphLatencyHistogramURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphLatencyHistogram({ data, options }: IgraphLatencyHistogramProps) {
        return GraphsApiFp(this.configuration).graphLatencyHistogram({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphLatencyPerProvider`` for arguments and options.
     *
     */
    public graphLatencyPerProviderURL({ data, options }: IgraphLatencyPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphLatencyPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphLatencyPerProvider({ data, options }: IgraphLatencyPerProviderProps) {
        return GraphsApiFp(this.configuration).graphLatencyPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfNewUsers`` for arguments and options.
     *
     */
    public graphNumberOfNewUsersURL({ data, options }: IgraphNumberOfNewUsersProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfNewUsersURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfNewUsers({ data, options }: IgraphNumberOfNewUsersProps) {
        return GraphsApiFp(this.configuration).graphNumberOfNewUsers({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfNewUsersPerProvider`` for arguments and options.
     *
     */
    public graphNumberOfNewUsersPerProviderURL({ data, options }: IgraphNumberOfNewUsersPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfNewUsersPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfNewUsersPerProvider({ data, options }: IgraphNumberOfNewUsersPerProviderProps) {
        return GraphsApiFp(this.configuration).graphNumberOfNewUsersPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfRecords`` for arguments and options.
     *
     */
    public graphNumberOfRecordsURL({ data, options }: IgraphNumberOfRecordsProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfRecordsURL({ data, options });
    }

    /**
     * 
     * @param {UpstreamGraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfRecords({ data, options }: IgraphNumberOfRecordsProps) {
        return GraphsApiFp(this.configuration).graphNumberOfRecords({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTrips`` for arguments and options.
     *
     */
    public graphNumberOfTripsURL({ data, options }: IgraphNumberOfTripsProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTrips({ data, options }: IgraphNumberOfTripsProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTrips({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsPerProvider`` for arguments and options.
     *
     */
    public graphNumberOfTripsPerProviderURL({ data, options }: IgraphNumberOfTripsPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsPerProvider({ data, options }: IgraphNumberOfTripsPerProviderProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsPerTripPurpose`` for arguments and options.
     *
     */
    public graphNumberOfTripsPerTripPurposeURL({ data, options }: IgraphNumberOfTripsPerTripPurposeProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerTripPurposeURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsPerTripPurpose({ data, options }: IgraphNumberOfTripsPerTripPurposeProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerTripPurpose({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsPerVehicle`` for arguments and options.
     *
     */
    public graphNumberOfTripsPerVehicleURL({ data, options }: IgraphNumberOfTripsPerVehicleProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerVehicleURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsPerVehicle({ data, options }: IgraphNumberOfTripsPerVehicleProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerVehicle({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsPerZone`` for arguments and options.
     *
     */
    public graphNumberOfTripsPerZoneURL({ data, options }: IgraphNumberOfTripsPerZoneProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerZoneURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsPerZone({ data, options }: IgraphNumberOfTripsPerZoneProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsPerZone({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsWithSensors`` for arguments and options.
     *
     */
    public graphNumberOfTripsWithSensorsURL({ data, options }: IgraphNumberOfTripsWithSensorsProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsWithSensorsURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsWithSensors({ data, options }: IgraphNumberOfTripsWithSensorsProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsWithSensors({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfTripsWithSensorsPercentage`` for arguments and options.
     *
     */
    public graphNumberOfTripsWithSensorsPercentageURL({ data, options }: IgraphNumberOfTripsWithSensorsPercentageProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfTripsWithSensorsPercentageURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfTripsWithSensorsPercentage({ data, options }: IgraphNumberOfTripsWithSensorsPercentageProps) {
        return GraphsApiFp(this.configuration).graphNumberOfTripsWithSensorsPercentage({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfUniqueUsers`` for arguments and options.
     *
     */
    public graphNumberOfUniqueUsersURL({ data, options }: IgraphNumberOfUniqueUsersProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfUniqueUsersURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfUniqueUsers({ data, options }: IgraphNumberOfUniqueUsersProps) {
        return GraphsApiFp(this.configuration).graphNumberOfUniqueUsers({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfUniqueUsersPerProvider`` for arguments and options.
     *
     */
    public graphNumberOfUniqueUsersPerProviderURL({ data, options }: IgraphNumberOfUniqueUsersPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfUniqueUsersPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfUniqueUsersPerProvider({ data, options }: IgraphNumberOfUniqueUsersPerProviderProps) {
        return GraphsApiFp(this.configuration).graphNumberOfUniqueUsersPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphNumberOfUsersPerTripPerformed`` for arguments and options.
     *
     */
    public graphNumberOfUsersPerTripPerformedURL({ data, options }: IgraphNumberOfUsersPerTripPerformedProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphNumberOfUsersPerTripPerformedURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphNumberOfUsersPerTripPerformed({ data, options }: IgraphNumberOfUsersPerTripPerformedProps) {
        return GraphsApiFp(this.configuration).graphNumberOfUsersPerTripPerformed({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphOffensePricePerProviderPerDate`` for arguments and options.
     *
     */
    public graphOffensePricePerProviderPerDateURL({ data, options }: IgraphOffensePricePerProviderPerDateProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphOffensePricePerProviderPerDateURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphOffensePricePerProviderPerDate({ data, options }: IgraphOffensePricePerProviderPerDateProps) {
        return GraphsApiFp(this.configuration).graphOffensePricePerProviderPerDate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphOffensesPerKind`` for arguments and options.
     *
     */
    public graphOffensesPerKindURL({ data, options }: IgraphOffensesPerKindProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphOffensesPerKindURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphOffensesPerKind({ data, options }: IgraphOffensesPerKindProps) {
        return GraphsApiFp(this.configuration).graphOffensesPerKind({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphOffensesPerProviderPerDate`` for arguments and options.
     *
     */
    public graphOffensesPerProviderPerDateURL({ data, options }: IgraphOffensesPerProviderPerDateProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphOffensesPerProviderPerDateURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphOffensesPerProviderPerDate({ data, options }: IgraphOffensesPerProviderPerDateProps) {
        return GraphsApiFp(this.configuration).graphOffensesPerProviderPerDate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphPercentageOfOffensesPerKind`` for arguments and options.
     *
     */
    public graphPercentageOfOffensesPerKindURL({ data, options }: IgraphPercentageOfOffensesPerKindProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphPercentageOfOffensesPerKindURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphPercentageOfOffensesPerKind({ data, options }: IgraphPercentageOfOffensesPerKindProps) {
        return GraphsApiFp(this.configuration).graphPercentageOfOffensesPerKind({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphPercentageOfTripsPerProvider`` for arguments and options.
     *
     */
    public graphPercentageOfTripsPerProviderURL({ data, options }: IgraphPercentageOfTripsPerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphPercentageOfTripsPerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphPercentageOfTripsPerProvider({ data, options }: IgraphPercentageOfTripsPerProviderProps) {
        return GraphsApiFp(this.configuration).graphPercentageOfTripsPerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphRatioOfTripsPerVehiclePerProvider`` for arguments and options.
     *
     */
    public graphRatioOfTripsPerVehiclePerProviderURL({ data, options }: IgraphRatioOfTripsPerVehiclePerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphRatioOfTripsPerVehiclePerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphRatioOfTripsPerVehiclePerProvider({ data, options }: IgraphRatioOfTripsPerVehiclePerProviderProps) {
        return GraphsApiFp(this.configuration).graphRatioOfTripsPerVehiclePerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTotalDistance`` for arguments and options.
     *
     */
    public graphTotalDistanceURL({ data, options }: IgraphTotalDistanceProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTotalDistanceURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTotalDistance({ data, options }: IgraphTotalDistanceProps) {
        return GraphsApiFp(this.configuration).graphTotalDistance({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTotalDistancePerProvider`` for arguments and options.
     *
     */
    public graphTotalDistancePerProviderURL({ data, options }: IgraphTotalDistancePerProviderProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTotalDistancePerProviderURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTotalDistancePerProvider({ data, options }: IgraphTotalDistancePerProviderProps) {
        return GraphsApiFp(this.configuration).graphTotalDistancePerProvider({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTotalDistancePerTripPurpose`` for arguments and options.
     *
     */
    public graphTotalDistancePerTripPurposeURL({ data, options }: IgraphTotalDistancePerTripPurposeProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTotalDistancePerTripPurposeURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTotalDistancePerTripPurpose({ data, options }: IgraphTotalDistancePerTripPurposeProps) {
        return GraphsApiFp(this.configuration).graphTotalDistancePerTripPurpose({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTripFlows`` for arguments and options.
     *
     */
    public graphTripFlowsURL({ data, options }: IgraphTripFlowsProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTripFlowsURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTripFlows({ data, options }: IgraphTripFlowsProps) {
        return GraphsApiFp(this.configuration).graphTripFlows({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTripsDistribution`` for arguments and options.
     *
     */
    public graphTripsDistributionURL({ data, options }: IgraphTripsDistributionProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTripsDistributionURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTripsDistribution({ data, options }: IgraphTripsDistributionProps) {
        return GraphsApiFp(this.configuration).graphTripsDistribution({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphTripsDuration`` for arguments and options.
     *
     */
    public graphTripsDurationURL({ data, options }: IgraphTripsDurationProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphTripsDurationURL({ data, options });
    }

    /**
     * 
     * @param {GraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphTripsDuration({ data, options }: IgraphTripsDurationProps) {
        return GraphsApiFp(this.configuration).graphTripsDuration({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``graphUpstreamRecordsFlows`` for arguments and options.
     *
     */
    public graphUpstreamRecordsFlowsURL({ data, options }: IgraphUpstreamRecordsFlowsProps): FetchArgs {
        return GraphsApiFp(this.configuration).graphUpstreamRecordsFlowsURL({ data, options });
    }

    /**
     * 
     * @param {UpstreamGraphFilters} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public graphUpstreamRecordsFlows({ data, options }: IgraphUpstreamRecordsFlowsProps) {
        return GraphsApiFp(this.configuration).graphUpstreamRecordsFlows({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportGraphList`` for arguments and options.
     *
     */
    public reportGraphListURL({ options }: IreportGraphListProps = {}): FetchArgs {
        return GraphsApiFp(this.configuration).reportGraphListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public reportGraphList({ options }: IreportGraphListProps = {}) {
        return GraphsApiFp(this.configuration).reportGraphList({ options })(this.fetch, this.basePath);
    }

}

/**
 * homepageDevicesList parameters
 */
export interface IhomepageDevicesListProps {
    category?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    provider?: Array<string>, // 
    status?: Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>, // 
    telemetry_after?: string, // range (lower bound)
    telemetry_before?: string, // range (upper bound)
    options?: any,
};

/**
 * homepageDevicesRead parameters
 */
export interface IhomepageDevicesReadProps {
    id: string,
    options?: any,
};

/**
 * providerMetrics parameters
 */
export interface IproviderMetricsProps {
    options?: any,
};

/**
 * HomepageApi - fetch parameter creator
 * @export
 */
export const HomepageApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options = {} }: IhomepageDevicesListProps): FetchArgs {
            const localVarPath = `/homepage/devices/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (provider) {
                localVarQueryParameter['provider'] = provider;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (telemetry_after !== undefined) {
                localVarQueryParameter['telemetry_after'] = telemetry_after;
            }

            if (telemetry_before !== undefined) {
                localVarQueryParameter['telemetry_before'] = telemetry_before;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesRead({ id, options = {} }: IhomepageDevicesReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling homepageDevicesRead.');
            }
            const localVarPath = `/homepage/devices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unified end-point for all providers metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerMetrics({ options = {} }: IproviderMetricsProps): FetchArgs {
            const localVarPath = `/homepage/providers_metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomepageApi - functional programming interface
 * @export
 */
export const HomepageApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        homepageDevicesListURL({ category, provider, status, telemetry_after, telemetry_before, options }: IhomepageDevicesListProps): FetchArgs {
            return HomepageApiFetchParamCreator(configuration).homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options });
        },

        /**
         * 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options }: IhomepageDevicesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<HomepageVehicleResponse> {
            const localVarFetchArgs = this.homepageDevicesListURL({ category, provider, status, telemetry_after, telemetry_before, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        homepageDevicesReadURL({ id, options }: IhomepageDevicesReadProps): FetchArgs {
            return HomepageApiFetchParamCreator(configuration).homepageDevicesRead({ id, options });
        },

        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesRead({ id, options }: IhomepageDevicesReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.homepageDevicesReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providerMetricsURL({ options }: IproviderMetricsProps): FetchArgs {
            return HomepageApiFetchParamCreator(configuration).providerMetrics({ options });
        },

        /**
         * Unified end-point for all providers metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerMetrics({ options }: IproviderMetricsProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProviderMetrics>> {
            const localVarFetchArgs = this.providerMetricsURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * HomepageApi - factory interface
 * @export
 */
export const HomepageApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options }: IhomepageDevicesListProps) {
            return HomepageApiFp(configuration).homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homepageDevicesRead({ id, options }: IhomepageDevicesReadProps) {
            return HomepageApiFp(configuration).homepageDevicesRead({ id, options })(fetch, basePath);
        },
        /**
         * Unified end-point for all providers metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerMetrics({ options }: IproviderMetricsProps) {
            return HomepageApiFp(configuration).providerMetrics({ options })(fetch, basePath);
        },
    };
};

/**
 * HomepageApi - object-oriented interface
 * @export
 * @class HomepageApi
 * @extends {BaseAPI}
 */
export class HomepageApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``homepageDevicesList`` for arguments and options.
     *
     */
    public homepageDevicesListURL({ category, provider, status, telemetry_after, telemetry_before, options }: IhomepageDevicesListProps = {}): FetchArgs {
        return HomepageApiFp(this.configuration).homepageDevicesListURL({ category, provider, status, telemetry_after, telemetry_before, options });
    }

    /**
     * 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
     * @param {Array<string>} [provider] 
     * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
     * @param {string} [telemetry_after] range (lower bound)
     * @param {string} [telemetry_before] range (upper bound)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options }: IhomepageDevicesListProps = {}) {
        return HomepageApiFp(this.configuration).homepageDevicesList({ category, provider, status, telemetry_after, telemetry_before, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``homepageDevicesRead`` for arguments and options.
     *
     */
    public homepageDevicesReadURL({ id, options }: IhomepageDevicesReadProps): FetchArgs {
        return HomepageApiFp(this.configuration).homepageDevicesReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public homepageDevicesRead({ id, options }: IhomepageDevicesReadProps) {
        return HomepageApiFp(this.configuration).homepageDevicesRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providerMetrics`` for arguments and options.
     *
     */
    public providerMetricsURL({ options }: IproviderMetricsProps = {}): FetchArgs {
        return HomepageApiFp(this.configuration).providerMetricsURL({ options });
    }

    /**
     * Unified end-point for all providers metrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public providerMetrics({ options }: IproviderMetricsProps = {}) {
        return HomepageApiFp(this.configuration).providerMetrics({ options })(this.fetch, this.basePath);
    }

}

/**
 * hoponExport parameters
 */
export interface IhoponExportProps {
    status?: Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>, // 
    updatedAt_after?: string, // range (lower bound)
    updatedAt_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    options?: any,
};

/**
 * hoponList parameters
 */
export interface IhoponListProps {
    status?: Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>, // 
    updatedAt_after?: string, // range (lower bound)
    updatedAt_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    options?: any,
};

/**
 * hoponRead parameters
 */
export interface IhoponReadProps {
    uuid: string, // A unique value identifying this HopOn Vehicle.
    options?: any,
};

/**
 * HoponApi - fetch parameter creator
 * @export
 */
export const HoponApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options = {} }: IhoponExportProps): FetchArgs {
            const localVarPath = `/hopon/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAt_after !== undefined) {
                localVarQueryParameter['updatedAt_after'] = updatedAt_after;
            }

            if (updatedAt_before !== undefined) {
                localVarQueryParameter['updatedAt_before'] = updatedAt_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options = {} }: IhoponListProps): FetchArgs {
            const localVarPath = `/hopon/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAt_after !== undefined) {
                localVarQueryParameter['updatedAt_after'] = updatedAt_after;
            }

            if (updatedAt_before !== undefined) {
                localVarQueryParameter['updatedAt_before'] = updatedAt_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid A unique value identifying this HopOn Vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponRead({ uuid, options = {} }: IhoponReadProps): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling hoponRead.');
            }
            const localVarPath = `/hopon/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoponApi - functional programming interface
 * @export
 */
export const HoponApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        hoponExportURL({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponExportProps): FetchArgs {
            return HoponApiFetchParamCreator(configuration).hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options });
        },

        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<HopOnResponse> {
            const localVarFetchArgs = this.hoponExportURL({ status, updatedAt_after, updatedAt_before, tz, lang, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        hoponListURL({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponListProps): FetchArgs {
            return HoponApiFetchParamCreator(configuration).hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options });
        },

        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponListProps): (fetch?: FetchAPI, basePath?: string) => Promise<HopOnResponse> {
            const localVarFetchArgs = this.hoponListURL({ status, updatedAt_after, updatedAt_before, tz, lang, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        hoponReadURL({ uuid, options }: IhoponReadProps): FetchArgs {
            return HoponApiFetchParamCreator(configuration).hoponRead({ uuid, options });
        },

        /**
         * 
         * @param {string} uuid A unique value identifying this HopOn Vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponRead({ uuid, options }: IhoponReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.hoponReadURL({ uuid, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * HoponApi - factory interface
 * @export
 */
export const HoponApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponExportProps) {
            return HoponApiFp(configuration).hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options })(fetch, basePath);
        },
        /**
         * 
         * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
         * @param {string} [updatedAt_after] range (lower bound)
         * @param {string} [updatedAt_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponListProps) {
            return HoponApiFp(configuration).hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid A unique value identifying this HopOn Vehicle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoponRead({ uuid, options }: IhoponReadProps) {
            return HoponApiFp(configuration).hoponRead({ uuid, options })(fetch, basePath);
        },
    };
};

/**
 * HoponApi - object-oriented interface
 * @export
 * @class HoponApi
 * @extends {BaseAPI}
 */
export class HoponApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``hoponExport`` for arguments and options.
     *
     */
    public hoponExportURL({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponExportProps = {}): FetchArgs {
        return HoponApiFp(this.configuration).hoponExportURL({ status, updatedAt_after, updatedAt_before, tz, lang, options });
    }

    /**
     * 
     * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
     * @param {string} [updatedAt_after] range (lower bound)
     * @param {string} [updatedAt_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoponApi
     */
    public hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponExportProps = {}) {
        return HoponApiFp(this.configuration).hoponExport({ status, updatedAt_after, updatedAt_before, tz, lang, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``hoponList`` for arguments and options.
     *
     */
    public hoponListURL({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponListProps = {}): FetchArgs {
        return HoponApiFp(this.configuration).hoponListURL({ status, updatedAt_after, updatedAt_before, tz, lang, options });
    }

    /**
     * 
     * @param {Array<'AVAILABLE' | 'RUNNING' | 'RESERVED' | 'REPORTED' | 'MAINTAINING' | 'MAINTENANCE_PENDING' | 'DAMAGED' | 'LOST' | 'LOW_BATTERY'>} [status] 
     * @param {string} [updatedAt_after] range (lower bound)
     * @param {string} [updatedAt_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoponApi
     */
    public hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options }: IhoponListProps = {}) {
        return HoponApiFp(this.configuration).hoponList({ status, updatedAt_after, updatedAt_before, tz, lang, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``hoponRead`` for arguments and options.
     *
     */
    public hoponReadURL({ uuid, options }: IhoponReadProps): FetchArgs {
        return HoponApiFp(this.configuration).hoponReadURL({ uuid, options });
    }

    /**
     * 
     * @param {string} uuid A unique value identifying this HopOn Vehicle.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoponApi
     */
    public hoponRead({ uuid, options }: IhoponReadProps) {
        return HoponApiFp(this.configuration).hoponRead({ uuid, options })(this.fetch, this.basePath);
    }

}

/**
 * jurisdictionBoundaryList parameters
 */
export interface IjurisdictionBoundaryListProps {
    options?: any,
};

/**
 * jurisdictionKpiGeometriesList parameters
 */
export interface IjurisdictionKpiGeometriesListProps {
    agency_key: string,
    options?: any,
};

/**
 * jurisdictionKpiGeometriesRead parameters
 */
export interface IjurisdictionKpiGeometriesReadProps {
    agency_key: string,
    slug: string, // Short identifier of the geometry. Used for the indexation.
    options?: any,
};

/**
 * jurisdictionKpiGeometriesZoneNames parameters
 */
export interface IjurisdictionKpiGeometriesZoneNamesProps {
    agency_key: string,
    slug: string, // Short identifier of the geometry. Used for the indexation.
    options?: any,
};

/**
 * JurisdictionApi - fetch parameter creator
 * @export
 */
export const JurisdictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionBoundaryList({ options = {} }: IjurisdictionBoundaryListProps): FetchArgs {
            const localVarPath = `/jurisdiction/boundary/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesList({ agency_key, options = {} }: IjurisdictionKpiGeometriesListProps): FetchArgs {
            // verify required parameter 'agency_key' is not null or undefined
            if (agency_key === null || agency_key === undefined) {
                throw new RequiredError('agency_key','Required parameter agency_key was null or undefined when calling jurisdictionKpiGeometriesList.');
            }
            const localVarPath = `/jurisdiction/{agency_key}/kpi_geometries/`
                .replace(`{${"agency_key"}}`, encodeURIComponent(String(agency_key)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesRead({ agency_key, slug, options = {} }: IjurisdictionKpiGeometriesReadProps): FetchArgs {
            // verify required parameter 'agency_key' is not null or undefined
            if (agency_key === null || agency_key === undefined) {
                throw new RequiredError('agency_key','Required parameter agency_key was null or undefined when calling jurisdictionKpiGeometriesRead.');
            }
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling jurisdictionKpiGeometriesRead.');
            }
            const localVarPath = `/jurisdiction/{agency_key}/kpi_geometries/{slug}/`
                .replace(`{${"agency_key"}}`, encodeURIComponent(String(agency_key)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options = {} }: IjurisdictionKpiGeometriesZoneNamesProps): FetchArgs {
            // verify required parameter 'agency_key' is not null or undefined
            if (agency_key === null || agency_key === undefined) {
                throw new RequiredError('agency_key','Required parameter agency_key was null or undefined when calling jurisdictionKpiGeometriesZoneNames.');
            }
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling jurisdictionKpiGeometriesZoneNames.');
            }
            const localVarPath = `/jurisdiction/{agency_key}/kpi_geometries/{slug}/zone_names/`
                .replace(`{${"agency_key"}}`, encodeURIComponent(String(agency_key)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JurisdictionApi - functional programming interface
 * @export
 */
export const JurisdictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        jurisdictionBoundaryListURL({ options }: IjurisdictionBoundaryListProps): FetchArgs {
            return JurisdictionApiFetchParamCreator(configuration).jurisdictionBoundaryList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionBoundaryList({ options }: IjurisdictionBoundaryListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.jurisdictionBoundaryListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        jurisdictionKpiGeometriesListURL({ agency_key, options }: IjurisdictionKpiGeometriesListProps): FetchArgs {
            return JurisdictionApiFetchParamCreator(configuration).jurisdictionKpiGeometriesList({ agency_key, options });
        },

        /**
         * 
         * @param {string} agency_key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesList({ agency_key, options }: IjurisdictionKpiGeometriesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<KPIGeometry>> {
            const localVarFetchArgs = this.jurisdictionKpiGeometriesListURL({ agency_key, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        jurisdictionKpiGeometriesReadURL({ agency_key, slug, options }: IjurisdictionKpiGeometriesReadProps): FetchArgs {
            return JurisdictionApiFetchParamCreator(configuration).jurisdictionKpiGeometriesRead({ agency_key, slug, options });
        },

        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesRead({ agency_key, slug, options }: IjurisdictionKpiGeometriesReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<KPIGeometry> {
            const localVarFetchArgs = this.jurisdictionKpiGeometriesReadURL({ agency_key, slug, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        jurisdictionKpiGeometriesZoneNamesURL({ agency_key, slug, options }: IjurisdictionKpiGeometriesZoneNamesProps): FetchArgs {
            return JurisdictionApiFetchParamCreator(configuration).jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options });
        },

        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options }: IjurisdictionKpiGeometriesZoneNamesProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZoneName>> {
            const localVarFetchArgs = this.jurisdictionKpiGeometriesZoneNamesURL({ agency_key, slug, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * JurisdictionApi - factory interface
 * @export
 */
export const JurisdictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionBoundaryList({ options }: IjurisdictionBoundaryListProps) {
            return JurisdictionApiFp(configuration).jurisdictionBoundaryList({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesList({ agency_key, options }: IjurisdictionKpiGeometriesListProps) {
            return JurisdictionApiFp(configuration).jurisdictionKpiGeometriesList({ agency_key, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesRead({ agency_key, slug, options }: IjurisdictionKpiGeometriesReadProps) {
            return JurisdictionApiFp(configuration).jurisdictionKpiGeometriesRead({ agency_key, slug, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} agency_key 
         * @param {string} slug Short identifier of the geometry. Used for the indexation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options }: IjurisdictionKpiGeometriesZoneNamesProps) {
            return JurisdictionApiFp(configuration).jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options })(fetch, basePath);
        },
    };
};

/**
 * JurisdictionApi - object-oriented interface
 * @export
 * @class JurisdictionApi
 * @extends {BaseAPI}
 */
export class JurisdictionApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``jurisdictionBoundaryList`` for arguments and options.
     *
     */
    public jurisdictionBoundaryListURL({ options }: IjurisdictionBoundaryListProps = {}): FetchArgs {
        return JurisdictionApiFp(this.configuration).jurisdictionBoundaryListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JurisdictionApi
     */
    public jurisdictionBoundaryList({ options }: IjurisdictionBoundaryListProps = {}) {
        return JurisdictionApiFp(this.configuration).jurisdictionBoundaryList({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``jurisdictionKpiGeometriesList`` for arguments and options.
     *
     */
    public jurisdictionKpiGeometriesListURL({ agency_key, options }: IjurisdictionKpiGeometriesListProps): FetchArgs {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesListURL({ agency_key, options });
    }

    /**
     * 
     * @param {string} agency_key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JurisdictionApi
     */
    public jurisdictionKpiGeometriesList({ agency_key, options }: IjurisdictionKpiGeometriesListProps) {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesList({ agency_key, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``jurisdictionKpiGeometriesRead`` for arguments and options.
     *
     */
    public jurisdictionKpiGeometriesReadURL({ agency_key, slug, options }: IjurisdictionKpiGeometriesReadProps): FetchArgs {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesReadURL({ agency_key, slug, options });
    }

    /**
     * 
     * @param {string} agency_key 
     * @param {string} slug Short identifier of the geometry. Used for the indexation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JurisdictionApi
     */
    public jurisdictionKpiGeometriesRead({ agency_key, slug, options }: IjurisdictionKpiGeometriesReadProps) {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesRead({ agency_key, slug, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``jurisdictionKpiGeometriesZoneNames`` for arguments and options.
     *
     */
    public jurisdictionKpiGeometriesZoneNamesURL({ agency_key, slug, options }: IjurisdictionKpiGeometriesZoneNamesProps): FetchArgs {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesZoneNamesURL({ agency_key, slug, options });
    }

    /**
     * 
     * @param {string} agency_key 
     * @param {string} slug Short identifier of the geometry. Used for the indexation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JurisdictionApi
     */
    public jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options }: IjurisdictionKpiGeometriesZoneNamesProps) {
        return JurisdictionApiFp(this.configuration).jurisdictionKpiGeometriesZoneNames({ agency_key, slug, options })(this.fetch, this.basePath);
    }

}

/**
 * mdsCredentialsCreate parameters
 */
export interface ImdsCredentialsCreateProps {
    data: MdsApiCredentials,
    options?: any,
};

/**
 * mdsCredentialsDelete parameters
 */
export interface ImdsCredentialsDeleteProps {
    id: number, // A unique integer value identifying this mds api credentials.
    options?: any,
};

/**
 * mdsCredentialsList parameters
 */
export interface ImdsCredentialsListProps {
    options?: any,
};

/**
 * mdsCredentialsPartialUpdate parameters
 */
export interface ImdsCredentialsPartialUpdateProps {
    id: number, // A unique integer value identifying this mds api credentials.
    data: MdsApiCredentials,
    options?: any,
};

/**
 * mdsCredentialsRead parameters
 */
export interface ImdsCredentialsReadProps {
    id: number, // A unique integer value identifying this mds api credentials.
    options?: any,
};

/**
 * mdsCredentialsUpdate parameters
 */
export interface ImdsCredentialsUpdateProps {
    id: number, // A unique integer value identifying this mds api credentials.
    data: MdsApiCredentials,
    options?: any,
};

/**
 * MdsCredentialsApi - fetch parameter creator
 * @export
 */
export const MdsCredentialsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsCreate({ data, options = {} }: ImdsCredentialsCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling mdsCredentialsCreate.');
            }
            const localVarPath = `/mds_credentials/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MdsApiCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsDelete({ id, options = {} }: ImdsCredentialsDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mdsCredentialsDelete.');
            }
            const localVarPath = `/mds_credentials/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsList({ options = {} }: ImdsCredentialsListProps): FetchArgs {
            const localVarPath = `/mds_credentials/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsPartialUpdate({ id, data, options = {} }: ImdsCredentialsPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mdsCredentialsPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling mdsCredentialsPartialUpdate.');
            }
            const localVarPath = `/mds_credentials/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MdsApiCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsRead({ id, options = {} }: ImdsCredentialsReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mdsCredentialsRead.');
            }
            const localVarPath = `/mds_credentials/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsUpdate({ id, data, options = {} }: ImdsCredentialsUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mdsCredentialsUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling mdsCredentialsUpdate.');
            }
            const localVarPath = `/mds_credentials/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MdsApiCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MdsCredentialsApi - functional programming interface
 * @export
 */
export const MdsCredentialsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        mdsCredentialsCreateURL({ data, options }: ImdsCredentialsCreateProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsCreate({ data, options });
        },

        /**
         * 
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsCreate({ data, options }: ImdsCredentialsCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<MdsApiCredentials> {
            const localVarFetchArgs = this.mdsCredentialsCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        mdsCredentialsDeleteURL({ id, options }: ImdsCredentialsDeleteProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsDelete({ id, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsDelete({ id, options }: ImdsCredentialsDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.mdsCredentialsDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        mdsCredentialsListURL({ options }: ImdsCredentialsListProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsList({ options }: ImdsCredentialsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MdsApiCredentials>> {
            const localVarFetchArgs = this.mdsCredentialsListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        mdsCredentialsPartialUpdateURL({ id, data, options }: ImdsCredentialsPartialUpdateProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsPartialUpdate({ id, data, options }: ImdsCredentialsPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<MdsApiCredentials> {
            const localVarFetchArgs = this.mdsCredentialsPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        mdsCredentialsReadURL({ id, options }: ImdsCredentialsReadProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsRead({ id, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsRead({ id, options }: ImdsCredentialsReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<MdsApiCredentials> {
            const localVarFetchArgs = this.mdsCredentialsReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        mdsCredentialsUpdateURL({ id, data, options }: ImdsCredentialsUpdateProps): FetchArgs {
            return MdsCredentialsApiFetchParamCreator(configuration).mdsCredentialsUpdate({ id, data, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsUpdate({ id, data, options }: ImdsCredentialsUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<MdsApiCredentials> {
            const localVarFetchArgs = this.mdsCredentialsUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * MdsCredentialsApi - factory interface
 * @export
 */
export const MdsCredentialsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsCreate({ data, options }: ImdsCredentialsCreateProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsDelete({ id, options }: ImdsCredentialsDeleteProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsDelete({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsList({ options }: ImdsCredentialsListProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsList({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsPartialUpdate({ id, data, options }: ImdsCredentialsPartialUpdateProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsRead({ id, options }: ImdsCredentialsReadProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this mds api credentials.
         * @param {MdsApiCredentials} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mdsCredentialsUpdate({ id, data, options }: ImdsCredentialsUpdateProps) {
            return MdsCredentialsApiFp(configuration).mdsCredentialsUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * MdsCredentialsApi - object-oriented interface
 * @export
 * @class MdsCredentialsApi
 * @extends {BaseAPI}
 */
export class MdsCredentialsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsCreate`` for arguments and options.
     *
     */
    public mdsCredentialsCreateURL({ data, options }: ImdsCredentialsCreateProps): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsCreateURL({ data, options });
    }

    /**
     * 
     * @param {MdsApiCredentials} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsCreate({ data, options }: ImdsCredentialsCreateProps) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsDelete`` for arguments and options.
     *
     */
    public mdsCredentialsDeleteURL({ id, options }: ImdsCredentialsDeleteProps): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsDeleteURL({ id, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this mds api credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsDelete({ id, options }: ImdsCredentialsDeleteProps) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsList`` for arguments and options.
     *
     */
    public mdsCredentialsListURL({ options }: ImdsCredentialsListProps = {}): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsList({ options }: ImdsCredentialsListProps = {}) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsList({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsPartialUpdate`` for arguments and options.
     *
     */
    public mdsCredentialsPartialUpdateURL({ id, data, options }: ImdsCredentialsPartialUpdateProps): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this mds api credentials.
     * @param {MdsApiCredentials} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsPartialUpdate({ id, data, options }: ImdsCredentialsPartialUpdateProps) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsRead`` for arguments and options.
     *
     */
    public mdsCredentialsReadURL({ id, options }: ImdsCredentialsReadProps): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsReadURL({ id, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this mds api credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsRead({ id, options }: ImdsCredentialsReadProps) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``mdsCredentialsUpdate`` for arguments and options.
     *
     */
    public mdsCredentialsUpdateURL({ id, data, options }: ImdsCredentialsUpdateProps): FetchArgs {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this mds api credentials.
     * @param {MdsApiCredentials} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdsCredentialsApi
     */
    public mdsCredentialsUpdate({ id, data, options }: ImdsCredentialsUpdateProps) {
        return MdsCredentialsApiFp(this.configuration).mdsCredentialsUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * exportFleetOffenseProof parameters
 */
export interface IexportFleetOffenseProofProps {
    provider_ids?: Array<string>, // 
    vin?: string, // 
    policy_ids?: Array<string>, // 
    policy_types?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    lang?: string, // language to which export headers and rows should be translated
    id?: number,
    options?: any,
};

/**
 * exportOffenseList parameters
 */
export interface IexportOffenseListProps {
    provider_ids?: Array<string>, // 
    vin?: string, // 
    policy_ids?: Array<string>, // 
    policy_types?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    lang?: string, // language to which export headers and rows should be translated
    options?: any,
};

/**
 * exportPolicyOffenseList parameters
 */
export interface IexportPolicyOffenseListProps {
    policy_id: string,
    provider_ids?: Array<string>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    lang?: string, // language to which export headers and rows should be translated
    options?: any,
};

/**
 * feesExport parameters
 */
export interface IfeesExportProps {
    layer: string,
    offset?: number,
    language?: string,
    trip_or_policy_id?: string,
    provider_ids?: Array<string>,
    amount_range_start?: number,
    amount_range_end?: number,
    trip_datetime_range_start?: Date,
    trip_datetime_range_end?: Date,
    origins?: Array<string>,
    destinations?: Array<string>,
    vehicle_types?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    options?: any,
};

/**
 * feesList parameters
 */
export interface IfeesListProps {
    layer: string,
    offset?: number,
    language?: string,
    trip_or_policy_id?: string,
    provider_ids?: Array<string>,
    amount_range_start?: number,
    amount_range_end?: number,
    trip_datetime_range_start?: Date,
    trip_datetime_range_end?: Date,
    origins?: Array<string>,
    destinations?: Array<string>,
    vehicle_types?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    options?: any,
};

/**
 * offenseList parameters
 */
export interface IoffenseListProps {
    provider_ids?: Array<string>, // 
    vin?: string, // 
    policy_ids?: Array<string>, // 
    policy_types?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    options?: any,
};

/**
 * policyFacetedGraphs parameters
 */
export interface IpolicyFacetedGraphsProps {
    policy_id: string,
    granularity: 'quarter' | 'month' | 'week' | 'day' | 'hour',
    provider_ids?: Array<string>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    options?: any,
};

/**
 * policyOffenseList parameters
 */
export interface IpolicyOffenseListProps {
    policy_id: string,
    provider_ids?: Array<string>, // 
    start_date_after?: string, // range (lower bound)
    start_date_before?: string, // range (upper bound)
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    end_date_after?: string, // range (lower bound)
    end_date_before?: string, // range (upper bound)
    include_abnormals?: boolean, // 
    options?: any,
};

/**
 * OffensesApi - fetch parameter creator
 * @export
 */
export const OffensesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options = {} }: IexportFleetOffenseProofProps): FetchArgs {
            const localVarPath = `/offenses/export-fleet-offense-proof/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (policy_ids) {
                localVarQueryParameter['policy_ids'] = policy_ids;
            }

            if (policy_types) {
                localVarQueryParameter['policy_types'] = policy_types;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options = {} }: IexportOffenseListProps): FetchArgs {
            const localVarPath = `/offenses/export-list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (policy_ids) {
                localVarQueryParameter['policy_ids'] = policy_ids;
            }

            if (policy_types) {
                localVarQueryParameter['policy_types'] = policy_types;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options = {} }: IexportPolicyOffenseListProps): FetchArgs {
            // verify required parameter 'policy_id' is not null or undefined
            if (policy_id === null || policy_id === undefined) {
                throw new RequiredError('policy_id','Required parameter policy_id was null or undefined when calling exportPolicyOffenseList.');
            }
            const localVarPath = `/offenses/export-policy-list/{policy_id}/`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policy_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options = {} }: IfeesExportProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling feesExport.');
            }
            const localVarPath = `/offenses/export-fees/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (trip_or_policy_id !== undefined) {
                localVarQueryParameter['trip_or_policy_id'] = trip_or_policy_id;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (amount_range_start !== undefined) {
                localVarQueryParameter['amount_range_start'] = amount_range_start;
            }

            if (amount_range_end !== undefined) {
                localVarQueryParameter['amount_range_end'] = amount_range_end;
            }

            if (trip_datetime_range_start !== undefined) {
                localVarQueryParameter['trip_datetime_range_start'] = (trip_datetime_range_start as any).toISOString();
            }

            if (trip_datetime_range_end !== undefined) {
                localVarQueryParameter['trip_datetime_range_end'] = (trip_datetime_range_end as any).toISOString();
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (vehicle_types) {
                localVarQueryParameter['vehicle_types'] = vehicle_types;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options = {} }: IfeesListProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling feesList.');
            }
            const localVarPath = `/offenses/fees/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (trip_or_policy_id !== undefined) {
                localVarQueryParameter['trip_or_policy_id'] = trip_or_policy_id;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (amount_range_start !== undefined) {
                localVarQueryParameter['amount_range_start'] = amount_range_start;
            }

            if (amount_range_end !== undefined) {
                localVarQueryParameter['amount_range_end'] = amount_range_end;
            }

            if (trip_datetime_range_start !== undefined) {
                localVarQueryParameter['trip_datetime_range_start'] = (trip_datetime_range_start as any).toISOString();
            }

            if (trip_datetime_range_end !== undefined) {
                localVarQueryParameter['trip_datetime_range_end'] = (trip_datetime_range_end as any).toISOString();
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (vehicle_types) {
                localVarQueryParameter['vehicle_types'] = vehicle_types;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options = {} }: IoffenseListProps): FetchArgs {
            const localVarPath = `/offenses/list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (policy_ids) {
                localVarQueryParameter['policy_ids'] = policy_ids;
            }

            if (policy_types) {
                localVarQueryParameter['policy_types'] = policy_types;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {'quarter' | 'month' | 'week' | 'day' | 'hour'} granularity 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options = {} }: IpolicyFacetedGraphsProps): FetchArgs {
            // verify required parameter 'policy_id' is not null or undefined
            if (policy_id === null || policy_id === undefined) {
                throw new RequiredError('policy_id','Required parameter policy_id was null or undefined when calling policyFacetedGraphs.');
            }
            // verify required parameter 'granularity' is not null or undefined
            if (granularity === null || granularity === undefined) {
                throw new RequiredError('granularity','Required parameter granularity was null or undefined when calling policyFacetedGraphs.');
            }
            const localVarPath = `/offenses/policy-faceted-graphs/{policy_id}/`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policy_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options = {} }: IpolicyOffenseListProps): FetchArgs {
            // verify required parameter 'policy_id' is not null or undefined
            if (policy_id === null || policy_id === undefined) {
                throw new RequiredError('policy_id','Required parameter policy_id was null or undefined when calling policyOffenseList.');
            }
            const localVarPath = `/offenses/policy-list/{policy_id}/`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policy_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (start_date_after !== undefined) {
                localVarQueryParameter['start_date_after'] = start_date_after;
            }

            if (start_date_before !== undefined) {
                localVarQueryParameter['start_date_before'] = start_date_before;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (end_date_after !== undefined) {
                localVarQueryParameter['end_date_after'] = end_date_after;
            }

            if (end_date_before !== undefined) {
                localVarQueryParameter['end_date_before'] = end_date_before;
            }

            if (include_abnormals !== undefined) {
                localVarQueryParameter['include_abnormals'] = include_abnormals;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffensesApi - functional programming interface
 * @export
 */
export const OffensesApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        exportFleetOffenseProofURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options }: IexportFleetOffenseProofProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options });
        },

        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options }: IexportFleetOffenseProofProps): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = this.exportFleetOffenseProofURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        exportOffenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options }: IexportOffenseListProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options });
        },

        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options }: IexportOffenseListProps): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = this.exportOffenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        exportPolicyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options }: IexportPolicyOffenseListProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options });
        },

        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options }: IexportPolicyOffenseListProps): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = this.exportPolicyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        feesExportURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesExportProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Fee>> {
            const localVarFetchArgs = this.feesExportURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        feesListURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesListProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<FeesResponse> {
            const localVarFetchArgs = this.feesListURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        offenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options }: IoffenseListProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options });
        },

        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options }: IoffenseListProps): (fetch?: FetchAPI, basePath?: string) => Promise<FacetedListOffenseResponse> {
            const localVarFetchArgs = this.offenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policyFacetedGraphsURL({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyFacetedGraphsProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
        },

        /**
         * 
         * @param {string} policy_id 
         * @param {'quarter' | 'month' | 'week' | 'day' | 'hour'} granularity 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyFacetedGraphsProps): (fetch?: FetchAPI, basePath?: string) => Promise<PolicyFacetedGraphsResponse> {
            const localVarFetchArgs = this.policyFacetedGraphsURL({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyOffenseListProps): FetchArgs {
            return OffensesApiFetchParamCreator(configuration).policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
        },

        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyOffenseListProps): (fetch?: FetchAPI, basePath?: string) => Promise<PolicyFacetedListOffenseResponse> {
            const localVarFetchArgs = this.policyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * OffensesApi - factory interface
 * @export
 */
export const OffensesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options }: IexportFleetOffenseProofProps) {
            return OffensesApiFp(configuration).exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options }: IexportOffenseListProps) {
            return OffensesApiFp(configuration).exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {string} [lang] language to which export headers and rows should be translated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options }: IexportPolicyOffenseListProps) {
            return OffensesApiFp(configuration).exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesExportProps) {
            return OffensesApiFp(configuration).feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {string} [language] 
         * @param {string} [trip_or_policy_id] 
         * @param {Array<string>} [provider_ids] 
         * @param {number} [amount_range_start] 
         * @param {number} [amount_range_end] 
         * @param {Date} [trip_datetime_range_start] 
         * @param {Date} [trip_datetime_range_end] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesListProps) {
            return OffensesApiFp(configuration).feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options })(fetch, basePath);
        },
        /**
         * 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [vin] 
         * @param {Array<string>} [policy_ids] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options }: IoffenseListProps) {
            return OffensesApiFp(configuration).offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {'quarter' | 'month' | 'week' | 'day' | 'hour'} granularity 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyFacetedGraphsProps) {
            return OffensesApiFp(configuration).policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} policy_id 
         * @param {Array<string>} [provider_ids] 
         * @param {string} [start_date_after] range (lower bound)
         * @param {string} [start_date_before] range (upper bound)
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {string} [end_date_after] range (lower bound)
         * @param {string} [end_date_before] range (upper bound)
         * @param {boolean} [include_abnormals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyOffenseListProps) {
            return OffensesApiFp(configuration).policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options })(fetch, basePath);
        },
    };
};

/**
 * OffensesApi - object-oriented interface
 * @export
 * @class OffensesApi
 * @extends {BaseAPI}
 */
export class OffensesApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``exportFleetOffenseProof`` for arguments and options.
     *
     */
    public exportFleetOffenseProofURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options }: IexportFleetOffenseProofProps = {}): FetchArgs {
        return OffensesApiFp(this.configuration).exportFleetOffenseProofURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options });
    }

    /**
     * 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [vin] 
     * @param {Array<string>} [policy_ids] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {string} [lang] language to which export headers and rows should be translated
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options }: IexportFleetOffenseProofProps = {}) {
        return OffensesApiFp(this.configuration).exportFleetOffenseProof({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``exportOffenseList`` for arguments and options.
     *
     */
    public exportOffenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options }: IexportOffenseListProps = {}): FetchArgs {
        return OffensesApiFp(this.configuration).exportOffenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options });
    }

    /**
     * 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [vin] 
     * @param {Array<string>} [policy_ids] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {string} [lang] language to which export headers and rows should be translated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options }: IexportOffenseListProps = {}) {
        return OffensesApiFp(this.configuration).exportOffenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, lang, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``exportPolicyOffenseList`` for arguments and options.
     *
     */
    public exportPolicyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options }: IexportPolicyOffenseListProps): FetchArgs {
        return OffensesApiFp(this.configuration).exportPolicyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options });
    }

    /**
     * 
     * @param {string} policy_id 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {string} [lang] language to which export headers and rows should be translated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options }: IexportPolicyOffenseListProps) {
        return OffensesApiFp(this.configuration).exportPolicyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, lang, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``feesExport`` for arguments and options.
     *
     */
    public feesExportURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesExportProps): FetchArgs {
        return OffensesApiFp(this.configuration).feesExportURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {string} [language] 
     * @param {string} [trip_or_policy_id] 
     * @param {Array<string>} [provider_ids] 
     * @param {number} [amount_range_start] 
     * @param {number} [amount_range_end] 
     * @param {Date} [trip_datetime_range_start] 
     * @param {Date} [trip_datetime_range_end] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesExportProps) {
        return OffensesApiFp(this.configuration).feesExport({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``feesList`` for arguments and options.
     *
     */
    public feesListURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesListProps): FetchArgs {
        return OffensesApiFp(this.configuration).feesListURL({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {string} [language] 
     * @param {string} [trip_or_policy_id] 
     * @param {Array<string>} [provider_ids] 
     * @param {number} [amount_range_start] 
     * @param {number} [amount_range_end] 
     * @param {Date} [trip_datetime_range_start] 
     * @param {Date} [trip_datetime_range_end] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_types] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options }: IfeesListProps) {
        return OffensesApiFp(this.configuration).feesList({ layer, offset, language, trip_or_policy_id, provider_ids, amount_range_start, amount_range_end, trip_datetime_range_start, trip_datetime_range_end, origins, destinations, vehicle_types, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``offenseList`` for arguments and options.
     *
     */
    public offenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options }: IoffenseListProps = {}): FetchArgs {
        return OffensesApiFp(this.configuration).offenseListURL({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options });
    }

    /**
     * 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [vin] 
     * @param {Array<string>} [policy_ids] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [policy_types] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options }: IoffenseListProps = {}) {
        return OffensesApiFp(this.configuration).offenseList({ provider_ids, vin, policy_ids, policy_types, start_date_after, start_date_before, end_date_after, end_date_before, include_abnormals, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policyFacetedGraphs`` for arguments and options.
     *
     */
    public policyFacetedGraphsURL({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyFacetedGraphsProps): FetchArgs {
        return OffensesApiFp(this.configuration).policyFacetedGraphsURL({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
    }

    /**
     * 
     * @param {string} policy_id 
     * @param {'quarter' | 'month' | 'week' | 'day' | 'hour'} granularity 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyFacetedGraphsProps) {
        return OffensesApiFp(this.configuration).policyFacetedGraphs({ policy_id, granularity, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policyOffenseList`` for arguments and options.
     *
     */
    public policyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyOffenseListProps): FetchArgs {
        return OffensesApiFp(this.configuration).policyOffenseListURL({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options });
    }

    /**
     * 
     * @param {string} policy_id 
     * @param {Array<string>} [provider_ids] 
     * @param {string} [start_date_after] range (lower bound)
     * @param {string} [start_date_before] range (upper bound)
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {string} [end_date_after] range (lower bound)
     * @param {string} [end_date_before] range (upper bound)
     * @param {boolean} [include_abnormals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffensesApi
     */
    public policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options }: IpolicyOffenseListProps) {
        return OffensesApiFp(this.configuration).policyOffenseList({ policy_id, provider_ids, start_date_after, start_date_before, vehicle_type, end_date_after, end_date_before, include_abnormals, options })(this.fetch, this.basePath);
    }

}

/**
 * changePassword parameters
 */
export interface IchangePasswordProps {
    data: ChangePassword,
    options?: any,
};

/**
 * PasswordApi - fetch parameter creator
 * @export
 */
export const PasswordApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword({ data, options = {} }: IchangePasswordProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling changePassword.');
            }
            const localVarPath = `/password/change`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePassword" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        changePasswordURL({ data, options }: IchangePasswordProps): FetchArgs {
            return PasswordApiFetchParamCreator(configuration).changePassword({ data, options });
        },

        /**
         * 
         * @param {ChangePassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword({ data, options }: IchangePasswordProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.changePasswordURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ChangePassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword({ data, options }: IchangePasswordProps) {
            return PasswordApiFp(configuration).changePassword({ data, options })(fetch, basePath);
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``changePassword`` for arguments and options.
     *
     */
    public changePasswordURL({ data, options }: IchangePasswordProps): FetchArgs {
        return PasswordApiFp(this.configuration).changePasswordURL({ data, options });
    }

    /**
     * 
     * @param {ChangePassword} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public changePassword({ data, options }: IchangePasswordProps) {
        return PasswordApiFp(this.configuration).changePassword({ data, options })(this.fetch, this.basePath);
    }

}

/**
 * policiesCreate parameters
 */
export interface IpoliciesCreateProps {
    data: PolicyInput,
    options?: any,
};

/**
 * policiesDelete parameters
 */
export interface IpoliciesDeleteProps {
    id: string, // A UUID string identifying this policy.
    options?: any,
};

/**
 * policiesExist parameters
 */
export interface IpoliciesExistProps {
    published?: boolean, // 
    active_at?: string, // 
    kind?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    providers?: Array<string>, // 
    tz?: string, // 
    lang?: string, // 
    name?: string, // 
    status?: Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * policiesExport parameters
 */
export interface IpoliciesExportProps {
    published?: boolean, // 
    active_at?: string, // 
    kind?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    providers?: Array<string>, // 
    tz?: string, // 
    lang?: string, // 
    name?: string, // 
    status?: Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * policiesList parameters
 */
export interface IpoliciesListProps {
    published?: boolean, // 
    active_at?: string, // 
    kind?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    providers?: Array<string>, // 
    tz?: string, // 
    lang?: string, // 
    name?: string, // 
    status?: Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * policiesListLight parameters
 */
export interface IpoliciesListLightProps {
    published?: boolean, // 
    active_at?: string, // 
    kind?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    providers?: Array<string>, // 
    tz?: string, // 
    lang?: string, // 
    name?: string, // 
    status?: Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * policiesNames parameters
 */
export interface IpoliciesNamesProps {
    published?: boolean, // 
    active_at?: string, // 
    kind?: Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>, // 
    providers?: Array<string>, // 
    tz?: string, // 
    lang?: string, // 
    name?: string, // 
    status?: Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>, // 
    limit?: number, // Number of results to return per page.
    offset?: number, // The initial index from which to return the results.
    options?: any,
};

/**
 * policiesPartialUpdate parameters
 */
export interface IpoliciesPartialUpdateProps {
    id: string, // A UUID string identifying this policy.
    data: PolicyInput,
    options?: any,
};

/**
 * policiesRead parameters
 */
export interface IpoliciesReadProps {
    id: string, // A UUID string identifying this policy.
    options?: any,
};

/**
 * policiesUpdate parameters
 */
export interface IpoliciesUpdateProps {
    id: string, // A UUID string identifying this policy.
    data: PolicyInput,
    options?: any,
};

/**
 * PoliciesApi - fetch parameter creator
 * @export
 */
export const PoliciesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesCreate({ data, options = {} }: IpoliciesCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling policiesCreate.');
            }
            const localVarPath = `/policies/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolicyInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesDelete({ id, options = {} }: IpoliciesDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling policiesDelete.');
            }
            const localVarPath = `/policies/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options = {} }: IpoliciesExistProps): FetchArgs {
            const localVarPath = `/policies/exist/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (active_at !== undefined) {
                localVarQueryParameter['active_at'] = active_at;
            }

            if (kind) {
                localVarQueryParameter['kind'] = kind;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options = {} }: IpoliciesExportProps): FetchArgs {
            const localVarPath = `/policies/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (active_at !== undefined) {
                localVarQueryParameter['active_at'] = active_at;
            }

            if (kind) {
                localVarQueryParameter['kind'] = kind;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options = {} }: IpoliciesListProps): FetchArgs {
            const localVarPath = `/policies/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (active_at !== undefined) {
                localVarQueryParameter['active_at'] = active_at;
            }

            if (kind) {
                localVarQueryParameter['kind'] = kind;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options = {} }: IpoliciesListLightProps): FetchArgs {
            const localVarPath = `/policies/list_light/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (active_at !== undefined) {
                localVarQueryParameter['active_at'] = active_at;
            }

            if (kind) {
                localVarQueryParameter['kind'] = kind;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to return the policy names for the front filter panels
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options = {} }: IpoliciesNamesProps): FetchArgs {
            const localVarPath = `/policies/names/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (active_at !== undefined) {
                localVarQueryParameter['active_at'] = active_at;
            }

            if (kind) {
                localVarQueryParameter['kind'] = kind;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPartialUpdate({ id, data, options = {} }: IpoliciesPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling policiesPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling policiesPartialUpdate.');
            }
            const localVarPath = `/policies/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolicyInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesRead({ id, options = {} }: IpoliciesReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling policiesRead.');
            }
            const localVarPath = `/policies/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesUpdate({ id, data, options = {} }: IpoliciesUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling policiesUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling policiesUpdate.');
            }
            const localVarPath = `/policies/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolicyInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesApi - functional programming interface
 * @export
 */
export const PoliciesApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        policiesCreateURL({ data, options }: IpoliciesCreateProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesCreate({ data, options });
        },

        /**
         * 
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesCreate({ data, options }: IpoliciesCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.policiesCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesDeleteURL({ id, options }: IpoliciesDeleteProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesDelete({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesDelete({ id, options }: IpoliciesDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.policiesDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesExistURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExistProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
        },

        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExistProps): (fetch?: FetchAPI, basePath?: string) => Promise<ExistPolicyResponse> {
            const localVarFetchArgs = this.policiesExistURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesExportURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExportProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
        },

        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LightPolicy>> {
            const localVarFetchArgs = this.policiesExportURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesListURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
        },

        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<PolicyResponse> {
            const localVarFetchArgs = this.policiesListURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesListLightURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListLightProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
        },

        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListLightProps): (fetch?: FetchAPI, basePath?: string) => Promise<LightPolicyResponse> {
            const localVarFetchArgs = this.policiesListLightURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesNamesURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesNamesProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
        },

        /**
         * Used to return the policy names for the front filter panels
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesNamesProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PolicyName>> {
            const localVarFetchArgs = this.policiesNamesURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesPartialUpdateURL({ id, data, options }: IpoliciesPartialUpdateProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPartialUpdate({ id, data, options }: IpoliciesPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.policiesPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesReadURL({ id, options }: IpoliciesReadProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesRead({ id, options }: IpoliciesReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Policy> {
            const localVarFetchArgs = this.policiesReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        policiesUpdateURL({ id, data, options }: IpoliciesUpdateProps): FetchArgs {
            return PoliciesApiFetchParamCreator(configuration).policiesUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesUpdate({ id, data, options }: IpoliciesUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.policiesUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * PoliciesApi - factory interface
 * @export
 */
export const PoliciesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesCreate({ data, options }: IpoliciesCreateProps) {
            return PoliciesApiFp(configuration).policiesCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesDelete({ id, options }: IpoliciesDeleteProps) {
            return PoliciesApiFp(configuration).policiesDelete({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExistProps) {
            return PoliciesApiFp(configuration).policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExportProps) {
            return PoliciesApiFp(configuration).policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListProps) {
            return PoliciesApiFp(configuration).policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListLightProps) {
            return PoliciesApiFp(configuration).policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(fetch, basePath);
        },
        /**
         * Used to return the policy names for the front filter panels
         * @param {boolean} [published] 
         * @param {string} [active_at] 
         * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
         * @param {Array<string>} [providers] 
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [name] 
         * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesNamesProps) {
            return PoliciesApiFp(configuration).policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPartialUpdate({ id, data, options }: IpoliciesPartialUpdateProps) {
            return PoliciesApiFp(configuration).policiesPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesRead({ id, options }: IpoliciesReadProps) {
            return PoliciesApiFp(configuration).policiesRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this policy.
         * @param {PolicyInput} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesUpdate({ id, data, options }: IpoliciesUpdateProps) {
            return PoliciesApiFp(configuration).policiesUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * PoliciesApi - object-oriented interface
 * @export
 * @class PoliciesApi
 * @extends {BaseAPI}
 */
export class PoliciesApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesCreate`` for arguments and options.
     *
     */
    public policiesCreateURL({ data, options }: IpoliciesCreateProps): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesCreateURL({ data, options });
    }

    /**
     * 
     * @param {PolicyInput} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesCreate({ data, options }: IpoliciesCreateProps) {
        return PoliciesApiFp(this.configuration).policiesCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesDelete`` for arguments and options.
     *
     */
    public policiesDeleteURL({ id, options }: IpoliciesDeleteProps): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesDeleteURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this policy.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesDelete({ id, options }: IpoliciesDeleteProps) {
        return PoliciesApiFp(this.configuration).policiesDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesExist`` for arguments and options.
     *
     */
    public policiesExistURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExistProps = {}): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesExistURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
    }

    /**
     * 
     * @param {boolean} [published] 
     * @param {string} [active_at] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
     * @param {Array<string>} [providers] 
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [name] 
     * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExistProps = {}) {
        return PoliciesApiFp(this.configuration).policiesExist({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesExport`` for arguments and options.
     *
     */
    public policiesExportURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExportProps = {}): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesExportURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
    }

    /**
     * 
     * @param {boolean} [published] 
     * @param {string} [active_at] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
     * @param {Array<string>} [providers] 
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [name] 
     * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesExportProps = {}) {
        return PoliciesApiFp(this.configuration).policiesExport({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesList`` for arguments and options.
     *
     */
    public policiesListURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListProps = {}): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesListURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
    }

    /**
     * 
     * @param {boolean} [published] 
     * @param {string} [active_at] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
     * @param {Array<string>} [providers] 
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [name] 
     * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListProps = {}) {
        return PoliciesApiFp(this.configuration).policiesList({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesListLight`` for arguments and options.
     *
     */
    public policiesListLightURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListLightProps = {}): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesListLightURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
    }

    /**
     * 
     * @param {boolean} [published] 
     * @param {string} [active_at] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
     * @param {Array<string>} [providers] 
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [name] 
     * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesListLightProps = {}) {
        return PoliciesApiFp(this.configuration).policiesListLight({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesNames`` for arguments and options.
     *
     */
    public policiesNamesURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesNamesProps = {}): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesNamesURL({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options });
    }

    /**
     * Used to return the policy names for the front filter panels
     * @param {boolean} [published] 
     * @param {string} [active_at] 
     * @param {Array<'speed' | 'geofence' | 'absolute_fleet_size' | 'permit' | 'parking' | 'trip_taxation' | 'abandoned' | 'balancing' | 'sidewalk' | 'time' | 'user'>} [kind] 
     * @param {Array<string>} [providers] 
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [name] 
     * @param {Array<'draft' | 'upcoming' | 'ongoing' | 'finished'>} [status] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options }: IpoliciesNamesProps = {}) {
        return PoliciesApiFp(this.configuration).policiesNames({ published, active_at, kind, providers, tz, lang, name, status, limit, offset, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesPartialUpdate`` for arguments and options.
     *
     */
    public policiesPartialUpdateURL({ id, data, options }: IpoliciesPartialUpdateProps): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this policy.
     * @param {PolicyInput} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesPartialUpdate({ id, data, options }: IpoliciesPartialUpdateProps) {
        return PoliciesApiFp(this.configuration).policiesPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesRead`` for arguments and options.
     *
     */
    public policiesReadURL({ id, options }: IpoliciesReadProps): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this policy.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesRead({ id, options }: IpoliciesReadProps) {
        return PoliciesApiFp(this.configuration).policiesRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``policiesUpdate`` for arguments and options.
     *
     */
    public policiesUpdateURL({ id, data, options }: IpoliciesUpdateProps): FetchArgs {
        return PoliciesApiFp(this.configuration).policiesUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this policy.
     * @param {PolicyInput} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesUpdate({ id, data, options }: IpoliciesUpdateProps) {
        return PoliciesApiFp(this.configuration).policiesUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * polygonsCreate parameters
 */
export interface IpolygonsCreateProps {
    data: PolygonRequest,
    options?: any,
};

/**
 * polygonsDelete parameters
 */
export interface IpolygonsDeleteProps {
    id: string, // A UUID string identifying this polygon.
    options?: any,
};

/**
 * polygonsDeleteListPolygon parameters
 */
export interface IpolygonsDeleteListPolygonProps {
    data: DeleteListPolygonRequest,
    options?: any,
};

/**
 * polygonsImportPolygons parameters
 */
export interface IpolygonsImportPolygonsProps {
    data: PolygonsImportRequest,
    options?: any,
};

/**
 * polygonsLightList parameters
 */
export interface IpolygonsLightListProps {
    ordering?: string, // Which field to use when ordering the results.
    options?: any,
};

/**
 * polygonsList parameters
 */
export interface IpolygonsListProps {
    ordering?: string, // Which field to use when ordering the results.
    options?: any,
};

/**
 * polygonsPartialUpdate parameters
 */
export interface IpolygonsPartialUpdateProps {
    id: string, // A UUID string identifying this polygon.
    data: PolygonRequest,
    options?: any,
};

/**
 * polygonsRead parameters
 */
export interface IpolygonsReadProps {
    id: string, // A UUID string identifying this polygon.
    options?: any,
};

/**
 * polygonsUpdate parameters
 */
export interface IpolygonsUpdateProps {
    id: string, // A UUID string identifying this polygon.
    data: PolygonRequest,
    options?: any,
};

/**
 * PolygonsApi - fetch parameter creator
 * @export
 */
export const PolygonsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsCreate({ data, options = {} }: IpolygonsCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling polygonsCreate.');
            }
            const localVarPath = `/polygons/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolygonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDelete({ id, options = {} }: IpolygonsDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling polygonsDelete.');
            }
            const localVarPath = `/polygons/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteListPolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDeleteListPolygon({ data, options = {} }: IpolygonsDeleteListPolygonProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling polygonsDeleteListPolygon.');
            }
            const localVarPath = `/polygons/delete_list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteListPolygonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PolygonsImportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsImportPolygons({ data, options = {} }: IpolygonsImportPolygonsProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling polygonsImportPolygons.');
            }
            const localVarPath = `/polygons/import/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolygonsImportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsLightList({ ordering, options = {} }: IpolygonsLightListProps): FetchArgs {
            const localVarPath = `/polygons/light_list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsList({ ordering, options = {} }: IpolygonsListProps): FetchArgs {
            const localVarPath = `/polygons/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsPartialUpdate({ id, data, options = {} }: IpolygonsPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling polygonsPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling polygonsPartialUpdate.');
            }
            const localVarPath = `/polygons/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolygonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsRead({ id, options = {} }: IpolygonsReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling polygonsRead.');
            }
            const localVarPath = `/polygons/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsUpdate({ id, data, options = {} }: IpolygonsUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling polygonsUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling polygonsUpdate.');
            }
            const localVarPath = `/polygons/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PolygonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolygonsApi - functional programming interface
 * @export
 */
export const PolygonsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        polygonsCreateURL({ data, options }: IpolygonsCreateProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsCreate({ data, options });
        },

        /**
         * 
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsCreate({ data, options }: IpolygonsCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.polygonsCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsDeleteURL({ id, options }: IpolygonsDeleteProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsDelete({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDelete({ id, options }: IpolygonsDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.polygonsDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsDeleteListPolygonURL({ data, options }: IpolygonsDeleteListPolygonProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsDeleteListPolygon({ data, options });
        },

        /**
         * 
         * @param {DeleteListPolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDeleteListPolygon({ data, options }: IpolygonsDeleteListPolygonProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.polygonsDeleteListPolygonURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsImportPolygonsURL({ data, options }: IpolygonsImportPolygonsProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsImportPolygons({ data, options });
        },

        /**
         * 
         * @param {PolygonsImportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsImportPolygons({ data, options }: IpolygonsImportPolygonsProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.polygonsImportPolygonsURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsLightListURL({ ordering, options }: IpolygonsLightListProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsLightList({ ordering, options });
        },

        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsLightList({ ordering, options }: IpolygonsLightListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LightPolygon>> {
            const localVarFetchArgs = this.polygonsLightListURL({ ordering, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsListURL({ ordering, options }: IpolygonsListProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsList({ ordering, options });
        },

        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsList({ ordering, options }: IpolygonsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PolygonResponse>> {
            const localVarFetchArgs = this.polygonsListURL({ ordering, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsPartialUpdateURL({ id, data, options }: IpolygonsPartialUpdateProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsPartialUpdate({ id, data, options }: IpolygonsPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.polygonsPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsReadURL({ id, options }: IpolygonsReadProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsRead({ id, options }: IpolygonsReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<PolygonResponse> {
            const localVarFetchArgs = this.polygonsReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        polygonsUpdateURL({ id, data, options }: IpolygonsUpdateProps): FetchArgs {
            return PolygonsApiFetchParamCreator(configuration).polygonsUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsUpdate({ id, data, options }: IpolygonsUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.polygonsUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * PolygonsApi - factory interface
 * @export
 */
export const PolygonsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsCreate({ data, options }: IpolygonsCreateProps) {
            return PolygonsApiFp(configuration).polygonsCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDelete({ id, options }: IpolygonsDeleteProps) {
            return PolygonsApiFp(configuration).polygonsDelete({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {DeleteListPolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsDeleteListPolygon({ data, options }: IpolygonsDeleteListPolygonProps) {
            return PolygonsApiFp(configuration).polygonsDeleteListPolygon({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {PolygonsImportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsImportPolygons({ data, options }: IpolygonsImportPolygonsProps) {
            return PolygonsApiFp(configuration).polygonsImportPolygons({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsLightList({ ordering, options }: IpolygonsLightListProps) {
            return PolygonsApiFp(configuration).polygonsLightList({ ordering, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsList({ ordering, options }: IpolygonsListProps) {
            return PolygonsApiFp(configuration).polygonsList({ ordering, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsPartialUpdate({ id, data, options }: IpolygonsPartialUpdateProps) {
            return PolygonsApiFp(configuration).polygonsPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsRead({ id, options }: IpolygonsReadProps) {
            return PolygonsApiFp(configuration).polygonsRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this polygon.
         * @param {PolygonRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polygonsUpdate({ id, data, options }: IpolygonsUpdateProps) {
            return PolygonsApiFp(configuration).polygonsUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * PolygonsApi - object-oriented interface
 * @export
 * @class PolygonsApi
 * @extends {BaseAPI}
 */
export class PolygonsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsCreate`` for arguments and options.
     *
     */
    public polygonsCreateURL({ data, options }: IpolygonsCreateProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsCreateURL({ data, options });
    }

    /**
     * 
     * @param {PolygonRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsCreate({ data, options }: IpolygonsCreateProps) {
        return PolygonsApiFp(this.configuration).polygonsCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsDelete`` for arguments and options.
     *
     */
    public polygonsDeleteURL({ id, options }: IpolygonsDeleteProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsDeleteURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this polygon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsDelete({ id, options }: IpolygonsDeleteProps) {
        return PolygonsApiFp(this.configuration).polygonsDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsDeleteListPolygon`` for arguments and options.
     *
     */
    public polygonsDeleteListPolygonURL({ data, options }: IpolygonsDeleteListPolygonProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsDeleteListPolygonURL({ data, options });
    }

    /**
     * 
     * @param {DeleteListPolygonRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsDeleteListPolygon({ data, options }: IpolygonsDeleteListPolygonProps) {
        return PolygonsApiFp(this.configuration).polygonsDeleteListPolygon({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsImportPolygons`` for arguments and options.
     *
     */
    public polygonsImportPolygonsURL({ data, options }: IpolygonsImportPolygonsProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsImportPolygonsURL({ data, options });
    }

    /**
     * 
     * @param {PolygonsImportRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsImportPolygons({ data, options }: IpolygonsImportPolygonsProps) {
        return PolygonsApiFp(this.configuration).polygonsImportPolygons({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsLightList`` for arguments and options.
     *
     */
    public polygonsLightListURL({ ordering, options }: IpolygonsLightListProps = {}): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsLightListURL({ ordering, options });
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsLightList({ ordering, options }: IpolygonsLightListProps = {}) {
        return PolygonsApiFp(this.configuration).polygonsLightList({ ordering, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsList`` for arguments and options.
     *
     */
    public polygonsListURL({ ordering, options }: IpolygonsListProps = {}): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsListURL({ ordering, options });
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsList({ ordering, options }: IpolygonsListProps = {}) {
        return PolygonsApiFp(this.configuration).polygonsList({ ordering, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsPartialUpdate`` for arguments and options.
     *
     */
    public polygonsPartialUpdateURL({ id, data, options }: IpolygonsPartialUpdateProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this polygon.
     * @param {PolygonRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsPartialUpdate({ id, data, options }: IpolygonsPartialUpdateProps) {
        return PolygonsApiFp(this.configuration).polygonsPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsRead`` for arguments and options.
     *
     */
    public polygonsReadURL({ id, options }: IpolygonsReadProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this polygon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsRead({ id, options }: IpolygonsReadProps) {
        return PolygonsApiFp(this.configuration).polygonsRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``polygonsUpdate`` for arguments and options.
     *
     */
    public polygonsUpdateURL({ id, data, options }: IpolygonsUpdateProps): FetchArgs {
        return PolygonsApiFp(this.configuration).polygonsUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this polygon.
     * @param {PolygonRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolygonsApi
     */
    public polygonsUpdate({ id, data, options }: IpolygonsUpdateProps) {
        return PolygonsApiFp(this.configuration).polygonsUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * providersCreate parameters
 */
export interface IprovidersCreateProps {
    data: Provider,
    options?: any,
};

/**
 * providersDelete parameters
 */
export interface IprovidersDeleteProps {
    id: string, // A UUID string identifying this provider.
    options?: any,
};

/**
 * providersList parameters
 */
export interface IprovidersListProps {
    is_active?: boolean, // 
    options?: any,
};

/**
 * providersPartialUpdate parameters
 */
export interface IprovidersPartialUpdateProps {
    id: string, // A UUID string identifying this provider.
    data: Provider,
    options?: any,
};

/**
 * providersRead parameters
 */
export interface IprovidersReadProps {
    id: string, // A UUID string identifying this provider.
    options?: any,
};

/**
 * providersUpdate parameters
 */
export interface IprovidersUpdateProps {
    id: string, // A UUID string identifying this provider.
    data: Provider,
    options?: any,
};

/**
 * ProvidersApi - fetch parameter creator
 * @export
 */
export const ProvidersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersCreate({ data, options = {} }: IprovidersCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling providersCreate.');
            }
            const localVarPath = `/providers/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Provider" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersDelete({ id, options = {} }: IprovidersDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling providersDelete.');
            }
            const localVarPath = `/providers/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [is_active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersList({ is_active, options = {} }: IprovidersListProps): FetchArgs {
            const localVarPath = `/providers/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (is_active !== undefined) {
                localVarQueryParameter['is_active'] = is_active;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPartialUpdate({ id, data, options = {} }: IprovidersPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling providersPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling providersPartialUpdate.');
            }
            const localVarPath = `/providers/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Provider" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersRead({ id, options = {} }: IprovidersReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling providersRead.');
            }
            const localVarPath = `/providers/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersUpdate({ id, data, options = {} }: IprovidersUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling providersUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling providersUpdate.');
            }
            const localVarPath = `/providers/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Provider" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        providersCreateURL({ data, options }: IprovidersCreateProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersCreate({ data, options });
        },

        /**
         * 
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersCreate({ data, options }: IprovidersCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Provider> {
            const localVarFetchArgs = this.providersCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providersDeleteURL({ id, options }: IprovidersDeleteProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersDelete({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersDelete({ id, options }: IprovidersDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.providersDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providersListURL({ is_active, options }: IprovidersListProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersList({ is_active, options });
        },

        /**
         * 
         * @param {boolean} [is_active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersList({ is_active, options }: IprovidersListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Provider>> {
            const localVarFetchArgs = this.providersListURL({ is_active, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providersPartialUpdateURL({ id, data, options }: IprovidersPartialUpdateProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPartialUpdate({ id, data, options }: IprovidersPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Provider> {
            const localVarFetchArgs = this.providersPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providersReadURL({ id, options }: IprovidersReadProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersRead({ id, options }: IprovidersReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Provider> {
            const localVarFetchArgs = this.providersReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        providersUpdateURL({ id, data, options }: IprovidersUpdateProps): FetchArgs {
            return ProvidersApiFetchParamCreator(configuration).providersUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersUpdate({ id, data, options }: IprovidersUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<Provider> {
            const localVarFetchArgs = this.providersUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersCreate({ data, options }: IprovidersCreateProps) {
            return ProvidersApiFp(configuration).providersCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersDelete({ id, options }: IprovidersDeleteProps) {
            return ProvidersApiFp(configuration).providersDelete({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [is_active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersList({ is_active, options }: IprovidersListProps) {
            return ProvidersApiFp(configuration).providersList({ is_active, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPartialUpdate({ id, data, options }: IprovidersPartialUpdateProps) {
            return ProvidersApiFp(configuration).providersPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersRead({ id, options }: IprovidersReadProps) {
            return ProvidersApiFp(configuration).providersRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this provider.
         * @param {Provider} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersUpdate({ id, data, options }: IprovidersUpdateProps) {
            return ProvidersApiFp(configuration).providersUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``providersCreate`` for arguments and options.
     *
     */
    public providersCreateURL({ data, options }: IprovidersCreateProps): FetchArgs {
        return ProvidersApiFp(this.configuration).providersCreateURL({ data, options });
    }

    /**
     * 
     * @param {Provider} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersCreate({ data, options }: IprovidersCreateProps) {
        return ProvidersApiFp(this.configuration).providersCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providersDelete`` for arguments and options.
     *
     */
    public providersDeleteURL({ id, options }: IprovidersDeleteProps): FetchArgs {
        return ProvidersApiFp(this.configuration).providersDeleteURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this provider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersDelete({ id, options }: IprovidersDeleteProps) {
        return ProvidersApiFp(this.configuration).providersDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providersList`` for arguments and options.
     *
     */
    public providersListURL({ is_active, options }: IprovidersListProps = {}): FetchArgs {
        return ProvidersApiFp(this.configuration).providersListURL({ is_active, options });
    }

    /**
     * 
     * @param {boolean} [is_active] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersList({ is_active, options }: IprovidersListProps = {}) {
        return ProvidersApiFp(this.configuration).providersList({ is_active, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providersPartialUpdate`` for arguments and options.
     *
     */
    public providersPartialUpdateURL({ id, data, options }: IprovidersPartialUpdateProps): FetchArgs {
        return ProvidersApiFp(this.configuration).providersPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this provider.
     * @param {Provider} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersPartialUpdate({ id, data, options }: IprovidersPartialUpdateProps) {
        return ProvidersApiFp(this.configuration).providersPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providersRead`` for arguments and options.
     *
     */
    public providersReadURL({ id, options }: IprovidersReadProps): FetchArgs {
        return ProvidersApiFp(this.configuration).providersReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this provider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersRead({ id, options }: IprovidersReadProps) {
        return ProvidersApiFp(this.configuration).providersRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``providersUpdate`` for arguments and options.
     *
     */
    public providersUpdateURL({ id, data, options }: IprovidersUpdateProps): FetchArgs {
        return ProvidersApiFp(this.configuration).providersUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this provider.
     * @param {Provider} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providersUpdate({ id, data, options }: IprovidersUpdateProps) {
        return ProvidersApiFp(this.configuration).providersUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * providersPermitsList parameters
 */
export interface IprovidersPermitsListProps {
    options?: any,
};

/**
 * ProvidersPermitsApi - fetch parameter creator
 * @export
 */
export const ProvidersPermitsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return a summary of permit policies per provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPermitsList({ options = {} }: IprovidersPermitsListProps): FetchArgs {
            const localVarPath = `/providers_permits/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersPermitsApi - functional programming interface
 * @export
 */
export const ProvidersPermitsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        providersPermitsListURL({ options }: IprovidersPermitsListProps): FetchArgs {
            return ProvidersPermitsApiFetchParamCreator(configuration).providersPermitsList({ options });
        },

        /**
         * Return a summary of permit policies per provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPermitsList({ options }: IprovidersPermitsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.providersPermitsListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * ProvidersPermitsApi - factory interface
 * @export
 */
export const ProvidersPermitsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Return a summary of permit policies per provider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providersPermitsList({ options }: IprovidersPermitsListProps) {
            return ProvidersPermitsApiFp(configuration).providersPermitsList({ options })(fetch, basePath);
        },
    };
};

/**
 * ProvidersPermitsApi - object-oriented interface
 * @export
 * @class ProvidersPermitsApi
 * @extends {BaseAPI}
 */
export class ProvidersPermitsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``providersPermitsList`` for arguments and options.
     *
     */
    public providersPermitsListURL({ options }: IprovidersPermitsListProps = {}): FetchArgs {
        return ProvidersPermitsApiFp(this.configuration).providersPermitsListURL({ options });
    }

    /**
     * Return a summary of permit policies per provider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersPermitsApi
     */
    public providersPermitsList({ options }: IprovidersPermitsListProps = {}) {
        return ProvidersPermitsApiFp(this.configuration).providersPermitsList({ options })(this.fetch, this.basePath);
    }

}

/**
 * reportsCreate parameters
 */
export interface IreportsCreateProps {
    data: CreateReport,
    options?: any,
};

/**
 * reportsDelete parameters
 */
export interface IreportsDeleteProps {
    id: number, // A unique integer value identifying this report.
    options?: any,
};

/**
 * reportsExport parameters
 */
export interface IreportsExportProps {
    id: number, // A unique integer value identifying this report.
    tz?: string,
    lang?: string,
    granularity?: string,
    options?: any,
};

/**
 * reportsList parameters
 */
export interface IreportsListProps {
    kind?: 'area_flow' | 'historical_map', // 
    created_by?: Array<string>, // 
    name?: string, // 
    type?: Array<'area_flow' | 'historical_map'>, // 
    options?: any,
};

/**
 * reportsPartialUpdate parameters
 */
export interface IreportsPartialUpdateProps {
    id: number, // A unique integer value identifying this report.
    data: UpdateReport,
    options?: any,
};

/**
 * reportsRead parameters
 */
export interface IreportsReadProps {
    id: number, // A unique integer value identifying this report.
    options?: any,
};

/**
 * reportsUpdate parameters
 */
export interface IreportsUpdateProps {
    id: number, // A unique integer value identifying this report.
    data: UpdateReport,
    options?: any,
};

/**
 * ReportsApi - fetch parameter creator
 * @export
 */
export const ReportsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCreate({ data, options = {} }: IreportsCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling reportsCreate.');
            }
            const localVarPath = `/reports/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsDelete({ id, options = {} }: IreportsDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsDelete.');
            }
            const localVarPath = `/reports/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [granularity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsExport({ id, tz, lang, granularity, options = {} }: IreportsExportProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsExport.');
            }
            const localVarPath = `/reports/{id}/export/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'area_flow' | 'historical_map'} [kind] 
         * @param {Array<string>} [created_by] 
         * @param {string} [name] 
         * @param {Array<'area_flow' | 'historical_map'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsList({ kind, created_by, name, type, options = {} }: IreportsListProps): FetchArgs {
            const localVarPath = `/reports/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (created_by) {
                localVarQueryParameter['created_by'] = created_by;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPartialUpdate({ id, data, options = {} }: IreportsPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling reportsPartialUpdate.');
            }
            const localVarPath = `/reports/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsRead({ id, options = {} }: IreportsReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsRead.');
            }
            const localVarPath = `/reports/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdate({ id, data, options = {} }: IreportsUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling reportsUpdate.');
            }
            const localVarPath = `/reports/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        reportsCreateURL({ data, options }: IreportsCreateProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsCreate({ data, options });
        },

        /**
         * 
         * @param {CreateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCreate({ data, options }: IreportsCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.reportsCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsDeleteURL({ id, options }: IreportsDeleteProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsDelete({ id, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsDelete({ id, options }: IreportsDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.reportsDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsExportURL({ id, tz, lang, granularity, options }: IreportsExportProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsExport({ id, tz, lang, granularity, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [granularity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsExport({ id, tz, lang, granularity, options }: IreportsExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.reportsExportURL({ id, tz, lang, granularity, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsListURL({ kind, created_by, name, type, options }: IreportsListProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsList({ kind, created_by, name, type, options });
        },

        /**
         * 
         * @param {'area_flow' | 'historical_map'} [kind] 
         * @param {Array<string>} [created_by] 
         * @param {string} [name] 
         * @param {Array<'area_flow' | 'historical_map'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsList({ kind, created_by, name, type, options }: IreportsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ListReport>> {
            const localVarFetchArgs = this.reportsListURL({ kind, created_by, name, type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsPartialUpdateURL({ id, data, options }: IreportsPartialUpdateProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPartialUpdate({ id, data, options }: IreportsPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.reportsPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsReadURL({ id, options }: IreportsReadProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsRead({ id, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsRead({ id, options }: IreportsReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<RetrievedReport> {
            const localVarFetchArgs = this.reportsReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        reportsUpdateURL({ id, data, options }: IreportsUpdateProps): FetchArgs {
            return ReportsApiFetchParamCreator(configuration).reportsUpdate({ id, data, options });
        },

        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdate({ id, data, options }: IreportsUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.reportsUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCreate({ data, options }: IreportsCreateProps) {
            return ReportsApiFp(configuration).reportsCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsDelete({ id, options }: IreportsDeleteProps) {
            return ReportsApiFp(configuration).reportsDelete({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [granularity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsExport({ id, tz, lang, granularity, options }: IreportsExportProps) {
            return ReportsApiFp(configuration).reportsExport({ id, tz, lang, granularity, options })(fetch, basePath);
        },
        /**
         * 
         * @param {'area_flow' | 'historical_map'} [kind] 
         * @param {Array<string>} [created_by] 
         * @param {string} [name] 
         * @param {Array<'area_flow' | 'historical_map'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsList({ kind, created_by, name, type, options }: IreportsListProps) {
            return ReportsApiFp(configuration).reportsList({ kind, created_by, name, type, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPartialUpdate({ id, data, options }: IreportsPartialUpdateProps) {
            return ReportsApiFp(configuration).reportsPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsRead({ id, options }: IreportsReadProps) {
            return ReportsApiFp(configuration).reportsRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this report.
         * @param {UpdateReport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdate({ id, data, options }: IreportsUpdateProps) {
            return ReportsApiFp(configuration).reportsUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsCreate`` for arguments and options.
     *
     */
    public reportsCreateURL({ data, options }: IreportsCreateProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsCreateURL({ data, options });
    }

    /**
     * 
     * @param {CreateReport} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsCreate({ data, options }: IreportsCreateProps) {
        return ReportsApiFp(this.configuration).reportsCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsDelete`` for arguments and options.
     *
     */
    public reportsDeleteURL({ id, options }: IreportsDeleteProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsDeleteURL({ id, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this report.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsDelete({ id, options }: IreportsDeleteProps) {
        return ReportsApiFp(this.configuration).reportsDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsExport`` for arguments and options.
     *
     */
    public reportsExportURL({ id, tz, lang, granularity, options }: IreportsExportProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsExportURL({ id, tz, lang, granularity, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this report.
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [granularity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsExport({ id, tz, lang, granularity, options }: IreportsExportProps) {
        return ReportsApiFp(this.configuration).reportsExport({ id, tz, lang, granularity, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsList`` for arguments and options.
     *
     */
    public reportsListURL({ kind, created_by, name, type, options }: IreportsListProps = {}): FetchArgs {
        return ReportsApiFp(this.configuration).reportsListURL({ kind, created_by, name, type, options });
    }

    /**
     * 
     * @param {'area_flow' | 'historical_map'} [kind] 
     * @param {Array<string>} [created_by] 
     * @param {string} [name] 
     * @param {Array<'area_flow' | 'historical_map'>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsList({ kind, created_by, name, type, options }: IreportsListProps = {}) {
        return ReportsApiFp(this.configuration).reportsList({ kind, created_by, name, type, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsPartialUpdate`` for arguments and options.
     *
     */
    public reportsPartialUpdateURL({ id, data, options }: IreportsPartialUpdateProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this report.
     * @param {UpdateReport} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsPartialUpdate({ id, data, options }: IreportsPartialUpdateProps) {
        return ReportsApiFp(this.configuration).reportsPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsRead`` for arguments and options.
     *
     */
    public reportsReadURL({ id, options }: IreportsReadProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsReadURL({ id, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this report.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsRead({ id, options }: IreportsReadProps) {
        return ReportsApiFp(this.configuration).reportsRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``reportsUpdate`` for arguments and options.
     *
     */
    public reportsUpdateURL({ id, data, options }: IreportsUpdateProps): FetchArgs {
        return ReportsApiFp(this.configuration).reportsUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this report.
     * @param {UpdateReport} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsUpdate({ id, data, options }: IreportsUpdateProps) {
        return ReportsApiFp(this.configuration).reportsUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * serviceAreasCreate parameters
 */
export interface IserviceAreasCreateProps {
    data: AreaRequest,
    options?: any,
};

/**
 * serviceAreasDelete parameters
 */
export interface IserviceAreasDeleteProps {
    id: string, // A UUID string identifying this area.
    options?: any,
};

/**
 * serviceAreasLabels parameters
 */
export interface IserviceAreasLabelsProps {
    ordering?: string, // Which field to use when ordering the results.
    options?: any,
};

/**
 * serviceAreasLightList parameters
 */
export interface IserviceAreasLightListProps {
    ordering?: string, // Which field to use when ordering the results.
    options?: any,
};

/**
 * serviceAreasList parameters
 */
export interface IserviceAreasListProps {
    ordering?: string, // Which field to use when ordering the results.
    options?: any,
};

/**
 * serviceAreasPartialUpdate parameters
 */
export interface IserviceAreasPartialUpdateProps {
    id: string, // A UUID string identifying this area.
    data: AreaRequest,
    options?: any,
};

/**
 * serviceAreasRead parameters
 */
export interface IserviceAreasReadProps {
    id: string, // A UUID string identifying this area.
    options?: any,
};

/**
 * serviceAreasReverse parameters
 */
export interface IserviceAreasReverseProps {
    id: string, // A UUID string identifying this area.
    data: ReverseAreaRequest,
    options?: any,
};

/**
 * serviceAreasUpdate parameters
 */
export interface IserviceAreasUpdateProps {
    id: string, // A UUID string identifying this area.
    data: AreaRequest,
    options?: any,
};

/**
 * ServiceAreasApi - fetch parameter creator
 * @export
 */
export const ServiceAreasApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasCreate({ data, options = {} }: IserviceAreasCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling serviceAreasCreate.');
            }
            const localVarPath = `/service_areas/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AreaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasDelete({ id, options = {} }: IserviceAreasDeleteProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling serviceAreasDelete.');
            }
            const localVarPath = `/service_areas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of area names and id on *all* areas. We want to have all versions since this is used to display a Area label from the policy/Report area_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLabels({ ordering, options = {} }: IserviceAreasLabelsProps): FetchArgs {
            const localVarPath = `/service_areas/labels/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only the columns listed by the frontend using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLightList({ ordering, options = {} }: IserviceAreasLightListProps): FetchArgs {
            const localVarPath = `/service_areas/light_list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Areas using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasList({ ordering, options = {} }: IserviceAreasListProps): FetchArgs {
            const localVarPath = `/service_areas/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasPartialUpdate({ id, data, options = {} }: IserviceAreasPartialUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling serviceAreasPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling serviceAreasPartialUpdate.');
            }
            const localVarPath = `/service_areas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AreaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasRead({ id, options = {} }: IserviceAreasReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling serviceAreasRead.');
            }
            const localVarPath = `/service_areas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {ReverseAreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasReverse({ id, data, options = {} }: IserviceAreasReverseProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling serviceAreasReverse.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling serviceAreasReverse.');
            }
            const localVarPath = `/service_areas/{id}/reverse/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReverseAreaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasUpdate({ id, data, options = {} }: IserviceAreasUpdateProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling serviceAreasUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling serviceAreasUpdate.');
            }
            const localVarPath = `/service_areas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AreaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceAreasApi - functional programming interface
 * @export
 */
export const ServiceAreasApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        serviceAreasCreateURL({ data, options }: IserviceAreasCreateProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasCreate({ data, options });
        },

        /**
         * 
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasCreate({ data, options }: IserviceAreasCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.serviceAreasCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasDeleteURL({ id, options }: IserviceAreasDeleteProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasDelete({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasDelete({ id, options }: IserviceAreasDeleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.serviceAreasDeleteURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasLabelsURL({ ordering, options }: IserviceAreasLabelsProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasLabels({ ordering, options });
        },

        /**
         * Fetch a list of area names and id on *all* areas. We want to have all versions since this is used to display a Area label from the policy/Report area_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLabels({ ordering, options }: IserviceAreasLabelsProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AreaLabelResponse>> {
            const localVarFetchArgs = this.serviceAreasLabelsURL({ ordering, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasLightListURL({ ordering, options }: IserviceAreasLightListProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasLightList({ ordering, options });
        },

        /**
         * Only the columns listed by the frontend using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLightList({ ordering, options }: IserviceAreasLightListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LightArea>> {
            const localVarFetchArgs = this.serviceAreasLightListURL({ ordering, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasListURL({ ordering, options }: IserviceAreasListProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasList({ ordering, options });
        },

        /**
         * List Areas using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasList({ ordering, options }: IserviceAreasListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AreaResponse>> {
            const localVarFetchArgs = this.serviceAreasListURL({ ordering, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasPartialUpdateURL({ id, data, options }: IserviceAreasPartialUpdateProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasPartialUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasPartialUpdate({ id, data, options }: IserviceAreasPartialUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.serviceAreasPartialUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasReadURL({ id, options }: IserviceAreasReadProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasRead({ id, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasRead({ id, options }: IserviceAreasReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<AreaResponse> {
            const localVarFetchArgs = this.serviceAreasReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasReverseURL({ id, data, options }: IserviceAreasReverseProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasReverse({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {ReverseAreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasReverse({ id, data, options }: IserviceAreasReverseProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.serviceAreasReverseURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        serviceAreasUpdateURL({ id, data, options }: IserviceAreasUpdateProps): FetchArgs {
            return ServiceAreasApiFetchParamCreator(configuration).serviceAreasUpdate({ id, data, options });
        },

        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasUpdate({ id, data, options }: IserviceAreasUpdateProps): (fetch?: FetchAPI, basePath?: string) => Promise<EmptyResponse> {
            const localVarFetchArgs = this.serviceAreasUpdateURL({ id, data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * ServiceAreasApi - factory interface
 * @export
 */
export const ServiceAreasApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasCreate({ data, options }: IserviceAreasCreateProps) {
            return ServiceAreasApiFp(configuration).serviceAreasCreate({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasDelete({ id, options }: IserviceAreasDeleteProps) {
            return ServiceAreasApiFp(configuration).serviceAreasDelete({ id, options })(fetch, basePath);
        },
        /**
         * Fetch a list of area names and id on *all* areas. We want to have all versions since this is used to display a Area label from the policy/Report area_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLabels({ ordering, options }: IserviceAreasLabelsProps) {
            return ServiceAreasApiFp(configuration).serviceAreasLabels({ ordering, options })(fetch, basePath);
        },
        /**
         * Only the columns listed by the frontend using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasLightList({ ordering, options }: IserviceAreasLightListProps) {
            return ServiceAreasApiFp(configuration).serviceAreasLightList({ ordering, options })(fetch, basePath);
        },
        /**
         * List Areas using the latest_versions manager.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasList({ ordering, options }: IserviceAreasListProps) {
            return ServiceAreasApiFp(configuration).serviceAreasList({ ordering, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasPartialUpdate({ id, data, options }: IserviceAreasPartialUpdateProps) {
            return ServiceAreasApiFp(configuration).serviceAreasPartialUpdate({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasRead({ id, options }: IserviceAreasReadProps) {
            return ServiceAreasApiFp(configuration).serviceAreasRead({ id, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {ReverseAreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasReverse({ id, data, options }: IserviceAreasReverseProps) {
            return ServiceAreasApiFp(configuration).serviceAreasReverse({ id, data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this area.
         * @param {AreaRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAreasUpdate({ id, data, options }: IserviceAreasUpdateProps) {
            return ServiceAreasApiFp(configuration).serviceAreasUpdate({ id, data, options })(fetch, basePath);
        },
    };
};

/**
 * ServiceAreasApi - object-oriented interface
 * @export
 * @class ServiceAreasApi
 * @extends {BaseAPI}
 */
export class ServiceAreasApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasCreate`` for arguments and options.
     *
     */
    public serviceAreasCreateURL({ data, options }: IserviceAreasCreateProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasCreateURL({ data, options });
    }

    /**
     * 
     * @param {AreaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasCreate({ data, options }: IserviceAreasCreateProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasCreate({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasDelete`` for arguments and options.
     *
     */
    public serviceAreasDeleteURL({ id, options }: IserviceAreasDeleteProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasDeleteURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this area.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasDelete({ id, options }: IserviceAreasDeleteProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasDelete({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasLabels`` for arguments and options.
     *
     */
    public serviceAreasLabelsURL({ ordering, options }: IserviceAreasLabelsProps = {}): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasLabelsURL({ ordering, options });
    }

    /**
     * Fetch a list of area names and id on *all* areas. We want to have all versions since this is used to display a Area label from the policy/Report area_id
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasLabels({ ordering, options }: IserviceAreasLabelsProps = {}) {
        return ServiceAreasApiFp(this.configuration).serviceAreasLabels({ ordering, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasLightList`` for arguments and options.
     *
     */
    public serviceAreasLightListURL({ ordering, options }: IserviceAreasLightListProps = {}): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasLightListURL({ ordering, options });
    }

    /**
     * Only the columns listed by the frontend using the latest_versions manager.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasLightList({ ordering, options }: IserviceAreasLightListProps = {}) {
        return ServiceAreasApiFp(this.configuration).serviceAreasLightList({ ordering, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasList`` for arguments and options.
     *
     */
    public serviceAreasListURL({ ordering, options }: IserviceAreasListProps = {}): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasListURL({ ordering, options });
    }

    /**
     * List Areas using the latest_versions manager.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasList({ ordering, options }: IserviceAreasListProps = {}) {
        return ServiceAreasApiFp(this.configuration).serviceAreasList({ ordering, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasPartialUpdate`` for arguments and options.
     *
     */
    public serviceAreasPartialUpdateURL({ id, data, options }: IserviceAreasPartialUpdateProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasPartialUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this area.
     * @param {AreaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasPartialUpdate({ id, data, options }: IserviceAreasPartialUpdateProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasPartialUpdate({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasRead`` for arguments and options.
     *
     */
    public serviceAreasReadURL({ id, options }: IserviceAreasReadProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this area.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasRead({ id, options }: IserviceAreasReadProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasRead({ id, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasReverse`` for arguments and options.
     *
     */
    public serviceAreasReverseURL({ id, data, options }: IserviceAreasReverseProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasReverseURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this area.
     * @param {ReverseAreaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasReverse({ id, data, options }: IserviceAreasReverseProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasReverse({ id, data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``serviceAreasUpdate`` for arguments and options.
     *
     */
    public serviceAreasUpdateURL({ id, data, options }: IserviceAreasUpdateProps): FetchArgs {
        return ServiceAreasApiFp(this.configuration).serviceAreasUpdateURL({ id, data, options });
    }

    /**
     * 
     * @param {string} id A UUID string identifying this area.
     * @param {AreaRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAreasApi
     */
    public serviceAreasUpdate({ id, data, options }: IserviceAreasUpdateProps) {
        return ServiceAreasApiFp(this.configuration).serviceAreasUpdate({ id, data, options })(this.fetch, this.basePath);
    }

}

/**
 * stopsExport parameters
 */
export interface IstopsExportProps {
    options?: any,
};

/**
 * stopsList parameters
 */
export interface IstopsListProps {
    options?: any,
};

/**
 * stopsRead parameters
 */
export interface IstopsReadProps {
    uid: string, // A UUID string identifying this stops.
    options?: any,
};

/**
 * StopsApi - fetch parameter creator
 * @export
 */
export const StopsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsExport({ options = {} }: IstopsExportProps): FetchArgs {
            const localVarPath = `/stops/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsList({ options = {} }: IstopsListProps): FetchArgs {
            const localVarPath = `/stops/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsRead({ uid, options = {} }: IstopsReadProps): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling stopsRead.');
            }
            const localVarPath = `/stops/{uid}/`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StopsApi - functional programming interface
 * @export
 */
export const StopsApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        stopsExportURL({ options }: IstopsExportProps): FetchArgs {
            return StopsApiFetchParamCreator(configuration).stopsExport({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsExport({ options }: IstopsExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<StopsResponse> {
            const localVarFetchArgs = this.stopsExportURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        stopsListURL({ options }: IstopsListProps): FetchArgs {
            return StopsApiFetchParamCreator(configuration).stopsList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsList({ options }: IstopsListProps): (fetch?: FetchAPI, basePath?: string) => Promise<StopsResponse> {
            const localVarFetchArgs = this.stopsListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        stopsReadURL({ uid, options }: IstopsReadProps): FetchArgs {
            return StopsApiFetchParamCreator(configuration).stopsRead({ uid, options });
        },

        /**
         * 
         * @param {string} uid A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsRead({ uid, options }: IstopsReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = this.stopsReadURL({ uid, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * StopsApi - factory interface
 * @export
 */
export const StopsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsExport({ options }: IstopsExportProps) {
            return StopsApiFp(configuration).stopsExport({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsList({ options }: IstopsListProps) {
            return StopsApiFp(configuration).stopsList({ options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} uid A UUID string identifying this stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopsRead({ uid, options }: IstopsReadProps) {
            return StopsApiFp(configuration).stopsRead({ uid, options })(fetch, basePath);
        },
    };
};

/**
 * StopsApi - object-oriented interface
 * @export
 * @class StopsApi
 * @extends {BaseAPI}
 */
export class StopsApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``stopsExport`` for arguments and options.
     *
     */
    public stopsExportURL({ options }: IstopsExportProps = {}): FetchArgs {
        return StopsApiFp(this.configuration).stopsExportURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StopsApi
     */
    public stopsExport({ options }: IstopsExportProps = {}) {
        return StopsApiFp(this.configuration).stopsExport({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``stopsList`` for arguments and options.
     *
     */
    public stopsListURL({ options }: IstopsListProps = {}): FetchArgs {
        return StopsApiFp(this.configuration).stopsListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StopsApi
     */
    public stopsList({ options }: IstopsListProps = {}) {
        return StopsApiFp(this.configuration).stopsList({ options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``stopsRead`` for arguments and options.
     *
     */
    public stopsReadURL({ uid, options }: IstopsReadProps): FetchArgs {
        return StopsApiFp(this.configuration).stopsReadURL({ uid, options });
    }

    /**
     * 
     * @param {string} uid A UUID string identifying this stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StopsApi
     */
    public stopsRead({ uid, options }: IstopsReadProps) {
        return StopsApiFp(this.configuration).stopsRead({ uid, options })(this.fetch, this.basePath);
    }

}

/**
 * tripCountExportInStreetSegments parameters
 */
export interface ItripCountExportInStreetSegmentsProps {
    layer: string,
    offset?: number,
    page?: number,
    page_size?: number,
    language?: string,
    id?: string,
    time_range_start?: Date,
    time_range_end?: Date,
    duration_min?: number,
    duration_max?: number,
    distance_min?: number,
    distance_max?: number,
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    propulsion_type?: Array<'human' | 'electric_assist' | 'electric' | 'combustion'>,
    provider_ids?: Array<string>,
    origins?: Array<string>,
    destinations?: Array<string>,
    day_of_week?: Array<number>,
    trips_per_street_min?: number,
    trips_per_street_max?: number,
    location_type?: Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>,
    options?: any,
};

/**
 * tripCountInStreetSegment parameters
 */
export interface ItripCountInStreetSegmentProps {
    layer: string,
    offset?: number,
    page?: number,
    page_size?: number,
    language?: string,
    id?: string,
    time_range_start?: Date,
    time_range_end?: Date,
    duration_min?: number,
    duration_max?: number,
    distance_min?: number,
    distance_max?: number,
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    propulsion_type?: Array<'human' | 'electric_assist' | 'electric' | 'combustion'>,
    provider_ids?: Array<string>,
    origins?: Array<string>,
    destinations?: Array<string>,
    day_of_week?: Array<number>,
    trips_per_street_min?: number,
    trips_per_street_max?: number,
    location_type?: Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>,
    options?: any,
};

/**
 * tripExportList parameters
 */
export interface ItripExportListProps {
    layer: string,
    offset?: number,
    page?: number,
    page_size?: number,
    language?: string,
    id?: string,
    time_range_start?: Date,
    time_range_end?: Date,
    duration_min?: number,
    duration_max?: number,
    distance_min?: number,
    distance_max?: number,
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    propulsion_type?: Array<'human' | 'electric_assist' | 'electric' | 'combustion'>,
    provider_ids?: Array<string>,
    origins?: Array<string>,
    destinations?: Array<string>,
    day_of_week?: Array<number>,
    trips_per_street_min?: number,
    trips_per_street_max?: number,
    location_type?: Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>,
    options?: any,
};

/**
 * tripFacetedList parameters
 */
export interface ItripFacetedListProps {
    layer: string,
    offset?: number,
    page?: number,
    page_size?: number,
    language?: string,
    id?: string,
    time_range_start?: Date,
    time_range_end?: Date,
    duration_min?: number,
    duration_max?: number,
    distance_min?: number,
    distance_max?: number,
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    propulsion_type?: Array<'human' | 'electric_assist' | 'electric' | 'combustion'>,
    provider_ids?: Array<string>,
    origins?: Array<string>,
    destinations?: Array<string>,
    day_of_week?: Array<number>,
    trips_per_street_min?: number,
    trips_per_street_max?: number,
    location_type?: Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>,
    options?: any,
};

/**
 * tripOriginDestinationList parameters
 */
export interface ItripOriginDestinationListProps {
    layer: string,
    offset?: number,
    page?: number,
    page_size?: number,
    language?: string,
    id?: string,
    time_range_start?: Date,
    time_range_end?: Date,
    duration_min?: number,
    duration_max?: number,
    distance_min?: number,
    distance_max?: number,
    vehicle_type?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>,
    propulsion_type?: Array<'human' | 'electric_assist' | 'electric' | 'combustion'>,
    provider_ids?: Array<string>,
    origins?: Array<string>,
    destinations?: Array<string>,
    day_of_week?: Array<number>,
    trips_per_street_min?: number,
    trips_per_street_max?: number,
    location_type?: Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>,
    options?: any,
};

/**
 * TripApi - fetch parameter creator
 * @export
 */
export const TripApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options = {} }: ItripCountExportInStreetSegmentsProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling tripCountExportInStreetSegments.');
            }
            const localVarPath = `/trip/export-trip-per-street-segments/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (time_range_start !== undefined) {
                localVarQueryParameter['time_range_start'] = (time_range_start as any).toISOString();
            }

            if (time_range_end !== undefined) {
                localVarQueryParameter['time_range_end'] = (time_range_end as any).toISOString();
            }

            if (duration_min !== undefined) {
                localVarQueryParameter['duration_min'] = duration_min;
            }

            if (duration_max !== undefined) {
                localVarQueryParameter['duration_max'] = duration_max;
            }

            if (distance_min !== undefined) {
                localVarQueryParameter['distance_min'] = distance_min;
            }

            if (distance_max !== undefined) {
                localVarQueryParameter['distance_max'] = distance_max;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (propulsion_type) {
                localVarQueryParameter['propulsion_type'] = propulsion_type;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (day_of_week) {
                localVarQueryParameter['day_of_week'] = day_of_week;
            }

            if (trips_per_street_min !== undefined) {
                localVarQueryParameter['trips_per_street_min'] = trips_per_street_min;
            }

            if (trips_per_street_max !== undefined) {
                localVarQueryParameter['trips_per_street_max'] = trips_per_street_max;
            }

            if (location_type) {
                localVarQueryParameter['location_type'] = location_type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options = {} }: ItripCountInStreetSegmentProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling tripCountInStreetSegment.');
            }
            const localVarPath = `/trip/trip-count-in-street-segment/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (time_range_start !== undefined) {
                localVarQueryParameter['time_range_start'] = (time_range_start as any).toISOString();
            }

            if (time_range_end !== undefined) {
                localVarQueryParameter['time_range_end'] = (time_range_end as any).toISOString();
            }

            if (duration_min !== undefined) {
                localVarQueryParameter['duration_min'] = duration_min;
            }

            if (duration_max !== undefined) {
                localVarQueryParameter['duration_max'] = duration_max;
            }

            if (distance_min !== undefined) {
                localVarQueryParameter['distance_min'] = distance_min;
            }

            if (distance_max !== undefined) {
                localVarQueryParameter['distance_max'] = distance_max;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (propulsion_type) {
                localVarQueryParameter['propulsion_type'] = propulsion_type;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (day_of_week) {
                localVarQueryParameter['day_of_week'] = day_of_week;
            }

            if (trips_per_street_min !== undefined) {
                localVarQueryParameter['trips_per_street_min'] = trips_per_street_min;
            }

            if (trips_per_street_max !== undefined) {
                localVarQueryParameter['trips_per_street_max'] = trips_per_street_max;
            }

            if (location_type) {
                localVarQueryParameter['location_type'] = location_type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options = {} }: ItripExportListProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling tripExportList.');
            }
            const localVarPath = `/trip/export-list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (time_range_start !== undefined) {
                localVarQueryParameter['time_range_start'] = (time_range_start as any).toISOString();
            }

            if (time_range_end !== undefined) {
                localVarQueryParameter['time_range_end'] = (time_range_end as any).toISOString();
            }

            if (duration_min !== undefined) {
                localVarQueryParameter['duration_min'] = duration_min;
            }

            if (duration_max !== undefined) {
                localVarQueryParameter['duration_max'] = duration_max;
            }

            if (distance_min !== undefined) {
                localVarQueryParameter['distance_min'] = distance_min;
            }

            if (distance_max !== undefined) {
                localVarQueryParameter['distance_max'] = distance_max;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (propulsion_type) {
                localVarQueryParameter['propulsion_type'] = propulsion_type;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (day_of_week) {
                localVarQueryParameter['day_of_week'] = day_of_week;
            }

            if (trips_per_street_min !== undefined) {
                localVarQueryParameter['trips_per_street_min'] = trips_per_street_min;
            }

            if (trips_per_street_max !== undefined) {
                localVarQueryParameter['trips_per_street_max'] = trips_per_street_max;
            }

            if (location_type) {
                localVarQueryParameter['location_type'] = location_type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options = {} }: ItripFacetedListProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling tripFacetedList.');
            }
            const localVarPath = `/trip/list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (time_range_start !== undefined) {
                localVarQueryParameter['time_range_start'] = (time_range_start as any).toISOString();
            }

            if (time_range_end !== undefined) {
                localVarQueryParameter['time_range_end'] = (time_range_end as any).toISOString();
            }

            if (duration_min !== undefined) {
                localVarQueryParameter['duration_min'] = duration_min;
            }

            if (duration_max !== undefined) {
                localVarQueryParameter['duration_max'] = duration_max;
            }

            if (distance_min !== undefined) {
                localVarQueryParameter['distance_min'] = distance_min;
            }

            if (distance_max !== undefined) {
                localVarQueryParameter['distance_max'] = distance_max;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (propulsion_type) {
                localVarQueryParameter['propulsion_type'] = propulsion_type;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (day_of_week) {
                localVarQueryParameter['day_of_week'] = day_of_week;
            }

            if (trips_per_street_min !== undefined) {
                localVarQueryParameter['trips_per_street_min'] = trips_per_street_min;
            }

            if (trips_per_street_max !== undefined) {
                localVarQueryParameter['trips_per_street_max'] = trips_per_street_max;
            }

            if (location_type) {
                localVarQueryParameter['location_type'] = location_type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options = {} }: ItripOriginDestinationListProps): FetchArgs {
            // verify required parameter 'layer' is not null or undefined
            if (layer === null || layer === undefined) {
                throw new RequiredError('layer','Required parameter layer was null or undefined when calling tripOriginDestinationList.');
            }
            const localVarPath = `/trip/origin-destination-list/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (page_size !== undefined) {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (time_range_start !== undefined) {
                localVarQueryParameter['time_range_start'] = (time_range_start as any).toISOString();
            }

            if (time_range_end !== undefined) {
                localVarQueryParameter['time_range_end'] = (time_range_end as any).toISOString();
            }

            if (duration_min !== undefined) {
                localVarQueryParameter['duration_min'] = duration_min;
            }

            if (duration_max !== undefined) {
                localVarQueryParameter['duration_max'] = duration_max;
            }

            if (distance_min !== undefined) {
                localVarQueryParameter['distance_min'] = distance_min;
            }

            if (distance_max !== undefined) {
                localVarQueryParameter['distance_max'] = distance_max;
            }

            if (vehicle_type) {
                localVarQueryParameter['vehicle_type'] = vehicle_type;
            }

            if (propulsion_type) {
                localVarQueryParameter['propulsion_type'] = propulsion_type;
            }

            if (provider_ids) {
                localVarQueryParameter['provider_ids'] = provider_ids;
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (day_of_week) {
                localVarQueryParameter['day_of_week'] = day_of_week;
            }

            if (trips_per_street_min !== undefined) {
                localVarQueryParameter['trips_per_street_min'] = trips_per_street_min;
            }

            if (trips_per_street_max !== undefined) {
                localVarQueryParameter['trips_per_street_max'] = trips_per_street_max;
            }

            if (location_type) {
                localVarQueryParameter['location_type'] = location_type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TripApi - functional programming interface
 * @export
 */
export const TripApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        tripCountExportInStreetSegmentsURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountExportInStreetSegmentsProps): FetchArgs {
            return TripApiFetchParamCreator(configuration).tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountExportInStreetSegmentsProps): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = this.tripCountExportInStreetSegmentsURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        tripCountInStreetSegmentURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountInStreetSegmentProps): FetchArgs {
            return TripApiFetchParamCreator(configuration).tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountInStreetSegmentProps): (fetch?: FetchAPI, basePath?: string) => Promise<TripCountInStreetSegment> {
            const localVarFetchArgs = this.tripCountInStreetSegmentURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        tripExportListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripExportListProps): FetchArgs {
            return TripApiFetchParamCreator(configuration).tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripExportListProps): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = this.tripExportListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        tripFacetedListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripFacetedListProps): FetchArgs {
            return TripApiFetchParamCreator(configuration).tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripFacetedListProps): (fetch?: FetchAPI, basePath?: string) => Promise<TripsFacetedListResponse> {
            const localVarFetchArgs = this.tripFacetedListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        tripOriginDestinationListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripOriginDestinationListProps): FetchArgs {
            return TripApiFetchParamCreator(configuration).tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
        },

        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripOriginDestinationListProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TripOriginDestination>> {
            const localVarFetchArgs = this.tripOriginDestinationListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * TripApi - factory interface
 * @export
 */
export const TripApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountExportInStreetSegmentsProps) {
            return TripApiFp(configuration).tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountInStreetSegmentProps) {
            return TripApiFp(configuration).tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripExportListProps) {
            return TripApiFp(configuration).tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripFacetedListProps) {
            return TripApiFp(configuration).tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {number} [offset] 
         * @param {number} [page] 
         * @param {number} [page_size] 
         * @param {string} [language] 
         * @param {string} [id] 
         * @param {Date} [time_range_start] 
         * @param {Date} [time_range_end] 
         * @param {number} [duration_min] 
         * @param {number} [duration_max] 
         * @param {number} [distance_min] 
         * @param {number} [distance_max] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
         * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
         * @param {Array<string>} [provider_ids] 
         * @param {Array<string>} [origins] 
         * @param {Array<string>} [destinations] 
         * @param {Array<number>} [day_of_week] 
         * @param {number} [trips_per_street_min] 
         * @param {number} [trips_per_street_max] 
         * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripOriginDestinationListProps) {
            return TripApiFp(configuration).tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(fetch, basePath);
        },
    };
};

/**
 * TripApi - object-oriented interface
 * @export
 * @class TripApi
 * @extends {BaseAPI}
 */
export class TripApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``tripCountExportInStreetSegments`` for arguments and options.
     *
     */
    public tripCountExportInStreetSegmentsURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountExportInStreetSegmentsProps): FetchArgs {
        return TripApiFp(this.configuration).tripCountExportInStreetSegmentsURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [language] 
     * @param {string} [id] 
     * @param {Date} [time_range_start] 
     * @param {Date} [time_range_end] 
     * @param {number} [duration_min] 
     * @param {number} [duration_max] 
     * @param {number} [distance_min] 
     * @param {number} [distance_max] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
     * @param {Array<string>} [provider_ids] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<number>} [day_of_week] 
     * @param {number} [trips_per_street_min] 
     * @param {number} [trips_per_street_max] 
     * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountExportInStreetSegmentsProps) {
        return TripApiFp(this.configuration).tripCountExportInStreetSegments({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``tripCountInStreetSegment`` for arguments and options.
     *
     */
    public tripCountInStreetSegmentURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountInStreetSegmentProps): FetchArgs {
        return TripApiFp(this.configuration).tripCountInStreetSegmentURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [language] 
     * @param {string} [id] 
     * @param {Date} [time_range_start] 
     * @param {Date} [time_range_end] 
     * @param {number} [duration_min] 
     * @param {number} [duration_max] 
     * @param {number} [distance_min] 
     * @param {number} [distance_max] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
     * @param {Array<string>} [provider_ids] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<number>} [day_of_week] 
     * @param {number} [trips_per_street_min] 
     * @param {number} [trips_per_street_max] 
     * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripCountInStreetSegmentProps) {
        return TripApiFp(this.configuration).tripCountInStreetSegment({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``tripExportList`` for arguments and options.
     *
     */
    public tripExportListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripExportListProps): FetchArgs {
        return TripApiFp(this.configuration).tripExportListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [language] 
     * @param {string} [id] 
     * @param {Date} [time_range_start] 
     * @param {Date} [time_range_end] 
     * @param {number} [duration_min] 
     * @param {number} [duration_max] 
     * @param {number} [distance_min] 
     * @param {number} [distance_max] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
     * @param {Array<string>} [provider_ids] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<number>} [day_of_week] 
     * @param {number} [trips_per_street_min] 
     * @param {number} [trips_per_street_max] 
     * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripExportListProps) {
        return TripApiFp(this.configuration).tripExportList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``tripFacetedList`` for arguments and options.
     *
     */
    public tripFacetedListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripFacetedListProps): FetchArgs {
        return TripApiFp(this.configuration).tripFacetedListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [language] 
     * @param {string} [id] 
     * @param {Date} [time_range_start] 
     * @param {Date} [time_range_end] 
     * @param {number} [duration_min] 
     * @param {number} [duration_max] 
     * @param {number} [distance_min] 
     * @param {number} [distance_max] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
     * @param {Array<string>} [provider_ids] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<number>} [day_of_week] 
     * @param {number} [trips_per_street_min] 
     * @param {number} [trips_per_street_max] 
     * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripFacetedListProps) {
        return TripApiFp(this.configuration).tripFacetedList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``tripOriginDestinationList`` for arguments and options.
     *
     */
    public tripOriginDestinationListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripOriginDestinationListProps): FetchArgs {
        return TripApiFp(this.configuration).tripOriginDestinationListURL({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options });
    }

    /**
     * 
     * @param {string} layer 
     * @param {number} [offset] 
     * @param {number} [page] 
     * @param {number} [page_size] 
     * @param {string} [language] 
     * @param {string} [id] 
     * @param {Date} [time_range_start] 
     * @param {Date} [time_range_end] 
     * @param {number} [duration_min] 
     * @param {number} [duration_max] 
     * @param {number} [distance_min] 
     * @param {number} [distance_max] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [vehicle_type] 
     * @param {Array<'human' | 'electric_assist' | 'electric' | 'combustion'>} [propulsion_type] 
     * @param {Array<string>} [provider_ids] 
     * @param {Array<string>} [origins] 
     * @param {Array<string>} [destinations] 
     * @param {Array<number>} [day_of_week] 
     * @param {number} [trips_per_street_min] 
     * @param {number} [trips_per_street_max] 
     * @param {Array<'bike_lane' | 'crosswalk' | 'side' | 'street' | 'garage' | 'invalid'>} [location_type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options }: ItripOriginDestinationListProps) {
        return TripApiFp(this.configuration).tripOriginDestinationList({ layer, offset, page, page_size, language, id, time_range_start, time_range_end, duration_min, duration_max, distance_min, distance_max, vehicle_type, propulsion_type, provider_ids, origins, destinations, day_of_week, trips_per_street_min, trips_per_street_max, location_type, options })(this.fetch, this.basePath);
    }

}

/**
 * userToggle2faList parameters
 */
export interface IuserToggle2faListProps {
    options?: any,
};

/**
 * UserToggle2faApi - fetch parameter creator
 * @export
 */
export const UserToggle2faApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userToggle2faList({ options = {} }: IuserToggle2faListProps): FetchArgs {
            const localVarPath = `/user_toggle_2fa/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserToggle2faApi - functional programming interface
 * @export
 */
export const UserToggle2faApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        userToggle2faListURL({ options }: IuserToggle2faListProps): FetchArgs {
            return UserToggle2faApiFetchParamCreator(configuration).userToggle2faList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userToggle2faList({ options }: IuserToggle2faListProps): (fetch?: FetchAPI, basePath?: string) => Promise<TotpLink> {
            const localVarFetchArgs = this.userToggle2faListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * UserToggle2faApi - factory interface
 * @export
 */
export const UserToggle2faApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userToggle2faList({ options }: IuserToggle2faListProps) {
            return UserToggle2faApiFp(configuration).userToggle2faList({ options })(fetch, basePath);
        },
    };
};

/**
 * UserToggle2faApi - object-oriented interface
 * @export
 * @class UserToggle2faApi
 * @extends {BaseAPI}
 */
export class UserToggle2faApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``userToggle2faList`` for arguments and options.
     *
     */
    public userToggle2faListURL({ options }: IuserToggle2faListProps = {}): FetchArgs {
        return UserToggle2faApiFp(this.configuration).userToggle2faListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserToggle2faApi
     */
    public userToggle2faList({ options }: IuserToggle2faListProps = {}) {
        return UserToggle2faApiFp(this.configuration).userToggle2faList({ options })(this.fetch, this.basePath);
    }

}

/**
 * userTotpList parameters
 */
export interface IuserTotpListProps {
    options?: any,
};

/**
 * UserTotpApi - fetch parameter creator
 * @export
 */
export const UserTotpApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTotpList({ options = {} }: IuserTotpListProps): FetchArgs {
            const localVarPath = `/user_totp/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserTotpApi - functional programming interface
 * @export
 */
export const UserTotpApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        userTotpListURL({ options }: IuserTotpListProps): FetchArgs {
            return UserTotpApiFetchParamCreator(configuration).userTotpList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTotpList({ options }: IuserTotpListProps): (fetch?: FetchAPI, basePath?: string) => Promise<TotpLink> {
            const localVarFetchArgs = this.userTotpListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * UserTotpApi - factory interface
 * @export
 */
export const UserTotpApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTotpList({ options }: IuserTotpListProps) {
            return UserTotpApiFp(configuration).userTotpList({ options })(fetch, basePath);
        },
    };
};

/**
 * UserTotpApi - object-oriented interface
 * @export
 * @class UserTotpApi
 * @extends {BaseAPI}
 */
export class UserTotpApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``userTotpList`` for arguments and options.
     *
     */
    public userTotpListURL({ options }: IuserTotpListProps = {}): FetchArgs {
        return UserTotpApiFp(this.configuration).userTotpListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserTotpApi
     */
    public userTotpList({ options }: IuserTotpListProps = {}) {
        return UserTotpApiFp(this.configuration).userTotpList({ options })(this.fetch, this.basePath);
    }

}

/**
 * userValidate2faCreate parameters
 */
export interface IuserValidate2faCreateProps {
    data: TwoFactorToken,
    options?: any,
};

/**
 * UserValidate2faApi - fetch parameter creator
 * @export
 */
export const UserValidate2faApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TwoFactorToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidate2faCreate({ data, options = {} }: IuserValidate2faCreateProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling userValidate2faCreate.');
            }
            const localVarPath = `/user_validate_2fa/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TwoFactorToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserValidate2faApi - functional programming interface
 * @export
 */
export const UserValidate2faApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        userValidate2faCreateURL({ data, options }: IuserValidate2faCreateProps): FetchArgs {
            return UserValidate2faApiFetchParamCreator(configuration).userValidate2faCreate({ data, options });
        },

        /**
         * 
         * @param {TwoFactorToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidate2faCreate({ data, options }: IuserValidate2faCreateProps): (fetch?: FetchAPI, basePath?: string) => Promise<TwoFactorValidation> {
            const localVarFetchArgs = this.userValidate2faCreateURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * UserValidate2faApi - factory interface
 * @export
 */
export const UserValidate2faApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {TwoFactorToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidate2faCreate({ data, options }: IuserValidate2faCreateProps) {
            return UserValidate2faApiFp(configuration).userValidate2faCreate({ data, options })(fetch, basePath);
        },
    };
};

/**
 * UserValidate2faApi - object-oriented interface
 * @export
 * @class UserValidate2faApi
 * @extends {BaseAPI}
 */
export class UserValidate2faApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``userValidate2faCreate`` for arguments and options.
     *
     */
    public userValidate2faCreateURL({ data, options }: IuserValidate2faCreateProps): FetchArgs {
        return UserValidate2faApiFp(this.configuration).userValidate2faCreateURL({ data, options });
    }

    /**
     * 
     * @param {TwoFactorToken} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserValidate2faApi
     */
    public userValidate2faCreate({ data, options }: IuserValidate2faCreateProps) {
        return UserValidate2faApiFp(this.configuration).userValidate2faCreate({ data, options })(this.fetch, this.basePath);
    }

}

/**
 * userinfoList parameters
 */
export interface IuserinfoListProps {
    options?: any,
};

/**
 * UserinfoApi - fetch parameter creator
 * @export
 */
export const UserinfoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoList({ options = {} }: IuserinfoListProps): FetchArgs {
            const localVarPath = `/userinfo/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserinfoApi - functional programming interface
 * @export
 */
export const UserinfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        userinfoListURL({ options }: IuserinfoListProps): FetchArgs {
            return UserinfoApiFetchParamCreator(configuration).userinfoList({ options });
        },

        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoList({ options }: IuserinfoListProps): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = this.userinfoListURL({ options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * UserinfoApi - factory interface
 * @export
 */
export const UserinfoApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoList({ options }: IuserinfoListProps) {
            return UserinfoApiFp(configuration).userinfoList({ options })(fetch, basePath);
        },
    };
};

/**
 * UserinfoApi - object-oriented interface
 * @export
 * @class UserinfoApi
 * @extends {BaseAPI}
 */
export class UserinfoApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``userinfoList`` for arguments and options.
     *
     */
    public userinfoListURL({ options }: IuserinfoListProps = {}): FetchArgs {
        return UserinfoApiFp(this.configuration).userinfoListURL({ options });
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserinfoApi
     */
    public userinfoList({ options }: IuserinfoListProps = {}) {
        return UserinfoApiFp(this.configuration).userinfoList({ options })(this.fetch, this.basePath);
    }

}

/**
 * vehiclesAutocomplete parameters
 */
export interface IvehiclesAutocompleteProps {
    id?: string, // 
    category?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    provider?: Array<string>, // 
    status?: Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>, // 
    propulsion?: string, // Multiple values may be separated by commas.
    identification_number?: string, // 
    telemetry_after?: string, // range (lower bound)
    telemetry_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    vin?: string, // 
    within_zone?: string, // 
    options?: any,
};

/**
 * vehiclesExport parameters
 */
export interface IvehiclesExportProps {
    id?: string, // 
    category?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    provider?: Array<string>, // 
    status?: Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>, // 
    propulsion?: string, // Multiple values may be separated by commas.
    identification_number?: string, // 
    telemetry_after?: string, // range (lower bound)
    telemetry_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    vin?: string, // 
    within_zone?: string, // 
    options?: any,
};

/**
 * vehiclesInZone parameters
 */
export interface IvehiclesInZoneProps {
    id?: string, // 
    category?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    provider?: Array<string>, // 
    status?: Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>, // 
    propulsion?: string, // Multiple values may be separated by commas.
    identification_number?: string, // 
    telemetry_after?: string, // range (lower bound)
    telemetry_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    vin?: string, // 
    within_zone?: string, // 
    options?: any,
};

/**
 * vehiclesList parameters
 */
export interface IvehiclesListProps {
    id?: string, // 
    category?: Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>, // 
    provider?: Array<string>, // 
    status?: Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>, // 
    propulsion?: string, // Multiple values may be separated by commas.
    identification_number?: string, // 
    telemetry_after?: string, // range (lower bound)
    telemetry_before?: string, // range (upper bound)
    tz?: string, // 
    lang?: string, // 
    vin?: string, // 
    within_zone?: string, // 
    options?: any,
};

/**
 * vehiclesProperties parameters
 */
export interface IvehiclesPropertiesProps {
    data: VehicleUids,
    options?: any,
};

/**
 * vehiclesRead parameters
 */
export interface IvehiclesReadProps {
    id: string,
    options?: any,
};

/**
 * VehiclesApi - fetch parameter creator
 * @export
 */
export const VehiclesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options = {} }: IvehiclesAutocompleteProps): FetchArgs {
            const localVarPath = `/vehicles/autocomplete/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (provider) {
                localVarQueryParameter['provider'] = provider;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (propulsion !== undefined) {
                localVarQueryParameter['propulsion'] = propulsion;
            }

            if (identification_number !== undefined) {
                localVarQueryParameter['identification_number'] = identification_number;
            }

            if (telemetry_after !== undefined) {
                localVarQueryParameter['telemetry_after'] = telemetry_after;
            }

            if (telemetry_before !== undefined) {
                localVarQueryParameter['telemetry_before'] = telemetry_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (within_zone !== undefined) {
                localVarQueryParameter['within_zone'] = within_zone;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options = {} }: IvehiclesExportProps): FetchArgs {
            const localVarPath = `/vehicles/export/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (provider) {
                localVarQueryParameter['provider'] = provider;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (propulsion !== undefined) {
                localVarQueryParameter['propulsion'] = propulsion;
            }

            if (identification_number !== undefined) {
                localVarQueryParameter['identification_number'] = identification_number;
            }

            if (telemetry_after !== undefined) {
                localVarQueryParameter['telemetry_after'] = telemetry_after;
            }

            if (telemetry_before !== undefined) {
                localVarQueryParameter['telemetry_before'] = telemetry_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (within_zone !== undefined) {
                localVarQueryParameter['within_zone'] = within_zone;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options = {} }: IvehiclesInZoneProps): FetchArgs {
            const localVarPath = `/vehicles/in_zone/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (provider) {
                localVarQueryParameter['provider'] = provider;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (propulsion !== undefined) {
                localVarQueryParameter['propulsion'] = propulsion;
            }

            if (identification_number !== undefined) {
                localVarQueryParameter['identification_number'] = identification_number;
            }

            if (telemetry_after !== undefined) {
                localVarQueryParameter['telemetry_after'] = telemetry_after;
            }

            if (telemetry_before !== undefined) {
                localVarQueryParameter['telemetry_before'] = telemetry_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (within_zone !== undefined) {
                localVarQueryParameter['within_zone'] = within_zone;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options = {} }: IvehiclesListProps): FetchArgs {
            const localVarPath = `/vehicles/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (provider) {
                localVarQueryParameter['provider'] = provider;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (propulsion !== undefined) {
                localVarQueryParameter['propulsion'] = propulsion;
            }

            if (identification_number !== undefined) {
                localVarQueryParameter['identification_number'] = identification_number;
            }

            if (telemetry_after !== undefined) {
                localVarQueryParameter['telemetry_after'] = telemetry_after;
            }

            if (telemetry_before !== undefined) {
                localVarQueryParameter['telemetry_before'] = telemetry_before;
            }

            if (tz !== undefined) {
                localVarQueryParameter['tz'] = tz;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (within_zone !== undefined) {
                localVarQueryParameter['within_zone'] = within_zone;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a dictionary of the {uid: [vin, provider, propulsion, ...} format. It's a little ugly, the serializer is dump and straightforward, but it's efficient as we need this information to export the historical map reports before we completely revamp the whole feature. Do not use this in other portions of the code, or make it a little smarter.
         * @param {VehicleUids} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesProperties({ data, options = {} }: IvehiclesPropertiesProps): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling vehiclesProperties.');
            }
            const localVarPath = `/vehicles/properties/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VehicleUids" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesRead({ id, options = {} }: IvehiclesReadProps): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling vehiclesRead.');
            }
            const localVarPath = `/vehicles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            const {search, ...localVarUrlObjWithoutSearch} = localVarUrlObj;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObjWithoutSearch),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    return {
        /**
         */
        vehiclesAutocompleteURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesAutocompleteProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
        },

        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesAutocompleteProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VehicleAutocomplete>> {
            const localVarFetchArgs = this.vehiclesAutocompleteURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        vehiclesExportURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesExportProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
        },

        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesExportProps): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleResponse> {
            const localVarFetchArgs = this.vehiclesExportURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        vehiclesInZoneURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesInZoneProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
        },

        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesInZoneProps): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Vehicle>> {
            const localVarFetchArgs = this.vehiclesInZoneURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        vehiclesListURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesListProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
        },

        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesListProps): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleResponse> {
            const localVarFetchArgs = this.vehiclesListURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        vehiclesPropertiesURL({ data, options }: IvehiclesPropertiesProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesProperties({ data, options });
        },

        /**
         * Returns a dictionary of the {uid: [vin, provider, propulsion, ...} format. It's a little ugly, the serializer is dump and straightforward, but it's efficient as we need this information to export the historical map reports before we completely revamp the whole feature. Do not use this in other portions of the code, or make it a little smarter.
         * @param {VehicleUids} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesProperties({ data, options }: IvehiclesPropertiesProps): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleProperties> {
            const localVarFetchArgs = this.vehiclesPropertiesURL({ data, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
        /**
         */
        vehiclesReadURL({ id, options }: IvehiclesReadProps): FetchArgs {
            return VehiclesApiFetchParamCreator(configuration).vehiclesRead({ id, options });
        },

        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesRead({ id, options }: IvehiclesReadProps): (fetch?: FetchAPI, basePath?: string) => Promise<RetrieveVehicle> {
            const localVarFetchArgs = this.vehiclesReadURL({ id, options });
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    return handleGeneratedApiResponse(response)
                });
            };
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesAutocompleteProps) {
            return VehiclesApiFp(configuration).vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesExportProps) {
            return VehiclesApiFp(configuration).vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesInZoneProps) {
            return VehiclesApiFp(configuration).vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
         * @param {Array<string>} [provider] 
         * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
         * @param {string} [propulsion] Multiple values may be separated by commas.
         * @param {string} [identification_number] 
         * @param {string} [telemetry_after] range (lower bound)
         * @param {string} [telemetry_before] range (upper bound)
         * @param {string} [tz] 
         * @param {string} [lang] 
         * @param {string} [vin] 
         * @param {string} [within_zone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesListProps) {
            return VehiclesApiFp(configuration).vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(fetch, basePath);
        },
        /**
         * Returns a dictionary of the {uid: [vin, provider, propulsion, ...} format. It's a little ugly, the serializer is dump and straightforward, but it's efficient as we need this information to export the historical map reports before we completely revamp the whole feature. Do not use this in other portions of the code, or make it a little smarter.
         * @param {VehicleUids} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesProperties({ data, options }: IvehiclesPropertiesProps) {
            return VehiclesApiFp(configuration).vehiclesProperties({ data, options })(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesRead({ id, options }: IvehiclesReadProps) {
            return VehiclesApiFp(configuration).vehiclesRead({ id, options })(fetch, basePath);
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesAutocomplete`` for arguments and options.
     *
     */
    public vehiclesAutocompleteURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesAutocompleteProps = {}): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesAutocompleteURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
    }

    /**
     * 
     * @param {string} [id] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
     * @param {Array<string>} [provider] 
     * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
     * @param {string} [propulsion] Multiple values may be separated by commas.
     * @param {string} [identification_number] 
     * @param {string} [telemetry_after] range (lower bound)
     * @param {string} [telemetry_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [vin] 
     * @param {string} [within_zone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesAutocompleteProps = {}) {
        return VehiclesApiFp(this.configuration).vehiclesAutocomplete({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesExport`` for arguments and options.
     *
     */
    public vehiclesExportURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesExportProps = {}): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesExportURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
    }

    /**
     * 
     * @param {string} [id] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
     * @param {Array<string>} [provider] 
     * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
     * @param {string} [propulsion] Multiple values may be separated by commas.
     * @param {string} [identification_number] 
     * @param {string} [telemetry_after] range (lower bound)
     * @param {string} [telemetry_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [vin] 
     * @param {string} [within_zone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesExportProps = {}) {
        return VehiclesApiFp(this.configuration).vehiclesExport({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesInZone`` for arguments and options.
     *
     */
    public vehiclesInZoneURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesInZoneProps = {}): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesInZoneURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
    }

    /**
     * 
     * @param {string} [id] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
     * @param {Array<string>} [provider] 
     * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
     * @param {string} [propulsion] Multiple values may be separated by commas.
     * @param {string} [identification_number] 
     * @param {string} [telemetry_after] range (lower bound)
     * @param {string} [telemetry_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [vin] 
     * @param {string} [within_zone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesInZoneProps = {}) {
        return VehiclesApiFp(this.configuration).vehiclesInZone({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesList`` for arguments and options.
     *
     */
    public vehiclesListURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesListProps = {}): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesListURL({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options });
    }

    /**
     * 
     * @param {string} [id] 
     * @param {Array<'bicycle' | 'car' | 'delivery_bot' | 'moped' | 'other' | 'scooter'>} [category] 
     * @param {Array<string>} [provider] 
     * @param {Array<'available' | 'elsewhere' | 'non_operational' | 'on_trip' | 'removed' | 'reserved' | 'unknown'>} [status] 
     * @param {string} [propulsion] Multiple values may be separated by commas.
     * @param {string} [identification_number] 
     * @param {string} [telemetry_after] range (lower bound)
     * @param {string} [telemetry_before] range (upper bound)
     * @param {string} [tz] 
     * @param {string} [lang] 
     * @param {string} [vin] 
     * @param {string} [within_zone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options }: IvehiclesListProps = {}) {
        return VehiclesApiFp(this.configuration).vehiclesList({ id, category, provider, status, propulsion, identification_number, telemetry_after, telemetry_before, tz, lang, vin, within_zone, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesProperties`` for arguments and options.
     *
     */
    public vehiclesPropertiesURL({ data, options }: IvehiclesPropertiesProps): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesPropertiesURL({ data, options });
    }

    /**
     * Returns a dictionary of the {uid: [vin, provider, propulsion, ...} format. It's a little ugly, the serializer is dump and straightforward, but it's efficient as we need this information to export the historical map reports before we completely revamp the whole feature. Do not use this in other portions of the code, or make it a little smarter.
     * @param {VehicleUids} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesProperties({ data, options }: IvehiclesPropertiesProps) {
        return VehiclesApiFp(this.configuration).vehiclesProperties({ data, options })(this.fetch, this.basePath);
    }

    /**
     * Returns the URL string and options for Fetch.
     * See ``vehiclesRead`` for arguments and options.
     *
     */
    public vehiclesReadURL({ id, options }: IvehiclesReadProps): FetchArgs {
        return VehiclesApiFp(this.configuration).vehiclesReadURL({ id, options });
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesRead({ id, options }: IvehiclesReadProps) {
        return VehiclesApiFp(this.configuration).vehiclesRead({ id, options })(this.fetch, this.basePath);
    }

}

