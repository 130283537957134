import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IRuleProps {
  direction: GridDirection
  key: string
  rule: [string, boolean]
}

function Rule(props: IRuleProps) {
  return (
    <Grid item xs={props.direction === 'column' ? 12 : 6}>
      {props.rule[1] ? (
        <Typography variant="body2">{props.rule[0]}</Typography>
      ) : (
        <Typography variant="body2" style={{ color: '#DC3030' }}>
          {props.rule[0]}
        </Typography>
      )}
    </Grid>
  )
}

export enum GridDirection {
  Column = 'column',
  Row = 'row',
}

interface IRulesValidator {
  checkRules: [string, boolean][]
  rulesDirection: GridDirection
}

export default function RulesValidator(props: IRulesValidator) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction="column" spacing={1} className={classes.passwordValidationContainer}>
      <Grid item>
        <Typography variant="body1">
          <span>{upperFirst(t('your password must contains:'))}</span>
        </Typography>
      </Grid>
      <Grid item container spacing={1} direction={props.rulesDirection} className={classes.passwordValidationElements}>
        {props.checkRules.map((rule: [string, boolean]) => (
          <Rule key={rule[0]} rule={rule} direction={props.rulesDirection} />
        ))}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  passwordValidationContainer: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  passwordValidationElements: {
    marginLeft: 10,
  },
}))
