import portableFetch from 'portable-fetch'

import { downloadExport } from 'src/api/downloadExport'

import { BaseAPI, FetchAPI } from './gen/api'

//  From/Size pagination with ES will not return more than 10000 results by default
// https://www.elastic.co/guide/en/elasticsearch/reference/current/search-request-from-size.html
export const MAX_FETCH_ITEMS = 1000
export const FETCH_PAGE_SIZE = 50

export class BaseFetchAPI extends BaseAPI {
  public getFetch(
    url: string,
    exporting?: boolean,
    token?: string
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      if (exporting) {
        return new Promise(() => {
          window.location.href = basePath + url + '&token=' + token
        })
      }
      return fetch(basePath + url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }
  public postFetch(url: string, body: any, exporting?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    return (fetch: FetchAPI = portableFetch, basePath: string = this.basePath) => {
      if (exporting) {
        return fetch(basePath + url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          downloadExport(response)
        })
      }
      return fetch(basePath + url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          throw response
        }
      })
    }
  }
}
